@use '/src/stylesheets/global' as global;

.RegistrerVirksomhetInfo {
  margin: 0 9.375rem;

  h1 {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }

  img {
    width: auto;
    height: 150px;
    max-width: 100%;
  }

  button {
    margin-top: 2rem;
  }

  .kategori_container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
    .kategori {
      &:after {
        content: '\00d7';
        margin-left: 0.5rem;
      }
      font-size: var(--font-size-normal);
      background: var(--secondary-color-25);
      width: fit-content;
      padding: 0 0.5rem;
      border-radius: 1rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .RegistrerVirksomhetInfo {
    h1 {
      margin: 0 0 1.25rem;
      font-size: var(--font-size-h4);
    }
  }
}

@use '/src/stylesheets/global' as global;

.Dashboard {
  margin-top: 3.6875rem;

  .DashboardTitle {
    font-family: var(--secondary-font);
    font-size: 3.125rem;
    font-weight: var(--font-weight-semibold);
  }

  .cardWrapper {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .card {
    border: 3px solid var(--tab-button-bg-hover);
    background: var(--white);
    padding: 2rem;
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  .cardTitle {
    font-family: var(--primary-font);
    font-size: 1.8125rem;
    font-weight: var(--font-weight-semibold);
  }

  svg {
    float: right;
    margin-top: 4rem;
  }

  .lastSVG {
    float: right;
    margin-top: 1rem;
  }

  .card_disabled {
    border: 3px dashed var(--tab-button-bg-hover);
    background: var(--white);
    padding: 2rem;
    cursor: default;
  }

  .card_disabled_title {
    font-family: var(--primary-font);
    font-size: 1.8125rem;
    font-weight: var(--font-weight-semibold);
    color: var(--grays-border-dark-grey, #d1d1d1);
  }
}

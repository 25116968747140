@use '/src/stylesheets/global' as global;

.Results {
  font-size: var(--font-size-normal);
  padding: 5rem 0;

  .resultTopLineWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .exportListWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.ContentBox {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, auto);

  header {
    display: grid;
    grid-row: 1;
    grid-template-columns: 2fr 1fr 2fr;

    h1 {
      grid-column: 2;
      font-size: 1.375rem;
      font-weight: var(--font-weight-normal);
      font-family: var(--primary-font);
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      color: var(--primary-dark-color);
      background: #d1d1d1;
      padding: 0.875rem 0;
    }
  }

  div[class^='CmsNode'] {
    background: var(--white);
    border: var(--border-width-thin) solid #d1d1d1;
    padding: 4rem;

    > article {
      display: block;
    }
  }
}

@use '/src/stylesheets/global' as global;

.MenuBar {
  display: flex;

  a,
  &:visited {
    text-decoration: none;
    color: var(--link-color);

    &:hover,
    &:focus {
      color: var(--link-hover-color);
    }
  }

  [role='menubar'] {
    display: flex;
    align-items: stretch;

    > [role='menuitem'] {
      display: flex;

      > a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: var(--font-size-base);
      }

      &[data-menu-position='relative'] {
        position: relative;
      }

      > a,
      > button {
        padding: 0.625rem 0.625rem;
      }
    }
  }

  [role='menubar'],
  [role='menu'] {
    p {
      line-height: 1.5;

      + p {
        margin-top: 0.3125rem;
      }

      &.Label {
        font-weight: var(--font-weight-bold);
      }

      &.SubText {
        color: var(--text-color);
        font-size: var(--font-size-base);
      }

      + ul {
        margin-top: 0.625rem;
      }
    }
  }

  [aria-expanded='false'] {
    .Overlay {
      display: none;
    }

    > [role='menu'] {
      display: none;
    }
  }

  [aria-expanded='true'] {
    .Overlay {
      z-index: 9;
      position: fixed;
      top: 5rem;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: transparent;
    }

    .Toggle {
      color: var(--link-color);

      &:hover,
      &:focus {
        color: var(--link-hover-color);
      }
    }

    > [role='menu'] {
      z-index: 20;
      position: absolute;
      top: 5rem;
      right: 0;
      background-color: var(--white);
      padding: 1.5625rem 3.125rem;

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &.List {
        max-width: 20.625rem;

        > [role='menuitem'] {
          + [role='menuitem'] {
            margin-top: 2.5rem;
          }
        }
      }

      &.Panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: space-between;
        text-align: center;
        min-width: 16.25rem;

        [role='menuitem'] {
          + [role='menuitem'] {
            margin-top: 1.875rem;
          }

          > p {
            font-size: var(--font-size-base);
            display: block;
            line-height: 1.5;

            span {
              display: block;
            }

            .noWrap {
              white-space: nowrap;
              font-weight: var(--font-weight-semibold);
              line-height: 2;
            }
          }

          > a {
            &.Button {
              padding: 0.625rem 1.875rem;
              justify-content: center;
              border: 0;
              background-color: var(--primary-color);
              color: white;
              font-family: var(--primary-font);
              cursor: pointer;
              white-space: nowrap;

              &:hover,
              &:focus {
                background-color: var(--primary-dark-color);
              }
            }

            &.Link {
              text-decoration: underline;
              justify-content: center;
              font-weight: var(--font-weight-semibold);
            }
          }
        }
      }
    }

    [role='menuitem'] {
      line-height: 2.125;
    }
  }
}

.Toggle {
  background-color: transparent;
  color: var(--link-color);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-base);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover,
  &:focus {
    color: var(--link-hover-color);
  }

  span {
    text-align: right;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    margin-left: 0.625rem;
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .MenuBar {
    [role='menubar'] {
      > [role='menuitem'] {
        + [role='menuitem'] {
          margin-left: 0.625rem;
        }

        > a,
        > button {
          padding: 0.3125rem;
        }
      }
    }

    [aria-expanded='true'] {
      > [role='menu'] {
        &.List {
          position: fixed;
          top: 3.75rem;
          right: 0;
          bottom: 0;
          left: 0;
          max-width: none;
          padding: 1.25rem;
        }

        &.Panel {
          top: 3.75rem;
        }
      }
    }
  }

  .Toggle {
    font-size: 0.6875rem;

    [class^='icon-'],
    [class*=' icon-'] {
      margin-left: 0.3125rem;

      &.SmallMobile {
        font-size: 0.6875rem;
      }
    }
  }
}

@use '/src/stylesheets/global' as global;

.LeverandoerTjenesteSide {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  .Wrapper {
    grid-column: 1 / 13;
  }

  section {
    width: 100%;

    h1 {
      margin-bottom: 0.625rem;
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-large);
      line-height: 1.75rem;
    }

    p {
      margin-bottom: 0.625rem;
      font-size: var(--font-size-small);
    }

    label {
      margin-top: 2rem;
      margin-bottom: 1.75rem;
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .LeverandoerTjenesteSide {
    padding: 0 1.25rem;
    grid-template-columns: 1fr;

    .Wrapper {
      grid-column: 1;
    }

    nav {
      ul {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@use '/src/stylesheets/global' as global;

.Header {
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0 1.25rem;
  height: var(--app-header-height);
  z-index: 10;
  background-color: var(--white);
  border-bottom: var(--border-width) solid var(--border-color-secondary);

  &[data-beta-label] {
    &::after {
      content: attr(data-beta-label);
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      transform-origin: 50% 50%;
      transform: rotateZ(-45deg) translateX(-2.5rem) translateY(-0.9375rem);
      font-size: 0.75rem;
      line-height: 2.125;
      background-color: var(--tertiary-color);
      color: var(--white);
      text-align: center;
      width: 8.75rem;
    }
  }

  .Wrapper {
    display: flex;
    width: 100%;
    max-width: var(--app-max-width);
    justify-content: space-between;
    position: relative;
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .Header {
    padding: 0 1.25rem;

    &::after {
      line-height: 1.75;
      transform: rotateZ(-45deg) translateX(-2.65625rem) translateY(-1.5625rem);
    }
  }
}

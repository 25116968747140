@use 'sass:map';
@use '/src/stylesheets/global' as global;

.TjenesteCard {
  --card-internal-grid-gap-small: 0.625rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: calc(var(--card-internal-grid-gap-small) * 1.5);
  box-sizing: border-box;
  padding: map.get(global.$card-spacings, 'narrow');
  background-color: var(--white);
  font-size: var(--font-size-normal);
  border: var(--border-width) solid var(--border-color-secondary);
  min-width: 0;

  p {
    font-size: var(--font-size-smaller);
  }

  &[data-href] {
    cursor: pointer;

    &:hover,
    &:focus-within {
      border: var(--border-width) solid var(--primary-color);
    }
  }

  &__title {
    h1 {
      font-family: var(--secondary-font);
      text-transform: uppercase;
      font-size: var(--font-size-large);
      hyphens: initial;
      line-height: 28px;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__label {
    text-transform: uppercase;
    font-size: var(--font-size-smaller);
  }

  a {
    font-size: var(--font-size-base);
    text-decoration: none;
    margin-left: auto;
    display: flex;
    column-gap: var(--grid-column-gap);

    [class*='icon-'] {
      font-size: var(--font-size-large);
    }
  }

  a:last-child {
    &:hover {
      color: var(--primary-color);
    }
  }
}

@use '/src/stylesheets/global' as global;

.minSideTitle {
  font-family: var(--primary-font);
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
}

.minSide {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: start;
  margin-top: 5rem;

  .companyProfile_ButtonWrapper,
  .minProfil_ButtonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    white-space: nowrap;
  }

  .subtitle {
    font-family: var(--primary-font);
    font-size: 1.5rem;
  }

  .kategori_list {
    margin-top: 0.5rem;
    padding-left: 0;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .kategori_chip {
    display: flex;
    padding: 0.375rem 1rem;
    border-radius: 6.25rem;
    background: var(--secondary-color-25);
    color: var(--primary-color);
  }

  button,
  section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  h4 {
    font-weight: var(--font-weight-semibold);
  }

  a {
    &:not(.link) {
      text-decoration: none;
      display: inline-flex;
      padding: 8px 26px;
      align-items: center;
      gap: 11px;
      border-radius: 34px;
      background: var(--primary-light-color);
      color: white;
      cursor: pointer;
    }
    width: fit-content;
    background: none;
    .link_text {
      width: fit-content;
      color: var(--primary-color);
      &:hover {
        color: var(--secondary-color);
      }
    }
  }

  .buttonTitle {
    color: var(--white);
    font-family: var(--primary-font);
    font-size: 1rem;
  }

  .companyName {
    font-family: var(--primary-font);
    font-size: 3.125rem;
    font-weight: var(--font-weight-bold);
  }

  section {
    margin-top: 2rem;
  }

  .contactInfoWrap {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  p,
  a,
  li {
    font-family: var(--primary-font);
    font-size: var(--font-size-normal);
  }

  ul {
    padding-left: 1rem;
    list-style-type: disc;
  }

  .nameWrap {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
  }

  .initials {
    background: #b1b1b1;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-align: center;
    color: var(--white);
  }
}

@media only screen and (max-width: global.breakpoint('lg')) {
  .minSide {
    display: flex;
    flex-direction: column;
  }

  .companyProfile_ButtonWrapper,
  .minProfil_ButtonWrapper {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

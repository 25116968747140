@use '/src/stylesheets/global' as global;

.RegistrerteLeverandorer {
  .RegistrerteLeverandorerTitle {
    font-family: var(--secondary-font);
    font-size: 3.125rem;
    font-weight: var(--font-weight-semibold);
    margin-bottom: 3rem;
  }

  .RegistrerteLeverandorerFilter {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: baseline;
    padding-bottom: 1rem;
    gap: 3rem;
  }

  .exportWrap {
    display: flex;
    white-space: nowrap;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    p {
      font-family: var(--primary-font);
      font-size: 1.1rem;
      font-weight: var(--font-weight-semibold);
    }
  }

  .sort {
    display: flex;
    align-items: center;

    label,
    select,
    option {
      margin-bottom: 0;
      font-family: var(--primary-font);
      font-size: 1.1rem;
      font-weight: var(--font-weight-semibold);
      color: #022a4b;
    }
  }

  select {
    background-color: transparent;
    border: transparent;
    outline-color: transparent;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid var(--white);
  }

  tr {
    height: 5.625rem;
    background: var(--border-color-secondary);
  }

  th {
    font-family: var(--primary-font);
    font-size: 1.75rem;
    font-weight: var(--font-weight-semibold);
    color: var(--white);
    background: #afafaf;
    text-align: left;
    padding-left: 1rem;
  }

  td {
    color: var(--black);
    font-family: var(--primary-font);
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-normal);
    padding-left: 1rem;

    &:first-child {
      font-weight: var(--font-weight-semibold);
    }
  }

  td:last-child {
    padding: 0 2rem;
  }
}

@use '/src/stylesheets/global' as global;

.Footer {
  display: flex;
  align-items: stretch;
  justify-content: center;
  box-sizing: border-box;
  padding: 3.75rem 1.25rem;
  background-color: rgb(14, 41, 73);
  color: white;
  margin-top: var(--app-vertical-padding);

  .wrapper {
    display: grid;
    grid-template-columns: 3fr auto;
    column-gap: 6.25rem;
    width: 100%;
    max-width: var(--app-max-width);
  }

  section {
    display: flex;
    flex-direction: column;

    a {
      color: white;
    }
  }

  address {
    margin-top: 1.4375rem;
    font-size: 1.25rem;
    font-style: normal;
    line-height: 1.4;
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .Footer {
    .wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 3.125rem;
    }

    ul {
      li,
      li:last-child {
        border-color: var(--primary-light-color);
      }
    }

    address {
      font-size: var(--font-size-normal);
    }
  }
}

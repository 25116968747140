@use '/src/stylesheets/global' as global;

.AvtaleListe {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap);

  .oppdatert {
    text-align: right;
    font-size: var(--font-size-smaller);

    time {
      display: block;
      font-size: var(--font-size-normal);
    }
  }

  article[class*='Card'] {
    &:nth-child(odd) {
      background-color: var(--primary-dark-color);

      &:not(:hover) {
        border-color: var(--primary-dark-color);
      }
    }
  }
}

.EmptyList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  row-gap: var(--grid-row-gap);
  font-size: var(--font-size-normal);
  gap: 1.5rem;

  [class^='icon-'],
  [class*=' icon-'] {
    font-size: var(--font-size-h1);
  }
}

@media only screen and (max-width: global.breakpoint('lg')) {
  .AvtaleListe {
    display: flex;
    flex-direction: column;

    .oppdatert {
      time {
        display: inline-block;
        margin-left: 0.25rem;
      }
    }
  }
}

@use 'sass:map';
@use '/src/stylesheets/global' as global;

.LeverandorCard {
  text-decoration: none;
  --card-internal-grid-gap-small: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: calc(var(--card-internal-grid-gap-small) * 1.5);
  box-sizing: border-box;
  padding: map.get(global.$card-spacings, 'narrow');
  background-color: var(--white);
  font-size: var(--font-size-normal);
  border: var(--border-width) solid var(--border-color-secondary);
  min-width: 0;
  cursor: pointer;

  &:hover {
    border: var(--border-width) solid var(--primary-color);
  }

  img {
    width: auto;
    height: 70px;
    max-width: 100%;
  }

  p {
    font-size: var(--font-size-smaller);
  }

  .kategori_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  &__kategori {
    display: flex;
    padding: 0.375rem 1rem;
    border-radius: 6.25rem;
    background: var(--secondary-color-25);
    color: var(--primary-color);
  }

  &__numberWrap {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--font-size-smaller);
    color: var(--primary-color);

    p {
      font-size: var(--font-size-normal);
    }
  }

  .more_button {
    font-size: var(--font-size-base);
    color: var(--primary-color);
    margin-left: auto;
    display: flex;
    column-gap: var(--grid-column-gap);
  }
}

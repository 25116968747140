@use '/src/stylesheets/global' as global;

.LeverandoerKategorier {
  margin-top: 3.125rem;

  h1 {
    font-family: var(--secondary-font);
    font-size: 2.3125rem;
    font-weight: var(--font-weight-semibold);
    margin-bottom: 1.5rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--grid-column-gap);
  }

  .checkbox_label {
    position: relative;
    display: flex;
    cursor: pointer;
    font-weight: 400;
    font-size: 1.125rem;
    color: var(--text-color);
    width: fit-content;
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
      color: var(--secondary-color);
    }

    input[type='checkbox'] {
      appearance: none;
      min-width: 1.5rem;
      height: 1.5rem;
      margin: 0 1rem 0 0;
      padding: 0;
      box-sizing: border-box;
      border-radius: 0;
      border: 0.125rem solid var(--primary-light-color);
      line-height: 1.5rem;
      vertical-align: middle;
      cursor: pointer;

      &:checked {
        + .marker:after,
        &:after {
          content: '\e900';
          position: absolute;
          left: 0;
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          font-family: 'dfo-symbols', sans-serif;
          text-align: center;
          background: var(--primary-color);
          color: white;
          top: 0;

          &:hover {
            cursor: pointer;
            background: var(--secondary-color);
          }
        }
      }
    }
  }
}

@use '/src/stylesheets/global' as global;

:root {
  --brand-horizontal-spacing: 0.625rem;
  --brand-border-width: var(--border-width);

  --brand-header-height: 3.125rem;
  --brand-header-title-height: 1.625rem;
  --brand-header-subtext-height: 0.5625rem;

  --brand-footer-height: 4rem;
  --brand-footer-title-height: 1.4375rem;
  --brand-footer-subtext-height: 0.8125rem;
}

.Brand {
  text-decoration: none;

  &.header {
    align-self: center;
    display: grid;
    grid-template-areas:
      'logo     border  name'
      'spacing  border  subtext';
    color: var(--primary-color);
    height: var(--brand-header-height);

    &:active,
    &:visited,
    &:hover {
      color: var(--primary-color);
    }

    img {
      height: var(--brand-header-title-height);
    }

    .Border {
      background-color: var(--primary-color);
      margin: 0 var(--brand-horizontal-spacing);
    }

    p {
      font-size: var(--brand-header-subtext-height);
      margin-left: var(--border-width-thin);
    }
  }

  &.footer {
    display: grid;
    grid-template-areas:
      'logo     border   name'
      'spacing  spacing  subtext';
    row-gap: 1.125rem;

    h1,
    &:active,
    &:visited,
    &:hover {
      color: var(--white);
    }

    img {
      height: var(--brand-footer-title-height);
      align-self: center;
    }

    .Border {
      background-color: var(--white);
      margin: 0 var(--brand-horizontal-spacing);
      height: var(--brand-footer-height);
    }

    h1 {
      display: flex;
      align-items: center;
      height: auto;
      max-width: 12.5rem;
    }

    p {
      font-size: var(--brand-footer-subtext-height);
      margin-left: var(
        --border-width-thin
      ); // Magic number to left align with h1 above
    }
  }

  img {
    grid-area: logo;
  }

  .Border {
    grid-area: border;
    display: block;
    height: var(--brand-header-height);
    width: var(--brand-border-width);
  }

  h1 {
    grid-area: name;
    font-family: var(--primary-font);
    font-size: var(--brand-header-title-height);
    font-weight: var(--brand-header-font-weight-normal);
    height: var(--brand-header-title-height);
  }

  p {
    grid-area: subtext;
    line-height: 1;
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  :root {
    --brand-horizontal-spacing: 0.3125rem;
    --brand-border-width: var(--border-width-thin);

    --brand-header-height: 2.1875rem;
    --brand-header-title-height: 0.75rem;

    --brand-footer-title-height: 1.125rem;
    --brand-footer-subtext-height: 0.625rem;
  }

  .Brand {
    align-self: center;
    max-width: 100%;

    &.header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      h1 {
        display: flex;
        align-items: center;
        height: var(--brand-header-height);
        max-width: 5.625rem;
      }

      img {
        align-self: center;
      }

      p {
        display: none;
      }
    }

    &.footer {
      max-width: 16.25rem;

      h1 {
        font-size: var(--brand-footer-title-height);
      }
    }
  }
}

[class*='CmsNode_main'] {
  *,
  .card {
    &.has-primary-light-blue-color {
      color: #005b91;
    }
    &.has-primary-light-blue-background-color {
      background-color: #005b91;
    }
    &.has-primary-dark-blue-color {
      color: #0e2a49;
    }
    &.has-primary-dark-blue-background-color {
      background-color: #0e2a49;
    }
    &.has-white-color {
      color: #ffffff;
    }
    &.has-white-background-color {
      background-color: #ffffff;
    }
    &.has-tertiary-green-color {
      color: #00a982;
    }
    &.has-tertiary-green-background-color {
      background-color: #00a982;
    }
    &.has-warning-yellow-color {
      color: #f7b715;
    }
    &.has-warning-yellow-background-color {
      background-color: #f7b715;
    }
    &.has-dark-grey-color {
      color: #d1d1d1;
    }
    &.has-dark-grey-background-color {
      background-color: #d1d1d1;
    }
  }
}

[class*='CmsNode_main'] {
  // @font-face {
  // 	font-family: 'Source Serif Pro';
  // 	font-style: normal;
  // 	font-weight: 700;
  // 	src: local(""),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-serif-pro/source-serif-pro-v10-latin-700.woff2) format("woff2"),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-serif-pro/source-serif-pro-v10-latin-700.woff) format("woff");
  // }
  // @font-face {
  // 	font-family: 'Source Serif Pro';
  // 	font-style: italic;
  // 	font-weight: 700;
  // 	src: local(""),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-serif-pro/source-serif-pro-v10-latin-700italic.woff2) format("woff2"),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-serif-pro/source-serif-pro-v10-latin-700italic.woff) format("woff");
  // }
  // @font-face {
  // 	font-family: 'Source Serif Pro';
  // 	font-style: normal;
  // 	font-weight: 600;
  // 	src: local(""),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-serif-pro/source-serif-pro-v11-latin-600.woff2) format("woff2"),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-serif-pro/source-serif-pro-v11-latin-600.woff) format("woff");
  // }
  // @font-face {
  // 	font-family: 'Source Serif Pro';
  // 	font-style: italic;
  // 	font-weight: 600;
  // 	src: local(""),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-serif-pro/source-serif-pro-v11-latin-600italic.woff2) format("woff2"),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-serif-pro/source-serif-pro-v11-latin-600italic.woff) format("woff");
  // }
  // @font-face {
  // 	font-family: 'Source Sans Pro';
  // 	font-style: normal;
  // 	font-weight: 400;
  // 	src: local(""),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-regular.woff2) format("woff2"),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-regular.woff) format("woff");
  // }
  // @font-face {
  // 	font-family: 'Source Sans Pro';
  // 	font-style: italic;
  // 	font-weight: 400;
  // 	src: local(""),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-italic.woff2) format("woff2"),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-italic.woff) format("woff");
  // }
  // @font-face {
  // 	font-family: 'Source Sans Pro';
  // 	font-style: normal;
  // 	font-weight: 600;
  // 	src: local(""),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-600.woff2) format("woff2"),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-600.woff) format("woff");
  // }
  // @font-face {
  // 	font-family: 'Source Sans Pro';
  // 	font-style: italic;
  // 	font-weight: 600;
  // 	src: local(""),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-600italic.woff2) format("woff2"),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-600italic.woff) format("woff");
  // }
  // @font-face {
  // 	font-family: 'Source Sans Pro';
  // 	font-style: normal;
  // 	font-weight: 700;
  // 	src: local(""),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-700.woff2) format("woff2"),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-700.woff) format("woff");
  // }
  // @font-face {
  // 	font-family: 'Source Sans Pro';
  // 	font-style: italic;
  // 	font-weight: 700;
  // 	src: local(""),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-700italic.woff2) format("woff2"),url(/themes/dfo-platform/drupal-platform-themes/dfo/assets/fonts/source-sans-pro/source-sans-pro-v18-latin-ext_latin-700italic.woff) format("woff");
  // }
  .h2 {
    font-family: 'Source Serif Pro', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    font-family: 'Source Serif Pro', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
  }
  .list__title {
    font-family: 'Source Serif Pro', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 12px;
    font-family: 'Source Serif Pro', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 12px;
  }
  .link-button {
    a {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      cursor: pointer;
      color: white !important;
      text-decoration: none !important;
      padding: 10px 24px;
      border: none;
      outline: none;
      box-shadow: none;
      background: #005b91;
      -webkit-transition: background-color 0.25s, border-color 0.25s;
      transition: background-color 0.25s, border-color 0.25s;
      line-height: inherit;
      &:hover {
        background-color: #009fe3;
      }
    }
  }
  .mobile-only {
    display: none;
    li {
      a {
        background-image: url(../../images/drupal/icons/arrow-link-blue.svg) !important;
        background-repeat: no-repeat !important;
        background-position: right 20px center !important;
        background-size: 21px !important;
        &:after {
          display: none;
        }
      }
    }
  }
  .desktop-only {
    display: none;
  }
  .wp-block-table {
    position: relative;
    margin: 40px 0 40px 0;
    figcaption {
      position: absolute;
      top: 0;
      width: 100%;
      border-top: 1px solid #ddd;
      padding-top: 20px;
      color: #555;
      font-size: 16px;
      text-align: center;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 70px;
    }
    thead {
      border: 0;
      th {
        border-bottom: 3px solid #d1d1d1;
      }
    }
    tfoot {
      border-top: 1px solid black;
    }
    td {
      padding: 12px 12px 12px 12px;
      font-size: 16px;
      word-break: normal;
      border: 1px solid #d1d1d1;
    }
    th {
      padding: 12px 12px 12px 12px;
      font-size: 16px;
      word-break: normal;
      border: 1px solid #d1d1d1;
      background: initial;
      text-align: left;
      font-weight: 600;
    }
    td.risk {
      padding-left: 40px;
      position: relative;
      &:after {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        left: 14px;
        top: calc(50% - 8px);
        border-radius: 50%;
      }
    }
    td.risk.circle.low {
      &:after {
        background-color: #00a982;
      }
    }
    td.risk.circle.medium {
      &:after {
        background-color: #f7b715;
      }
    }
    td.risk.circle.high {
      &:after {
        background-color: #f7808e;
      }
    }
    td.risk.circle.very-high {
      &:after {
        background-color: #e83f53;
      }
    }
    td.risk.graph {
      padding-left: 44px;
      &:after {
        width: 20px;
        height: 19px;
        top: calc(50% - 10px);
        border-radius: 0;
        background-color: transparent;
      }
    }
    td.risk.graph.low {
      &:after {
        background-image: url(../../images/drupal/icons/graph-low.svg);
      }
    }
    td.risk.graph.medium {
      &:after {
        background-image: url(../../images/drupal/icons/graph-medium.svg);
      }
    }
    td.risk.graph.high {
      &:after {
        background-image: url(../../images/drupal/icons/graph-high.svg);
      }
    }
    td.risk.graph.very-high {
      &:after {
        background-image: url(../../images/drupal/icons/graph-very-high.svg);
      }
    }
  }
  .wp-block-rm-advanced-table {
    position: relative;
    margin: 40px 0 40px 0;
    figcaption {
      position: absolute;
      top: 0;
      width: 100%;
      border-top: 1px solid #ddd;
      padding-top: 20px;
      color: #555;
      font-size: 16px;
      text-align: center;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 70px;
      margin-top: 0;
    }
    thead {
      border: 0;
      th {
        border-bottom: 3px solid #d1d1d1;
      }
    }
    tfoot {
      border-top: 1px solid black;
    }
    td {
      padding: 12px 12px 12px 12px;
      font-size: 16px;
      word-break: normal;
      border: 1px solid #d1d1d1;
    }
    th {
      padding: 12px 12px 12px 12px;
      font-size: 16px;
      word-break: normal;
      border: 1px solid #d1d1d1;
      background: initial;
      text-align: left;
      font-weight: 600;
    }
    td.risk {
      padding-left: 40px;
      position: relative;
      &:after {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        left: 14px;
        top: calc(50% - 8px);
        border-radius: 50%;
      }
    }
    td.risk.circle.low {
      &:after {
        background-color: #00a982;
      }
    }
    td.risk.circle.medium {
      &:after {
        background-color: #f7b715;
      }
    }
    td.risk.circle.high {
      &:after {
        background-color: #f7808e;
      }
    }
    td.risk.circle.very-high {
      &:after {
        background-color: #e83f53;
      }
    }
    td.risk.graph {
      padding-left: 44px;
      &:after {
        width: 20px;
        height: 19px;
        top: calc(50% - 10px);
        border-radius: 0;
        background-color: transparent;
      }
    }
    td.risk.graph.low {
      &:after {
        background-image: url(../../images/drupal/icons/graph-low.svg);
      }
    }
    td.risk.graph.medium {
      &:after {
        background-image: url(../../images/drupal/icons/graph-medium.svg);
      }
    }
    td.risk.graph.high {
      &:after {
        background-image: url(../../images/drupal/icons/graph-high.svg);
      }
    }
    td.risk.graph.very-high {
      &:after {
        background-image: url(../../images/drupal/icons/graph-very-high.svg);
      }
    }
  }
  .cke_editable {
    position: relative;
    margin: 40px 0 40px 0;
    figcaption {
      position: absolute;
      top: 0;
      width: 100%;
      border-top: 1px solid #ddd;
      padding-top: 20px;
      color: #555;
      font-size: 16px;
      text-align: center;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 70px;
      margin-top: 0;
    }
    thead {
      border: 0;
      th {
        border-bottom: 3px solid #d1d1d1;
      }
    }
    tfoot {
      border-top: 1px solid black;
    }
    td {
      padding: 12px 12px 12px 12px;
      font-size: 16px;
      word-break: normal;
      border: 1px solid #d1d1d1;
    }
    th {
      padding: 12px 12px 12px 12px;
      font-size: 16px;
      word-break: normal;
      border: 1px solid #d1d1d1;
      background: initial;
      text-align: left;
      font-weight: 600;
    }
    td.risk {
      padding-left: 40px;
      position: relative;
      &:after {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        left: 14px;
        top: calc(50% - 8px);
        border-radius: 50%;
      }
    }
    td.risk.circle.low {
      &:after {
        background-color: #00a982;
      }
    }
    td.risk.circle.medium {
      &:after {
        background-color: #f7b715;
      }
    }
    td.risk.circle.high {
      &:after {
        background-color: #f7808e;
      }
    }
    td.risk.circle.very-high {
      &:after {
        background-color: #e83f53;
      }
    }
    td.risk.graph {
      padding-left: 44px;
      &:after {
        width: 20px;
        height: 19px;
        top: calc(50% - 10px);
        border-radius: 0;
        background-color: transparent;
      }
    }
    td.risk.graph.low {
      &:after {
        background-image: url(../../images/drupal/icons/graph-low.svg);
      }
    }
    td.risk.graph.medium {
      &:after {
        background-image: url(../../images/drupal/icons/graph-medium.svg);
      }
    }
    td.risk.graph.high {
      &:after {
        background-image: url(../../images/drupal/icons/graph-high.svg);
      }
    }
    td.risk.graph.very-high {
      &:after {
        background-image: url(../../images/drupal/icons/graph-very-high.svg);
      }
    }
  }
  .wp-block-table.is-style-stripes {
    border-spacing: 0;
    border-collapse: inherit;
    background-color: transparent;
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f0f0f0;
        }
      }
      border: 1px solid #d1d1d1;
      th {
        border: 0;
      }
    }
    td {
      border: 0;
    }
    th {
      border: 0;
      border-top: 1px solid #d1d1d1;
    }
    tr {
      td {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
      th {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
    }
    thead {
      border: 1px solid #d1d1d1;
      th {
        border-bottom: 3px solid #d1d1d1;
      }
    }
    tfoot {
      border: 1px solid #d1d1d1;
    }
  }
  .wp-block-table.is-striped {
    border-spacing: 0;
    border-collapse: inherit;
    background-color: transparent;
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f0f0f0;
        }
      }
      border: 1px solid #d1d1d1;
      th {
        border: 0;
      }
    }
    td {
      border: 0;
    }
    th {
      border: 0;
      border-top: 1px solid #d1d1d1;
    }
    tr {
      td {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
      th {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
    }
    thead {
      border: 1px solid #d1d1d1;
      th {
        border-bottom: 3px solid #d1d1d1;
      }
    }
    tfoot {
      border: 1px solid #d1d1d1;
    }
  }
  .wp-block-rm-advanced-table.is-style-stripes {
    border-spacing: 0;
    border-collapse: inherit;
    background-color: transparent;
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f0f0f0;
        }
      }
      border: 1px solid #d1d1d1;
      th {
        border: 0;
      }
    }
    td {
      border: 0;
    }
    th {
      border: 0;
      border-top: 1px solid #d1d1d1;
    }
    tr {
      td {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
      th {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
    }
    thead {
      border: 1px solid #d1d1d1;
      th {
        border-bottom: 3px solid #d1d1d1;
      }
    }
    tfoot {
      border: 1px solid #d1d1d1;
    }
  }
  .wp-block-rm-advanced-table.is-striped {
    border-spacing: 0;
    border-collapse: inherit;
    background-color: transparent;
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f0f0f0;
        }
      }
      border: 1px solid #d1d1d1;
      th {
        border: 0;
      }
    }
    td {
      border: 0;
    }
    th {
      border: 0;
      border-top: 1px solid #d1d1d1;
    }
    tr {
      td {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
      th {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
    }
    thead {
      border: 1px solid #d1d1d1;
      th {
        border-bottom: 3px solid #d1d1d1;
      }
    }
    tfoot {
      border: 1px solid #d1d1d1;
    }
  }
  .cke_editable.is-style-stripes {
    border-spacing: 0;
    border-collapse: inherit;
    background-color: transparent;
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f0f0f0;
        }
      }
      border: 1px solid #d1d1d1;
      th {
        border: 0;
      }
    }
    td {
      border: 0;
    }
    th {
      border: 0;
      border-top: 1px solid #d1d1d1;
    }
    tr {
      td {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
      th {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
    }
    thead {
      border: 1px solid #d1d1d1;
      th {
        border-bottom: 3px solid #d1d1d1;
      }
    }
    tfoot {
      border: 1px solid #d1d1d1;
    }
  }
  .cke_editable.is-striped {
    border-spacing: 0;
    border-collapse: inherit;
    background-color: transparent;
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f0f0f0;
        }
      }
      border: 1px solid #d1d1d1;
      th {
        border: 0;
      }
    }
    td {
      border: 0;
    }
    th {
      border: 0;
      border-top: 1px solid #d1d1d1;
    }
    tr {
      td {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
      th {
        &:not(:last-child) {
          border-right: 1px solid #d1d1d1;
        }
      }
    }
    thead {
      border: 1px solid #d1d1d1;
      th {
        border-bottom: 3px solid #d1d1d1;
      }
    }
    tfoot {
      border: 1px solid #d1d1d1;
    }
  }
  .field--name-field-advanced-table {
    table {
      border: 0;
      float: none;
    }
    thead {
      th {
        font-weight: bold;
        border-bottom-width: 3px;
      }
      td {
        font-weight: bold;
        border-bottom-width: 3px;
      }
    }
  }
  table {
    p {
      margin: 0 !important;
    }
  }
  thead {
    td {
      font-weight: bold;
    }
  }
  .collapsable {
    td {
      vertical-align: top;
    }
  }
  .element-invisible {
    display: none;
  }
  #block-dfo-page-title {
    h1 {
      margin: 80px 0 24px 0;
      font-family: 'Source Serif Pro', serif;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: 62px;
      h1 {
        font-family: 'Source Serif Pro', serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
        margin: 0;
      }
    }
  }
  .field--name-body {
    margin-top: 56px;
    color: #333;
    a {
      color: #0e2a49;
      text-decoration: underline;
      &:hover {
        &:not(.card) {
          &:not(.card__title-link) {
            color: #009fe3;
          }
        }
      }
    }
  }
  ul.has-list-style-lower-latin {
    list-style: lower-latin;
  }
  ol.has-list-style-lower-latin {
    list-style: lower-latin;
  }
  ul.has-list-style-check-list {
    li {
      &::marker {
        content: '\2713';
        color: #005b91;
        font-size: 1.1em;
      }
    }
  }
  ol.has-list-style-check-list {
    li {
      &::marker {
        content: '\2713';
        color: #005b91;
        font-size: 1.1em;
      }
    }
  }
  .wp-block-drupal-section {
    details.collapsable {
      margin-bottom: 0;
      + {
        p {
          margin-top: 40px;
        }
        ul {
          margin-top: 40px;
        }
        ol {
          margin-top: 40px;
        }
        figure {
          margin-top: 40px;
        }
        figure.align-left {
          + {
            ul {
              margin-top: 40px !important;
            }
          }
        }
        .wp-block-rm-manual-content {
          margin-top: 40px;
        }
      }
      ol {
        margin-top: 40px !important;
      }
    }
    .wp-block-media-entity.type-document {
      + {
        .wp-block-media-entity.type-image {
          margin-top: 40px;
        }
      }
    }
    h1 {
      margin: 40px 0 8px 0;
      + {
        details.collapsable {
          margin-top: 0;
        }
        .card {
          margin-top: 0;
        }
        .wp-block-media-entity {
          margin-top: 0;
        }
        figure {
          margin-top: 0;
        }
        .wp-block-rm-factbox {
          margin-top: 0;
        }
        .wp-block-quote {
          margin-top: 0;
        }
        .wp-block-table {
          margin-top: 0;
        }
        .wp-block-cover {
          margin-top: 0;
        }
      }
      > span {
        > strong {
          font-weight: normal;
        }
      }
    }
    h2 {
      margin: 40px 0 8px 0;
      + {
        details.collapsable {
          margin-top: 0;
        }
        .card {
          margin-top: 0;
        }
        .wp-block-media-entity {
          margin-top: 0;
        }
        figure {
          margin-top: 0;
        }
        .wp-block-rm-factbox {
          margin-top: 0;
        }
        .wp-block-quote {
          margin-top: 0;
        }
        .wp-block-table {
          margin-top: 0;
        }
        .wp-block-cover {
          margin-top: 0;
        }
      }
      > span {
        > strong {
          font-weight: normal;
        }
      }
    }
    h3 {
      margin: 40px 0 8px 0;
      + {
        details.collapsable {
          margin-top: 0;
        }
        .card {
          margin-top: 0;
        }
        .wp-block-media-entity {
          margin-top: 0;
        }
        figure {
          margin-top: 0;
        }
        .wp-block-rm-factbox {
          margin-top: 0;
        }
        .wp-block-quote {
          margin-top: 0;
        }
        .wp-block-table {
          margin-top: 0;
        }
        .wp-block-cover {
          margin-top: 0;
        }
      }
      > span {
        > strong {
          font-weight: normal;
        }
      }
    }
    h4 {
      margin: 40px 0 8px 0;
      + {
        details.collapsable {
          margin-top: 0;
        }
        .card {
          margin-top: 0;
        }
        .wp-block-media-entity {
          margin-top: 0;
        }
        figure {
          margin-top: 0;
        }
        .wp-block-rm-factbox {
          margin-top: 0;
        }
        .wp-block-quote {
          margin-top: 0;
        }
        .wp-block-table {
          margin-top: 0;
        }
        .wp-block-cover {
          margin-top: 0;
        }
      }
      > span {
        > strong {
          font-weight: normal;
        }
      }
    }
    h5 {
      margin: 40px 0 8px 0;
      + {
        details.collapsable {
          margin-top: 0;
        }
        .card {
          margin-top: 0;
        }
        .wp-block-media-entity {
          margin-top: 0;
        }
        figure {
          margin-top: 0;
        }
        .wp-block-rm-factbox {
          margin-top: 0;
        }
        .wp-block-quote {
          margin-top: 0;
        }
        .wp-block-table {
          margin-top: 0;
        }
        .wp-block-cover {
          margin-top: 0;
        }
      }
      > span {
        > strong {
          font-weight: normal;
        }
      }
    }
    h6 {
      margin: 40px 0 8px 0;
      + {
        details.collapsable {
          margin-top: 0;
        }
        .card {
          margin-top: 0;
        }
        .wp-block-media-entity {
          margin-top: 0;
        }
        figure {
          margin-top: 0;
        }
        .wp-block-rm-factbox {
          margin-top: 0;
        }
        .wp-block-quote {
          margin-top: 0;
        }
        .wp-block-table {
          margin-top: 0;
        }
        .wp-block-cover {
          margin-top: 0;
        }
      }
      > span {
        > strong {
          font-weight: normal;
        }
      }
    }
    .wp-block-columns {
      .card {
        margin: 0 0 15px 0;
      }
      margin: 0 0 30px 0;
    }
    p {
      margin: 0 0 30px 0;
    }
    ul {
      margin: 0 0 30px 0;
      ul {
        margin-bottom: 0 !important;
      }
      ol {
        margin-bottom: 0 !important;
      }
    }
    ol {
      margin: 0 0 30px 0;
      ul {
        margin-bottom: 0 !important;
      }
      ol {
        margin-bottom: 0 !important;
      }
    }
    .card {
      margin: 40px 0 40px 0;
    }
    .wp-block-quote {
      margin: 40px 0 40px 0;
    }
    .wp-block-cover {
      margin: 40px 0 40px 0;
    }
    > * {
      &:last-child {
        margin-bottom: 0 !important;
        .wp-block-rm-manual-content {
          margin-bottom: 0 !important;
        }
        .list-items {
          article {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  main {
    > * {
      &:first-child {
        margin-top: 0;
      }
    }
    h1 {
      font-family: 'Source Serif Pro', serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 45px;
    }
    h2 {
      font-family: 'Source Serif Pro', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
    }
    h3 {
      font-family: 'Source Serif Pro', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
    h4 {
      font-family: 'Source Serif Pro', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
    h5 {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
    h6 {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
    }
    p {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    ul {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    li {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    table {
      th {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
      }
      td {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
      }
    }
    > #block-dfo-content {
      main.wp-block-drupal-section {
        h1 {
          + {
            .wp-block-columns {
              > .wp-block-column {
                > .card {
                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }
            .card {
              margin-top: 0;
            }
          }
        }
        h2 {
          + {
            .wp-block-columns {
              > .wp-block-column {
                > .card {
                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }
            .card {
              margin-top: 0;
            }
          }
        }
        h3 {
          + {
            .wp-block-columns {
              > .wp-block-column {
                > .card {
                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }
            .card {
              margin-top: 0;
            }
          }
        }
        h4 {
          + {
            .wp-block-columns {
              > .wp-block-column {
                > .card {
                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }
            .card {
              margin-top: 0;
            }
          }
        }
        h5 {
          + {
            .wp-block-columns {
              > .wp-block-column {
                > .card {
                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }
            .card {
              margin-top: 0;
            }
          }
        }
        h6 {
          + {
            .wp-block-columns {
              > .wp-block-column {
                > .card {
                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }
            .card {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .block-editor-block-list__layout {
    > * {
      &:first-child {
        margin-top: 0;
      }
    }
    h1 {
      font-family: 'Source Serif Pro', serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 45px;
    }
    h2 {
      font-family: 'Source Serif Pro', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
    }
    h3 {
      font-family: 'Source Serif Pro', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
    h4 {
      font-family: 'Source Serif Pro', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
    h5 {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
    h6 {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
    }
    p {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    ul {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    li {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    table {
      th {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
      }
      td {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
  .node-unpublished {
    h1 {
      &::after {
        padding-left: 3px;
        content: '*' !important;
        color: #f00000 !important;
      }
    }
  }
  details.collapsable {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    border-top: 1px solid #039fe3;
    border-bottom: 1px solid #039fe3;
    overflow: hidden;
    max-width: 953px;
    > summary {
      padding: 10px 50px 10px 20px;
      cursor: pointer;
      position: relative;
      display: block;
      font-weight: 600;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-down-blue.svg);
        position: absolute;
        right: 20px;
        width: 22px;
        height: 22px;
        top: 13px;
      }
      &::-webkit-details-marker {
        display: none;
      }
      &:focus {
        outline-color: #0e2a49;
      }
    }
    div.summary {
      padding: 10px 50px 10px 20px;
      cursor: pointer;
      position: relative;
      display: block;
      font-weight: 600;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-down-blue.svg);
        position: absolute;
        right: 20px;
        width: 22px;
        height: 22px;
        top: 13px;
      }
      &::-webkit-details-marker {
        display: none;
      }
      &:focus {
        outline-color: #0e2a49;
      }
    }
    > div {
      padding: 20px 20px 0 20px;
    }
    p {
      margin-bottom: 2rem;
    }
    + {
      details.collapsable {
        margin-top: -1px;
      }
    }
    .wp-block-rm-advanced-table {
      margin: 0;
      padding-bottom: 20px;
      max-width: 665px;
      * {
        font-size: 14px !important;
      }
    }
    .wp-block-media-entity {
      margin-top: 0 !important;
    }
    .wp-block-media-entity.align-left {
      + {
        ul {
          margin-top: 0;
        }
      }
      ol {
        margin-top: 0;
      }
    }
  }
  div.collapsable {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    border-top: 1px solid #039fe3;
    border-bottom: 1px solid #039fe3;
    overflow: hidden;
    max-width: 953px;
    > summary {
      padding: 10px 50px 10px 20px;
      cursor: pointer;
      position: relative;
      display: block;
      font-weight: 600;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-down-blue.svg);
        position: absolute;
        right: 20px;
        width: 22px;
        height: 22px;
        top: 13px;
      }
      &::-webkit-details-marker {
        display: none;
      }
      &:focus {
        outline-color: #0e2a49;
      }
    }
    div.summary {
      padding: 10px 50px 10px 20px;
      cursor: pointer;
      position: relative;
      display: block;
      font-weight: 600;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-down-blue.svg);
        position: absolute;
        right: 20px;
        width: 22px;
        height: 22px;
        top: 13px;
      }
      &::-webkit-details-marker {
        display: none;
      }
      &:focus {
        outline-color: #0e2a49;
      }
    }
    > div {
      padding: 20px 20px 0 20px;
    }
    p {
      margin-bottom: 2rem;
    }
    + {
      details.collapsable {
        margin-top: -1px;
      }
    }
    .wp-block-rm-advanced-table {
      margin: 0;
      padding-bottom: 20px;
      max-width: 665px;
      * {
        font-size: 14px !important;
      }
    }
    .wp-block-media-entity {
      margin-top: 0 !important;
    }
    .wp-block-media-entity.align-left {
      + {
        ul {
          margin-top: 0;
        }
      }
      ol {
        margin-top: 0;
      }
    }
  }
  details.collapsable[open] {
    > summary {
      background-color: #0e2a49;
      color: white;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-up-white.svg);
      }
    }
    > div.summary {
      background-color: #0e2a49;
      color: white;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-up-white.svg);
      }
    }
  }
  details.collapsable.edit-mode {
    > summary {
      background-color: #0e2a49;
      color: white;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-up-white.svg);
      }
    }
    > div.summary {
      background-color: #0e2a49;
      color: white;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-up-white.svg);
      }
    }
  }
  div.collapsable[open] {
    > summary {
      background-color: #0e2a49;
      color: white;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-up-white.svg);
      }
    }
    > div.summary {
      background-color: #0e2a49;
      color: white;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-up-white.svg);
      }
    }
  }
  div.collapsable.edit-mode {
    > summary {
      background-color: #0e2a49;
      color: white;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-up-white.svg);
      }
    }
    > div.summary {
      background-color: #0e2a49;
      color: white;
      &:after {
        content: url(../../images/drupal/icons/accordion-arrow-up-white.svg);
      }
    }
  }
  * {
    + {
      details.collapsable {
        margin-top: 0;
      }
    }
  }
  .field--name-field-intro-text {
    margin-bottom: 48px;
    p {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 33px;
      margin: 0;
    }
  }
  .wp-block-columns {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    + {
      .wp-block-image {
        margin: 0;
      }
      .card {
        margin-top: 0;
      }
      .wp-block-columns {
        .card {
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
  .wp-block-columns.has-background {
    padding: 1.25em 2.375em;
  }
  .wp-block-columns.are-vertically-aligned-top {
    align-items: flex-start;
  }
  .wp-block-columns.are-vertically-aligned-center {
    align-items: center;
  }
  .wp-block-columns.are-vertically-aligned-bottom {
    align-items: flex-end;
  }
  .wp-block-column {
    flex-direction: column;
    display: flex;
    word-break: break-word;
    overflow-wrap: break-word;
    .card {
      height: 100%;
    }
    .card.card--large {
      display: block;
      .card__title-link {
        display: block;
      }
      .card__illustration {
        width: auto;
        max-width: 100%;
      }
      .card__image {
        width: auto;
        max-width: 100%;
      }
      .card__copy {
        width: auto;
        max-width: 100%;
      }
    }
  }
  .wp-block-column.is-vertically-aligned-top {
    align-self: flex-start;
    width: 100%;
  }
  .wp-block-column.is-vertically-aligned-center {
    -ms-grid-row-align: center;
    align-self: center;
    width: 100%;
  }
  .wp-block-column.is-vertically-aligned-bottom {
    align-self: flex-end;
    width: 100%;
  }
  .wp-block-rm-factbox {
    border: solid 1px #039fe3;
    box-sizing: border-box;
    padding: 40px;
    margin: 40px 0 40px 0;
    .factbox__label {
      font-family: 'Source Serif Pro', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      margin: 0 0 8px 0;
    }
    .factbox__content {
      padding: 0;
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
    p {
      margin-bottom: 0;
      + {
        * {
          margin-top: 10px;
        }
      }
    }
  }
  .wp-block-rm-factbox.alignleft {
    padding: 20px;
    margin-top: 7px !important;
    margin-bottom: 37px !important;
  }
  .wp-block-rm-factbox.alignright {
    margin-top: 7px !important;
    padding: 20px;
    margin-bottom: 37px !important;
  }
  .card {
    border: 2px solid #e6e6e6;
    background-color: white;
    color: #0e2a49;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    overflow: auto;
    &:hover {
      border: 2px solid #0e2a49;
    }
    .card__title-link {
      display: block;
      text-decoration: none;
      color: #0e2a49;
      height: 100%;
    }
    .card__copy {
      padding: 32px 32px;
    }
    .card__title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 22px;
      line-height: 27px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;
    }
    p.card__body {
      margin-top: 8px;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    + {
      .card {
        margin-top: 16px;
      }
      figure {
        margin-top: 40px;
      }
      .wp-block-columns {
        .wp-block-column {
          .list__title {
            margin-top: 0;
          }
        }
      }
      .wp-block-group {
        margin-top: 16px;
      }
      article.node--view-mode-small {
        border-top: none;
      }
    }
    &:last-child {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    img {
      width: 100%;
      border-radius: 0;
    }
    .card__link-title {
      background-image: url(../../images/drupal/icons/arrow-link-blue.svg);
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 40px auto;
      position: absolute;
      right: 30px;
      bottom: 30px;
      padding: 0 54px 0 0px;
      height: 27px;
    }
    &:not(.card-front).is-external {
      .card__link-title {
        background-image: url(../../images/drupal/icons/external-link-blue.svg);
      }
    }
  }
  .card.card__title-link {
    &:hover {
      border-color: #0e2a49;
    }
  }
  .card.is-external {
    .card__title {
      span {
        margin-right: 28px;
        display: block;
      }
    }
  }
  .card.card--medium {
    .card__copy {
      padding: 32px;
    }
  }
  .card.card--button {
    .card__copy {
      padding: 24px;
    }
    .card__title {
      font-size: 18px;
    }
  }
  .card.card--large {
    display: flex;
    flex-direction: row;
    .card__copy {
      padding: 32px;
      min-width: calc(50% - 80px);
    }
    .card__illustration {
      min-width: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .card.card--large.card__title-link {
    text-decoration: none;
  }
  .card.card__with-icon--balance {
    background-image: url(../../images/drupal/icons/balance.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
    .card__title {
      padding-top: 54px;
      text-align: center;
    }
  }
  .card.card__with-icon--bubble-question {
    background-image: url(../../images/drupal/icons/bubble-question.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
    .card__title {
      padding-top: 54px;
      text-align: center;
    }
  }
  .card.card__with-icon--man-and-woman {
    background-image: url(../../images/drupal/icons/man-woman.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
    .card__title {
      padding-top: 54px;
      text-align: center;
    }
  }
  .card.card__with-icon--calendar {
    background-image: url(../../images/drupal/icons/calendar.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
    .card__title {
      padding-top: 54px;
      text-align: center;
    }
  }
  .card.card__with-icon--file-search {
    background-image: url(../../images/drupal/icons/file-search.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
    .card__title {
      padding-top: 54px;
      text-align: center;
    }
  }
  .card.card__with-icon--document {
    background-image: url(../../images/drupal/icons/document.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
    .card__title {
      padding-top: 54px;
      text-align: center;
    }
  }
  .card.card--process {
    ul.process-list {
      margin: 50px 0 0px;
      padding: 0 36px 0 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      flex: 1 1 0px;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      margin: 50px 0 30px;
      li {
        flex-grow: 1;
        &:nth-of-type(1) {
          z-index: 6;
        }
        &:nth-of-type(2) {
          z-index: 5;
        }
        &:nth-of-type(3) {
          z-index: 4;
        }
        &:nth-of-type(4) {
          z-index: 3;
        }
        &:nth-of-type(5) {
          z-index: 2;
        }
        &:nth-of-type(6) {
          z-index: 1;
        }
        &:first-child {
          span {
            padding-left: 20px;
          }
        }
        > span {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          line-height: 21px;
          font-weight: 600;
          color: #012a4c;
          padding: 0 0 0 40px;
          text-decoration: none;
          background: white;
          height: 99px;
          border-width: 3px 0 3px 3px;
          border-style: solid;
          border-color: #e6e6e6;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &::after {
            content: '';
            display: block;
            position: absolute;
            background: inherit;
            height: 148px;
            width: 149px;
            transform: rotate(45deg) skew(12deg, 12deg);
            border: 3px #e6e6e6 solid;
            top: -28px;
            right: -27px;
            z-index: -1;
          }
        }
        > span.blueArrow {
          &::after {
            border-color: #0e2a49;
          }
        }
      }
    }
    .card__link-title {
      display: none;
    }
  }
  .card.has-primary-light-blue-background-color {
    color: white !important;
    .card__link-title {
      background-image: url(../../images/drupal/icons/arrow-link.svg);
    }
    .card__title-link {
      color: white !important;
    }
  }
  .card.has-primary-dark-blue-background-color {
    color: white !important;
    .card__link-title {
      background-image: url(../../images/drupal/icons/arrow-link.svg);
    }
    .card__title-link {
      color: white !important;
    }
  }
  .card.external-link {
    border: none;
    border-top: solid 1px #009fe3;
    border-bottom: solid 1px #009fe3;
    background: inherit;
    margin: -1px 0 0 0;
    + {
      * {
        &:not(.card.external-link) {
          &:not(.card.external) {
            margin-top: 40px;
          }
        }
      }
    }
    &:last-child {
      margin-bottom: 40px;
    }
    &:first-child {
      margin-top: 40px;
    }
    &:only-of-type {
      margin-top: 40px;
    }
    a {
      &:hover {
        .card__copy {
          color: #009fe3;
        }
      }
    }
    .card__copy {
      color: #0e2a49;
      text-decoration: none;
      display: block;
      padding: 10px 50px 10px 20px;
      .card__title {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
  .card.external {
    border: none;
    border-top: solid 1px #009fe3;
    border-bottom: solid 1px #009fe3;
    background: inherit;
    margin: -1px 0 0 0;
    + {
      * {
        &:not(.card.external-link) {
          &:not(.card.external) {
            margin-top: 40px;
          }
        }
      }
    }
    &:last-child {
      margin-bottom: 40px;
    }
    &:first-child {
      margin-top: 40px;
    }
    &:only-of-type {
      margin-top: 40px;
    }
    a {
      &:hover {
        .card__copy {
          color: #009fe3;
        }
      }
    }
    .card__copy {
      color: #0e2a49;
      text-decoration: none;
      display: block;
      padding: 10px 50px 10px 20px;
      .card__title {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
  .wp-block-group {
    + {
      .wp-block-columns {
        > .wp-block-column {
          > .card {
            margin-top: 0;
          }
        }
      }
    }
    ul {
      display: grid;
      padding-left: 20px;
      + {
        p {
          margin-top: 18px;
        }
      }
    }
    ol {
      display: grid;
      padding-left: 20px;
      + {
        p {
          margin-top: 18px;
        }
      }
    }
  }
  .block-editor-block-list__block {
    + {
      .block-editor-block-list__block {
        .card {
          margin-top: 0;
        }
      }
    }
  }
  .card-front {
    position: relative;
    display: block;
    text-decoration: none !important;
    .card__link-title {
      background-image: url(../../images/drupal/icons/arrow-link-blue.svg);
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 40px auto;
      position: absolute;
      right: 30px;
      bottom: 30px;
      padding: 0 54px 0 0px;
      height: 27px;
    }
    .card__copy {
      padding: 40px 40px 70px 40px !important;
    }
    .card__prefix {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
    .card__body {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 26px;
      font-weight: 400;
      line-height: 32px;
      margin-top: 42px !important;
    }
  }
  .card--front {
    position: relative;
    display: block;
    text-decoration: none !important;
    .card__link-title {
      background-image: url(../../images/drupal/icons/arrow-link-blue.svg);
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 40px auto;
      position: absolute;
      right: 30px;
      bottom: 30px;
      padding: 0 54px 0 0px;
      height: 27px;
    }
    .card__copy {
      padding: 40px 40px 70px 40px !important;
    }
    .card__prefix {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
    .card__body {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 26px;
      font-weight: 400;
      line-height: 32px;
      margin-top: 42px !important;
    }
  }
  .card-front.is-external {
    .card__title {
      background-image: none;
    }
  }
  .card--front.is-external {
    .card__title {
      background-image: none;
    }
  }
  .card-front.card--medium {
    display: flex;
    flex-direction: row;
    .card__copy {
      order: 1;
      width: 60%;
      padding: 40px 35px 77px 40px;
    }
    .card__illustration {
      order: 2;
      width: 40%;
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        padding: 40px 40px 30px 0;
      }
    }
    .card__title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 26px;
      font-weight: 700;
      line-height: 32px;
    }
    .card__link-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      right: auto;
      left: 40px;
      bottom: 30px;
    }
    .card__body {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 33px;
      margin-top: 10px !important;
    }
  }
  .card--front.card--medium {
    display: flex;
    flex-direction: row;
    .card__copy {
      order: 1;
      width: 60%;
      padding: 40px 35px 77px 40px;
    }
    .card__illustration {
      order: 2;
      width: 40%;
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        padding: 40px 40px 30px 0;
      }
    }
    .card__title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 26px;
      font-weight: 700;
      line-height: 32px;
    }
    .card__link-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      right: auto;
      left: 40px;
      bottom: 30px;
    }
    .card__body {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 33px;
      margin-top: 10px !important;
    }
  }
  .card-front.has-primary-light-blue-background-color {
    color: white !important;
    .card__link-title {
      background-image: url(../../images/drupal/icons/arrow-link.svg);
    }
  }
  .card-front.has-primary-dark-blue-background-color {
    color: white !important;
    .card__link-title {
      background-image: url(../../images/drupal/icons/arrow-link.svg);
    }
  }
  .card--front.has-primary-light-blue-background-color {
    color: white !important;
    .card__link-title {
      background-image: url(../../images/drupal/icons/arrow-link.svg);
    }
  }
  .card--front.has-primary-dark-blue-background-color {
    color: white !important;
    .card__link-title {
      background-image: url(../../images/drupal/icons/arrow-link.svg);
    }
  }
  .wp-block-column.narrow-column {
    .card-front.card--medium {
      display: block;
      .card__copy {
        width: auto;
      }
      .card__illustration {
        width: auto;
        margin-right: 0;
        img {
          padding-bottom: 0;
          padding-left: 51px;
          width: auto;
        }
      }
    }
  }
  figure.media-type-block {
    .field--name-field-credit-text {
      text-align: right;
      width: 100%;
      font-style: italic;
      font-size: 0.8em;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    figcaption {
      margin-top: 5px;
    }
  }
  figure.wp-block-media-entity {
    .field--name-field-credit-text {
      text-align: right;
      width: 100%;
      font-style: italic;
      font-size: 0.8em;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    figcaption {
      margin-top: 5px;
    }
  }
  .field--name-field-image {
    .field--name-field-credit-text {
      text-align: right;
      width: 100%;
      font-style: italic;
      font-size: 0.8em;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    figcaption {
      margin-top: 5px;
    }
  }
  .field--name-field-media-image {
    .field--name-field-credit-text {
      text-align: right;
      width: 100%;
      font-style: italic;
      font-size: 0.8em;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    figcaption {
      margin-top: 5px;
    }
  }
  figure.media-type-block.type-image {
    display: inline-grid;
    margin-bottom: 30px;
  }
  figure.media-type-block.type-video {
    display: inline-grid;
    margin-bottom: 30px;
    width: 100%;
    .field--name-field-external-video-url {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  figure.media-type-block.wp-block-rm-media-type-image {
    display: inline-grid;
    margin-bottom: 30px;
  }
  figure.wp-block-media-entity.type-image {
    display: inline-grid;
    margin-bottom: 30px;
  }
  figure.wp-block-media-entity.type-video {
    display: inline-grid;
    margin-bottom: 30px;
    width: 100%;
    .field--name-field-external-video-url {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  figure.wp-block-media-entity.wp-block-rm-media-type-image {
    display: inline-grid;
    margin-bottom: 30px;
  }
  .field--name-field-image.type-image {
    display: inline-grid;
    margin-bottom: 30px;
  }
  .field--name-field-image.type-video {
    display: inline-grid;
    margin-bottom: 30px;
    width: 100%;
    .field--name-field-external-video-url {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .field--name-field-image.wp-block-rm-media-type-image {
    display: inline-grid;
    margin-bottom: 30px;
  }
  .field--name-field-media-image.type-image {
    display: inline-grid;
    margin-bottom: 30px;
  }
  .field--name-field-media-image.type-video {
    display: inline-grid;
    margin-bottom: 30px;
    width: 100%;
    .field--name-field-external-video-url {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .field--name-field-media-image.wp-block-rm-media-type-image {
    display: inline-grid;
    margin-bottom: 30px;
  }
  figure.media-type-block.wp-block-rm-media-type-video {
    width: 100%;
    .field--name-field-external-video-url {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  figure.wp-block-media-entity.wp-block-rm-media-type-video {
    width: 100%;
    .field--name-field-external-video-url {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .field--name-field-image.wp-block-rm-media-type-video {
    width: 100%;
    .field--name-field-external-video-url {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .field--name-field-media-image.wp-block-rm-media-type-video {
    width: 100%;
    .field--name-field-external-video-url {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  figure.media-type-block.type-document {
    border-top: solid 1px #009fe3;
    border-bottom: solid 1px #009fe3;
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      color: black;
      background-color: inherit;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: right 20px top 19px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;
      &:hover {
        .document_title {
          color: #009fe3;
        }
      }
    }
    .document_title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
      }
    }
    table {
      border-collapse: collapse;
      text-transform: uppercase;
      td {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
        padding: 0 24px 0 0;
      }
    }
  }
  figure.media-type-block.wp-block-rm-media-type-document {
    border-top: solid 1px #009fe3;
    border-bottom: solid 1px #009fe3;
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      color: black;
      background-color: inherit;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: right 20px top 19px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;
      &:hover {
        .document_title {
          color: #009fe3;
        }
      }
    }
    .document_title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
      }
    }
    table {
      border-collapse: collapse;
      text-transform: uppercase;
      td {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
        padding: 0 24px 0 0;
      }
    }
  }
  figure.wp-block-media-entity.type-document {
    border-top: solid 1px #009fe3;
    border-bottom: solid 1px #009fe3;
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      color: black;
      background-color: inherit;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: right 20px top 19px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;
      &:hover {
        .document_title {
          color: #009fe3;
        }
      }
    }
    .document_title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
      }
    }
    table {
      border-collapse: collapse;
      text-transform: uppercase;
      td {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
        padding: 0 24px 0 0;
      }
    }
  }
  figure.wp-block-media-entity.wp-block-rm-media-type-document {
    border-top: solid 1px #009fe3;
    border-bottom: solid 1px #009fe3;
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      color: black;
      background-color: inherit;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: right 20px top 19px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;
      &:hover {
        .document_title {
          color: #009fe3;
        }
      }
    }
    .document_title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
      }
    }
    table {
      border-collapse: collapse;
      text-transform: uppercase;
      td {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
        padding: 0 24px 0 0;
      }
    }
  }
  .field--name-field-image.type-document {
    border-top: solid 1px #009fe3;
    border-bottom: solid 1px #009fe3;
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      color: black;
      background-color: inherit;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: right 20px top 19px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;
      &:hover {
        .document_title {
          color: #009fe3;
        }
      }
    }
    .document_title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
      }
    }
    table {
      border-collapse: collapse;
      text-transform: uppercase;
      td {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
        padding: 0 24px 0 0;
      }
    }
  }
  .field--name-field-image.wp-block-rm-media-type-document {
    border-top: solid 1px #009fe3;
    border-bottom: solid 1px #009fe3;
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      color: black;
      background-color: inherit;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: right 20px top 19px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;
      &:hover {
        .document_title {
          color: #009fe3;
        }
      }
    }
    .document_title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
      }
    }
    table {
      border-collapse: collapse;
      text-transform: uppercase;
      td {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
        padding: 0 24px 0 0;
      }
    }
  }
  .field--name-field-media-image.type-document {
    border-top: solid 1px #009fe3;
    border-bottom: solid 1px #009fe3;
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      color: black;
      background-color: inherit;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: right 20px top 19px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;
      &:hover {
        .document_title {
          color: #009fe3;
        }
      }
    }
    .document_title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
      }
    }
    table {
      border-collapse: collapse;
      text-transform: uppercase;
      td {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
        padding: 0 24px 0 0;
      }
    }
  }
  .field--name-field-media-image.wp-block-rm-media-type-document {
    border-top: solid 1px #009fe3;
    border-bottom: solid 1px #009fe3;
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      color: black;
      background-color: inherit;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: right 20px top 19px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;
      &:hover {
        .document_title {
          color: #009fe3;
        }
      }
    }
    .document_title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
      }
    }
    table {
      border-collapse: collapse;
      text-transform: uppercase;
      td {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
        padding: 0 24px 0 0;
      }
    }
  }
  figure.media-type-block.align-left {
    float: left;
    width: 40%;
    margin-right: 20px;
    margin-top: 7px;
    margin-bottom: 27px;
    + {
      ul {
        display: grid;
        margin-top: 40px;
        padding-left: 20px;
        + {
          p {
            margin-top: 18px;
          }
        }
      }
      ol {
        display: grid;
        margin-top: 40px;
        padding-left: 20px;
        + {
          p {
            margin-top: 18px;
          }
        }
      }
      article {
        display: flex;
      }
    }
  }
  figure.wp-block-media-entity.align-left {
    float: left;
    width: 40%;
    margin-right: 20px;
    margin-top: 7px;
    margin-bottom: 27px;
    + {
      ul {
        display: grid;
        margin-top: 40px;
        padding-left: 20px;
        + {
          p {
            margin-top: 18px;
          }
        }
      }
      ol {
        display: grid;
        margin-top: 40px;
        padding-left: 20px;
        + {
          p {
            margin-top: 18px;
          }
        }
      }
      article {
        display: flex;
      }
    }
  }
  .field--name-field-image.align-left {
    float: left;
    width: 40%;
    margin-right: 20px;
    margin-top: 7px;
    margin-bottom: 27px;
    + {
      ul {
        display: grid;
        margin-top: 40px;
        padding-left: 20px;
        + {
          p {
            margin-top: 18px;
          }
        }
      }
      ol {
        display: grid;
        margin-top: 40px;
        padding-left: 20px;
        + {
          p {
            margin-top: 18px;
          }
        }
      }
      article {
        display: flex;
      }
    }
  }
  .field--name-field-media-image.align-left {
    float: left;
    width: 40%;
    margin-right: 20px;
    margin-top: 7px;
    margin-bottom: 27px;
    + {
      ul {
        display: grid;
        margin-top: 40px;
        padding-left: 20px;
        + {
          p {
            margin-top: 18px;
          }
        }
      }
      ol {
        display: grid;
        margin-top: 40px;
        padding-left: 20px;
        + {
          p {
            margin-top: 18px;
          }
        }
      }
      article {
        display: flex;
      }
    }
  }
  figure.media-type-block.align-right {
    float: right;
    width: 40%;
    margin-left: 20px;
    margin-top: 7px;
    margin-bottom: 27px;
  }
  figure.wp-block-media-entity.align-right {
    float: right;
    width: 40%;
    margin-left: 20px;
    margin-top: 7px;
    margin-bottom: 27px;
  }
  .field--name-field-image.align-right {
    float: right;
    width: 40%;
    margin-left: 20px;
    margin-top: 7px;
    margin-bottom: 27px;
  }
  .field--name-field-media-image.align-right {
    float: right;
    width: 40%;
    margin-left: 20px;
    margin-top: 7px;
    margin-bottom: 27px;
  }
  article.node--view-mode-medium {
    background: white;
    border: solid 2px #e6e6e6;
    margin: 0 0 30px 0;
    padding: 0;
    a[rel='bookmark'] {
      padding: 32px;
      display: block;
      text-decoration: none;
      color: #0e2a49;
      &:hover {
        color: #0e2a49;
        p {
          color: #0e2a49 !important;
        }
        span {
          color: #0e2a49 !important;
        }
        div.date {
          color: #0e2a49 !important;
        }
      }
    }
    &:hover {
      border-color: #0e2a49;
    }
    strong {
      color: #0e2a49;
      text-decoration: none;
      display: block;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 22px;
      line-height: 27px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .field--name-field-intro-text {
      margin-bottom: 0;
      margin-top: 0;
      p {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
      }
    }
    .field--name-published-at {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
    .date {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 14px;
      line-height: 18px;
      margin-top: 24px;
    }
  }
  article.node--view-mode-large {
    background: white;
    border: solid 2px #e6e6e6;
    margin: 0 0 30px 0;
    padding: 0;
    a[rel='bookmark'] {
      padding: 32px;
      display: block;
      text-decoration: none;
      color: #0e2a49;
      &:hover {
        color: #0e2a49;
        p {
          color: #0e2a49 !important;
        }
        span {
          color: #0e2a49 !important;
        }
        div.date {
          color: #0e2a49 !important;
        }
      }
    }
    &:hover {
      border-color: #0e2a49;
    }
    strong {
      color: #0e2a49;
      text-decoration: none;
      display: block;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 22px;
      line-height: 27px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .field--name-field-intro-text {
      margin-bottom: 0;
      margin-top: 0;
      p {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
      }
    }
    .field--name-published-at {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
    .date {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 14px;
      line-height: 18px;
      margin-top: 24px;
    }
  }
  article.node--view-mode-small {
    background: inherit;
    border-top: solid 1px #009fe3;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px #009fe3;
    padding: 0;
    margin: 0;
    &:hover {
      strong {
        a {
          color: #009fe3;
        }
      }
      h2 {
        a {
          color: #009fe3;
        }
      }
      .card__copy {
        color: #009fe3;
      }
    }
    strong {
      a {
        color: #0e2a49;
        text-decoration: none;
        display: block;
        padding: 10px 50px 10px 20px;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        background-image: url(../../images/drupal/icons/arrow-link-blue.svg);
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: 21px;
      }
    }
    h2 {
      a {
        color: #0e2a49;
        text-decoration: none;
        display: block;
        padding: 10px 50px 10px 20px;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        background-image: url(../../images/drupal/icons/arrow-link-blue.svg);
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: 21px;
      }
    }
    .card__copy {
      color: #0e2a49;
      text-decoration: none;
      display: block;
      padding: 10px 50px 10px 20px;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      background-image: url(../../images/drupal/icons/arrow-link-blue.svg);
      background-repeat: no-repeat;
      background-position: right 20px center;
      background-size: 21px;
    }
    + {
      article.node--view-mode-small {
        border-top: none;
      }
      * {
        &:not(article.node--view-mode-small) {
          margin-top: 40px;
        }
      }
    }
  }
  article {
    .metadata {
      margin-top: 10px;
      display: flex;
      > * {
        margin-right: 10px;
      }
    }
    > * {
      &:last-child {
        margin-bottom: 0;
        .wp-block-rm-manual-content {
          margin-bottom: 0;
        }
        .list-items {
          > * {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .node__contact-wrapper {
      padding-left: 20px;
      padding-right: 20px;
      background-color: #efefef;
      padding-top: 40px;
      padding-bottom: 40px;
      color: #333;
      margin-bottom: -80px;
      margin-top: 80px;
      > h2 {
        color: #0e2a49;
        margin: 0 0 10px 0;
      }
      a {
        color: #0e2a49;
        &:hover {
          color: #009fe3;
        }
      }
      .contact-info {
        margin-bottom: 0;
        padding: 0;
      }
      .field--name-field-tittel {
        font-family: 'Source Serif Pro', serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 33px;
        margin-bottom: 8px;
        margin-top: 0;
      }
      .field--name-field-contact-details {
        p {
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .field--name-field-contact {
        + {
          .field--name-field-contact-person {
            margin-top: 40px;
            ul {
              margin: 10px 0 8px 0;
            }
          }
        }
      }
    }
  }
  .content-list-wrapper {
    margin-bottom: 40px;
    > h2 {
      margin: 40px 0 8px 0;
      color: #0e2a49;
    }
    > .list__title {
      margin: 40px 0 8px 0;
      color: #0e2a49;
    }
    .field--name-field-intro-text {
      margin-bottom: 0;
    }
    .field--name-published-at {
      margin-top: 24px;
    }
    .date {
      margin-top: 24px;
    }
    .card.external-link {
      margin: 0;
      + {
        .card.external-link {
          border-top: 0;
        }
      }
    }
    article.node--view-mode-medium {
      margin: 0 0 15px 0;
    }
    article.node--view-mode-large {
      margin: 0 0 15px 0;
    }
    .card.is-external {
      .card__title {
        background: none;
      }
    }
    .wp-block-media-entity {
      + {
        .wp-block-rm-link-block {
          margin-top: -1px !important;
        }
      }
    }
    .list-items {
      > * {
        &:not(:first-child) {
          margin-top: -1px !important;
        }
      }
    }
    .node-unpublished {
      strong {
        span {
          &::after {
            content: '*';
            color: red;
          }
        }
      }
    }
  }
  .manual-content-list-wrapper {
    margin-bottom: 40px;
    > h2 {
      margin: 40px 0 8px 0;
      color: #0e2a49;
    }
    > .list__title {
      margin: 40px 0 8px 0;
      color: #0e2a49;
    }
    .field--name-field-intro-text {
      margin-bottom: 0;
    }
    .field--name-published-at {
      margin-top: 24px;
    }
    .date {
      margin-top: 24px;
    }
    .card.external-link {
      margin: 0;
      + {
        .card.external-link {
          border-top: 0;
        }
      }
    }
    article.node--view-mode-medium {
      margin: 0 0 15px 0;
    }
    article.node--view-mode-large {
      margin: 0 0 15px 0;
    }
    .card.is-external {
      .card__title {
        background: none;
      }
    }
    .wp-block-media-entity {
      + {
        .wp-block-rm-link-block {
          margin-top: -1px !important;
        }
      }
    }
    .list-items {
      > * {
        &:not(:first-child) {
          margin-top: -1px !important;
        }
      }
    }
    .node-unpublished {
      strong {
        span {
          &::after {
            content: '*';
            color: red;
          }
        }
      }
    }
    .wp-block-rm-link-block {
      border-bottom: solid 1px #009fe3;
      .card__copy {
        color: #0e2a49;
        text-decoration: none;
        display: block;
        padding: 10px 50px 10px 20px;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
      }
      a.card__title-link {
        &:hover {
          span {
            color: #009fe3;
          }
        }
      }
    }
  }
  .content-list-wrapper.align-right {
    > h2 {
      margin-top: 0;
    }
    > .list__title {
      margin-top: 0;
    }
  }
  .content-list-wrapper.alignright {
    > h2 {
      margin-top: 0;
    }
    > .list__title {
      margin-top: 0;
    }
  }
  .content-list-wrapper.align-left {
    > h2 {
      margin-top: 0;
    }
    > .list__title {
      margin-top: 0;
    }
  }
  .content-list-wrapper.alignleft {
    > h2 {
      margin-top: 0;
    }
    > .list__title {
      margin-top: 0;
    }
  }
  .manual-content-list-wrapper.align-right {
    > h2 {
      margin-top: 0;
    }
    > .list__title {
      margin-top: 0;
    }
  }
  .manual-content-list-wrapper.alignright {
    > h2 {
      margin-top: 0;
    }
    > .list__title {
      margin-top: 0;
    }
  }
  .manual-content-list-wrapper.align-left {
    > h2 {
      margin-top: 0;
    }
    > .list__title {
      margin-top: 0;
    }
  }
  .manual-content-list-wrapper.alignleft {
    > h2 {
      margin-top: 0;
    }
    > .list__title {
      margin-top: 0;
    }
  }
  .content-list-wrapper.align-center {
    clear: both;
  }
  .manual-content-list-wrapper.align-center {
    clear: both;
  }
  blockquote.wp-block-quote {
    padding: 32px;
    border: solid 2px #009fe3;
    border-left: none;
    border-right: none;
    p {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      &:last-child {
        margin-bottom: 0;
      }
    }
    cite {
      font-size: 14px;
      line-height: 17.6px;
      font-weight: 600;
      font-style: italic;
      color: #333;
      text-align: right;
      font-style: italic;
      display: inherit;
      margin: -14px 0 0 0;
    }
    .wp-block-quote__citation {
      font-size: 14px;
      line-height: 17.6px;
      font-weight: 600;
      font-style: italic;
      color: #333;
      text-align: right;
      font-style: italic;
      display: inherit;
      margin: -14px 0 0 0;
    }
  }
  blockquote.wp-block-quote.is-style-lovtekst {
    border: none;
    background: rgba(0, 159, 227, 0.05);
    p {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
    }
    cite {
      font-size: 14px;
      line-height: 17.6px;
      font-weight: 400;
      font-style: italic;
    }
    .wp-block-quote__citation {
      font-size: 14px;
      line-height: 17.6px;
      font-weight: 400;
      font-style: italic;
    }
  }
  blockquote.wp-block-quote.alignleft {
    width: 40%;
    float: left;
    margin-top: 0;
    margin-right: 20px;
    padding: 20px;
  }
  blockquote.wp-block-quote.alignright {
    width: 40%;
    float: right;
    margin-top: 0;
    margin-left: 20px;
    padding: 20px;
  }
  body.page-node-type-landing-page {
    overflow-x: hidden;
  }
  .wp-block-cover {
    width: auto;
    padding: 48px 0;
    .wp-block-cover__inner-container {
      width: 100%;
      > h2 {
        &:first-of-type {
          margin: 0 0 30px 0;
        }
      }
    }
    .wp-block-columns {
      &:last-child {
        margin-bottom: 0;
      }
      .wp-block-column {
        > * {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .wp-block-cover.wide {
    padding-left: 20px;
    padding-right: 20px;
  }
  .wp-block-cover.has-tertiary-green-background-color {
    background-color: #00a982;
  }
  .wp-block-cover.has-white-background-color {
    background-color: white;
  }
  .wp-block-cover.has-primary-light-blue-background-color {
    background-color: #039fe3;
  }
  .wp-block-cover.has-primary-dark-blue-background-color {
    background-color: #0e2a49;
  }
  .wp-block-cover.has-warning-yellow-background-color {
    background-color: #f7b715;
  }
  .wp-block-cover.has-dark-grey-background-color {
    background-color: #d1d1d1;
  }
  .contact-info {
    background-color: #efefef;
    padding: 24px;
    margin-bottom: 48px;
    .field--name-field-tittel {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 8px;
      color: #0e2a49;
    }
    .list__title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 8px;
      color: #0e2a49;
    }
    .field--name-field-contact-details {
      ul {
        &:last-child {
          margin-bottom: 0;
        }
      }
      p {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .components-form-token-field__help {
      font-size: smaller;
      font-style: italic;
    }
    > .contact-info {
      padding: 0;
      margin-bottom: 0;
      + {
        .profiles {
          margin-top: 30px;
        }
      }
    }
    .profiles {
      ul {
        margin-bottom: 0;
      }
      .profile {
        display: inline-block;
      }
    }
  }
  .wp-block-dfo-contact-info {
    background-color: #efefef;
    padding: 24px;
    margin-bottom: 48px;
    .field--name-field-tittel {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 8px;
      color: #0e2a49;
    }
    .list__title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 8px;
      color: #0e2a49;
    }
    .field--name-field-contact-details {
      ul {
        &:last-child {
          margin-bottom: 0;
        }
      }
      p {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .components-form-token-field__help {
      font-size: smaller;
      font-style: italic;
    }
    > .contact-info {
      padding: 0;
      margin-bottom: 0;
      + {
        .profiles {
          margin-top: 30px;
        }
      }
    }
    .profiles {
      ul {
        margin-bottom: 0;
      }
      .profile {
        display: inline-block;
      }
    }
  }
  html {
    background-color: #0e2a49;
  }
  body {
    background-color: #0e2a49;
    font-family: 'Source Sans Pro', sans-serif;
    background: #f7f7f7;
    color: #0e2a49;
    margin: 0;
    overflow-x: hidden;
  }
  a {
    &:focus-visible {
      outline-color: #0e2a49;
      outline-style: groove;
    }
  }
  button {
    &:focus-visible {
      outline-color: #0e2a49;
      outline-style: groove;
    }
  }
  a.popup {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    &::after {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      height: 20px;
      width: 20px;
      background: url(../../images/drupal/images/icon_magnifyingglass.png)
        no-repeat top left;
    }
  }
  h1 {
    color: #0e2a49;
  }
  h2 {
    color: #0e2a49;
  }
  h3 {
    color: #0e2a49;
  }
  h4 {
    color: #0e2a49;
  }
  h5 {
    color: #0e2a49;
  }
  h6 {
    color: #0e2a49;
  }
  .date.bottom {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    margin-top: 64px;
  }
  .views-header {
    a {
      color: #0e2a49;
      text-decoration: underline;
      &:hover {
        color: #009fe3;
      }
    }
  }
  a.button {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    color: white !important;
    text-decoration: none !important;
    padding: 10px 24px;
    border: none;
    outline: none;
    box-shadow: none;
    background: #005b91;
    -webkit-transition: background-color 0.25s, border-color 0.25s;
    transition: background-color 0.25s, border-color 0.25s;
    line-height: inherit;
    &:hover {
      background-color: #009fe3;
    }
  }
  a.button.red {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    color: white !important;
    text-decoration: none !important;
    padding: 10px 24px;
    border: none;
    outline: none;
    box-shadow: none;
    background: #e83f53;
    -webkit-transition: background-color 0.25s, border-color 0.25s;
    transition: background-color 0.25s, border-color 0.25s;
    line-height: inherit;
    &:hover {
      background-color: #ec5f70;
    }
  }
  a.button.cancel {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    color: white !important;
    text-decoration: none !important;
    padding: 10px 24px;
    border: none;
    outline: none;
    box-shadow: none;
    background: #e83f53;
    -webkit-transition: background-color 0.25s, border-color 0.25s;
    transition: background-color 0.25s, border-color 0.25s;
    line-height: inherit;
    &:hover {
      background-color: #ec5f70;
    }
  }
  a.button.green {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    color: white !important;
    text-decoration: none !important;
    padding: 10px 24px;
    border: none;
    outline: none;
    box-shadow: none;
    background: #00a982;
    -webkit-transition: background-color 0.25s, border-color 0.25s;
    transition: background-color 0.25s, border-color 0.25s;
    line-height: inherit;
    &:hover {
      background-color: #00cd9d;
    }
  }
  a.button.ok {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    color: white !important;
    text-decoration: none !important;
    padding: 10px 24px;
    border: none;
    outline: none;
    box-shadow: none;
    background: #00a982;
    -webkit-transition: background-color 0.25s, border-color 0.25s;
    transition: background-color 0.25s, border-color 0.25s;
    line-height: inherit;
    &:hover {
      background-color: #00cd9d;
    }
  }
  .field--label-inline {
    display: flex;
    .field-label {
      margin-right: 5px;
      &:after {
        content: ':';
      }
    }
  }
  #block-dfo-breadcrumbs {
    ol {
      margin: 40px 0 0 0;
      padding: 0;
      li {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 18px;
        display: inline;
        &:not(:last-child) {
          &:after {
            content: '/';
          }
        }
        a {
          color: #0e2a49;
          font-weight: 700;
          &:hover {
            color: #009fe3;
          }
        }
      }
    }
  }
  .block-facets {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin: 24px 0;
    .search__facet-label {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      color: #333;
    }
    .facet-block__title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      color: #333;
    }
    .facets-widget-checkbox {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-right: 20px;
          margin-top: 8px;
          display: flex;
          .facet-item__value {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            color: #0e2a49;
          }
          .facet-item__count {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            color: #0e2a49;
          }
          input.facets-checkbox {
            box-sizing: border-box;
            flex-shrink: 0;
            border: 2px solid #005b91;
            box-shadow: none;
            -webkit-appearance: none;
            outline: none;
            background-color: #fff;
            padding: 7px;
            height: 1em;
            width: 1em;
            margin: 5px 10px 0 0;
            &:checked {
              background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.408 19.422c.123.052.254.078.387.078h.051l.018-.001a1.002 1.002 0 0 0 .738-.406l.001-.002 13.093-17.57L20.094.295 7.689 16.941 1.716 10.89.298 12.327l6.783 6.874c.093.095.204.17.327.221z' fill='%23fff'/%3E%3C/svg%3E")
                no-repeat 50%;
              background-size: calc(17px - 8px);
              background-color: #005b91;
            }
            &:hover {
              border-width: 4px;
              padding: 5px;
            }
          }
          input.form-checkbox {
            box-sizing: border-box;
            flex-shrink: 0;
            border: 2px solid #005b91;
            box-shadow: none;
            -webkit-appearance: none;
            outline: none;
            background-color: #fff;
            padding: 7px;
            height: 1em;
            width: 1em;
            margin: 5px 10px 0 0;
            &:checked {
              background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.408 19.422c.123.052.254.078.387.078h.051l.018-.001a1.002 1.002 0 0 0 .738-.406l.001-.002 13.093-17.57L20.094.295 7.689 16.941 1.716 10.89.298 12.327l6.783 6.874c.093.095.204.17.327.221z' fill='%23fff'/%3E%3C/svg%3E")
                no-repeat 50%;
              background-size: calc(17px - 8px);
              background-color: #005b91;
            }
            &:hover {
              border-width: 4px;
              padding: 5px;
            }
          }
        }
      }
    }
  }
  .facet-block {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin: 24px 0;
    .search__facet-label {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      color: #333;
    }
    .facet-block__title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      color: #333;
    }
    .facets-widget-checkbox {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-right: 20px;
          margin-top: 8px;
          display: flex;
          .facet-item__value {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            color: #0e2a49;
          }
          .facet-item__count {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            color: #0e2a49;
          }
          input.facets-checkbox {
            box-sizing: border-box;
            flex-shrink: 0;
            border: 2px solid #005b91;
            box-shadow: none;
            -webkit-appearance: none;
            outline: none;
            background-color: #fff;
            padding: 7px;
            height: 1em;
            width: 1em;
            margin: 5px 10px 0 0;
            &:checked {
              background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.408 19.422c.123.052.254.078.387.078h.051l.018-.001a1.002 1.002 0 0 0 .738-.406l.001-.002 13.093-17.57L20.094.295 7.689 16.941 1.716 10.89.298 12.327l6.783 6.874c.093.095.204.17.327.221z' fill='%23fff'/%3E%3C/svg%3E")
                no-repeat 50%;
              background-size: calc(17px - 8px);
              background-color: #005b91;
            }
            &:hover {
              border-width: 4px;
              padding: 5px;
            }
          }
          input.form-checkbox {
            box-sizing: border-box;
            flex-shrink: 0;
            border: 2px solid #005b91;
            box-shadow: none;
            -webkit-appearance: none;
            outline: none;
            background-color: #fff;
            padding: 7px;
            height: 1em;
            width: 1em;
            margin: 5px 10px 0 0;
            &:checked {
              background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.408 19.422c.123.052.254.078.387.078h.051l.018-.001a1.002 1.002 0 0 0 .738-.406l.001-.002 13.093-17.57L20.094.295 7.689 16.941 1.716 10.89.298 12.327l6.783 6.874c.093.095.204.17.327.221z' fill='%23fff'/%3E%3C/svg%3E")
                no-repeat 50%;
              background-size: calc(17px - 8px);
              background-color: #005b91;
            }
            &:hover {
              border-width: 4px;
              padding: 5px;
            }
          }
        }
      }
    }
  }
  .page-node-type-full-width {
    .block-page-title-block {
      display: none;
    }
    iframe {
      width: 100%;
      border-width: 0;
      margin-top: 40px;
    }
  }
  #block-dfo-primary-local-tasks {
    > ul {
      padding: 0;
      li {
        display: inline-block;
        > a {
          padding: 5px 8px;
          background: #a6ddf5;
          color: black;
          text-decoration: none;
          &:hover {
            &:not(.is-active) {
              -webkit-transition: background-color 300ms ease;
              -ms-transition: background-color 300ms ease;
              transition: background-color 300ms ease;
              background: #c6e9f9;
            }
          }
        }
        > a.is-active {
          background: #0e2a49;
          color: white;
        }
      }
    }
    position: fixed;
    bottom: 40px;
    right: 20px;
    background: rgba(255, 255, 255, 0.7);
    padding: 20px;
    z-index: 100;
    ul {
      margin: 0;
    }
  }
  header {
    position: relative;
    display: table;
    width: 100%;
    height: 80px;
    border-bottom: 2px solid #e6e6e6;
    background-color: #fff;
    .main-container {
      position: relative;
    }
  }
  .wrapper-logo-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .region-header {
      width: 100%;
    }
  }
  #block-dfo-site-branding {
    margin: 24px 0;
    a {
      img {
        width: 100%;
        max-width: 375px;
      }
    }
    .mobile-logo {
      display: none;
    }
  }
  .header-navigation {
    width: 100%;
  }
  .controlswrapper {
    display: flex;
    justify-content: flex-end;
  }
  .controlswrapper.js-dfo-open {
    display: none;
  }
  .searchwrapper {
    display: none;
    form {
      border: solid 3px black;
      display: flex;
      position: relative;
      input.form-text {
        padding: 5px;
        border: none;
        width: calc(100% - 20px) !important;
        outline: none;
      }
      input.form-submit {
        height: 16px;
        padding: 4px;
        width: 20px;
        display: block;
      }
    }
  }
  .searchwrapper.js-dfo-open {
    display: block;
  }
  .searchtoggle {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    background: inherit;
    margin: 0 17px;
    background-repeat: no-repeat;
    background-position: right;
    padding-right: 20px;
    background-image: url(../../images/drupal/icons/search.svg);
    background-size: 15px;
    background-color: transparent;
    color: #0e2a49;
    &:hover {
      color: #009fe3;
    }
  }
  .menutoggle {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    background: inherit;
    margin: 0 17px;
    background-repeat: no-repeat;
    background-position: right;
    padding-right: 40px;
    background-image: url(../../images/drupal/icons/menu-burger.svg);
    background-size: 37px 34px;
    margin-right: 0;
    background-color: transparent;
    color: #0e2a49;
    &:hover {
      color: #009fe3;
    }
  }
  .menutoggle.menu-open {
    background-image: url(../../images/drupal/icons/menu-burger-close.svg);
  }
  .username {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    background-image: url(../../images/drupal/icons/user.svg);
    background-repeat: no-repeat;
    background-position: left 4px;
    margin-top: 1px;
    padding-left: 18px;
    position: relative;
    a {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: #0e2a49;
      text-decoration: none;
      &:hover {
        color: #009fe3;
      }
    }
  }
  .log-in {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 600;
    background-image: url(../../images/drupal/icons/user.svg);
    background-repeat: no-repeat;
    background-position: left 4px;
    color: #0e2a49;
    text-decoration: none;
    padding-left: 18px;
    &:hover {
      color: #009fe3;
    }
  }
  .username-popup {
    padding: 32px;
    width: calc(259px - 32px * 2);
    background-color: white;
    position: absolute;
    left: 0;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    display: none;
    > a {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .my-page-button {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      cursor: pointer;
      color: white !important;
      text-decoration: none !important;
      padding: 10px 24px;
      border: none;
      outline: none;
      box-shadow: none;
      background: #0e2a49;
      -webkit-transition: background-color 0.25s, border-color 0.25s;
      transition: background-color 0.25s, border-color 0.25s;
      line-height: inherit;
      width: 140px;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      &:hover {
        background-color: #143b67;
      }
    }
    .log-out {
      text-decoration: underline;
    }
  }
  .username-popup.show {
    display: flex;
  }
  .js-dfo-open {
    display: block;
  }
  .main-menu-outside-burger {
    ul {
      margin: 0;
      li {
        display: inline-block;
        a {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 16px;
          font-weight: 600;
          text-decoration: none;
          color: #0e2a49;
          margin: 0 17px;
          &:hover {
            color: #009fe3;
          }
        }
        a.is-active {
          color: #009fe3;
        }
      }
    }
  }
  .main-menu {
    display: block;
    clear: both;
    margin-bottom: 20px;
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        display: inline-block;
        width: 100%;
        a {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          line-height: 23px;
          display: block;
          background: #f7f7f7;
          border-top: solid 1px #039fe3;
          color: #0e2a49;
          text-decoration: none;
          padding: 15px 40px 15px 20px;
          &::after {
            content: url(../../images/drupal/icons/accordion-arrow-down-blue.svg);
            position: absolute;
            right: 40px;
            width: 14px;
            height: 22px;
          }
        }
        a.is-active {
          color: #009fe3;
        }
        &:hover {
          > a {
            background: #0e2a49;
            color: white;
            &::after {
              content: url(../../images/drupal/icons/accordion-arrow-down-white.svg);
              position: absolute;
              right: 20px;
              width: 22px;
              height: 22px;
            }
          }
        }
        ul {
          overflow: hidden;
          li {
            a {
              background: white;
              border: 0;
              position: relative;
              &:hover {
                background: inherit;
                color: #009fe3;
              }
              &::after {
                display: none;
              }
            }
            a.is-active {
              color: #009fe3;
              font-weight: 600;
            }
            a.menu-node-unpublished {
              &::after {
                display: inline;
                content: '*';
              }
            }
          }
          li.menu-item--active-trail {
            a {
              color: #009fe3;
              font-weight: 600;
            }
          }
        }
        ul.js-dfo-closed {
          display: none;
        }
      }
      li.js-dfo-active {
        > a {
          background: #0e2a49;
          color: white;
          &::after {
            content: url(../../images/drupal/icons/accordion-arrow-down-white.svg);
            position: absolute;
            right: 20px;
            width: 22px;
            height: 22px;
            transform: rotateX(180deg);
          }
        }
      }
      li.menu-item--active-trail {
        > a {
          &::after {
            transform: rotateX(180deg);
          }
        }
      }
    }
  }
  .main-menu.js-dfo-closed {
    display: none;
    visibility: hidden;
  }
  > .main-menu {
    ul {
      li.menu-item--active-trail {
        > a {
          background: #0e2a49;
          color: white;
          &::after {
            content: url(../../images/drupal/icons/accordion-arrow-down-white.svg);
            position: absolute;
            right: 20px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
  .menu-node-unpublished {
    &::after {
      color: #f00000;
      content: '*';
      padding-left: 3px;
    }
  }
  .region-footer {
    ul.menu {
      margin: 10px 0 20px 0;
      padding: 0;
      li.menu-item {
        list-style: none;
        width: 100%;
        a {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          line-height: 27px;
          background-image: url(../../images/drupal/icons/arrow-link.svg);
          color: white;
          background-repeat: no-repeat;
          background-position: calc(100% - 20px) center;
          background-size: 21px;
          display: block;
          text-decoration: none;
          padding: 10px 82px 10px 20px;
          border-top: solid 1px #039fe3;
          &:hover {
            color: #009fe3;
          }
        }
        a[target='_blank'] {
          background-image: url(../../images/drupal/icons/external-link.svg);
          background-size: 22px;
        }
        &:nth-last-child(1) {
          a {
            border-bottom: solid 1px #039fe3;
          }
        }
      }
    }
  }
  .block-content-sub-menu {
    margin-top: 40px;
    h2 {
      margin: 0 0 8px 0;
    }
    ul.menu {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        background: inherit;
        border-left: none;
        border-right: none;
        border-bottom: none;
        padding: 0;
        margin: 0;
        &:last-child {
          border-bottom: solid 1px #009fe3;
        }
        a {
          border-top: solid 1px #009fe3;
          color: #0e2a49;
          text-decoration: none;
          display: block;
          padding: 10px 50px 10px 20px;
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          background-image: url(../../images/drupal/icons/arrow-link-blue.svg);
          background-repeat: no-repeat;
          background-position: right 20px center;
          background-size: 21px;
          &:hover {
            color: #009fe3;
          }
        }
        a.is-active {
          display: none;
        }
      }
    }
  }
  #block-content-outiside-sub-menu-items.block-content-sub-menu {
    margin-bottom: 40px;
  }
  .page-node-type-landing-page {
    .block-content-sub-menu {
      h2 {
        display: none;
      }
      ul.menu {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-bottom: 15px;
          &:last-child {
            border-bottom: none;
          }
          a {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 22px;
            line-height: 27px;
            font-weight: 600;
            border: 2px solid #e6e6e6;
            background-color: white;
            color: #0e2a49;
            border-radius: 0;
            box-shadow: none;
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            padding: 32px;
            background-image: none;
            height: 100%;
            &:hover {
              border: 2px solid #0e2a49;
              border-color: #0e2a49;
            }
          }
          a.card__title-link {
            &:hover {
              border-color: #0e2a49;
            }
          }
        }
        a {
          + {
            ul.menu {
              margin-top: 15px;
            }
          }
        }
      }
    }
    #block-dfo-page-title.block-core {
      display: none;
    }
  }
  footer[role='contentinfo'] {
    display: table;
    width: 100%;
    background: #0e2a49;
    padding: 60px 0;
    color: white;
    a {
      color: inherit;
      text-decoration: underline;
    }
    .main-container {
      display: flex;
    }
    ul.menu {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .footer__contact-wrapper {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
      margin-left: 8.33333%;
    }
    .footer__contact {
      list-style-type: none;
      color: #fefefe;
      padding-left: 0;
      margin: 0;
      font-weight: normal;
    }
    .footer__logo {
      width: 137px;
    }
    .footer__link-logo {
      display: block;
      margin-bottom: 16px;
    }
  }
  .region-content {
    margin-bottom: 80px;
  }
  .region-content-secondary {
    background: #efefef;
    display: table;
    width: 100%;
  }
  .region-content-tertiary {
    background: white;
    display: table;
    width: 100%;
  }
  .main-container {
    max-width: 1146px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
  }
  .block-page-title-block {
    max-width: 705px;
  }
  .path-nyhetsarkiv {
    #block-dfo-content {
      max-width: 705px;
    }
  }
  .node.has-sidebar {
    .node__content-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .node__content {
      + {
        .sidebar {
          width: 291px;
          #stickyMenu {
            max-width: 339px;
          }
        }
      }
    }
    .sidebar {
      width: 339px;
      #toc {
        width: 291px;
      }
    }
  }
  .node {
    .node__content-wrapper {
      + {
        .block-dfo-entity-usage-list {
          margin-top: 40px;
        }
      }
    }
  }
  .node.sidebar-right {
    .sidebar {
      order: 2;
    }
  }
  .node.node--type-process.has-sidebar {
    .sidebar {
      #toc {
        float: right;
      }
    }
  }
  .sidebar {
    position: relative;
    .sticky-menu {
      width: 286px;
    }
    .sticky-menu.sticky {
      position: fixed;
      top: 0;
      padding-top: 40px !important;
    }
    .sticky-menu.sticky-bottom {
      position: absolute;
      top: auto;
      bottom: 0;
    }
    .sticky-menu.with-toolbar {
      padding-top: 58px;
    }
    #toc {
      position: relative;
      .toc__header {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #0e2a49;
        padding-left: 50px;
        margin: 10px 0 20px 0;
      }
      .toc-arrow {
        background-image: url(../../images/drupal/icons/toc-arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        left: 20px;
        margin-top: 3px;
      }
      ol.toc__list {
        padding: 0 0 0px 10px;
        list-style: none;
      }
      li.toc__list-item {
        padding-left: 10px;
        margin: 10px 0;
        a {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          text-decoration: none;
          color: #0e2a49;
          opacity: 0.75;
          &:hover {
            color: #009fe3;
          }
        }
      }
      li.toc__list-item.is-active-li {
        a {
          text-shadow: 0 0 #000;
          opacity: 1;
        }
      }
      > ol.toc__list {
        margin: 10px 0;
        > li.toc__list-item {
          border-bottom: 1px dashed #ccc;
          padding-left: 40px;
          padding-bottom: 10px;
        }
        > ol.toc__list {
          padding-left: 40px;
        }
        ol.toc__list {
          display: none;
        }
        ol.toc__list.is-active-ol {
          display: block;
        }
      }
    }
  }
  .page-node-type-process-page {
    h1.book-title {
      font-family: 'Source Serif Pro', serif;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: 62px;
      margin: 80px 0 24px 0;
    }
  }
  .page-node-type-process {
    #block-dfo-page-title {
      max-width: none;
    }
  }
  .node--type-process {
    .field--name-field-intro-text {
      margin-bottom: 36px;
    }
    .date {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #0e2a49;
    }
    .node__content {
      margin-top: 36px;
      > h2 {
        font-family: 'Source Serif Pro', serif;
        font-size: 30px;
        line-height: 37px;
        font-weight: 600;
        margin: 18px 0 7px 0;
      }
    }
    .layout-sidebar {
      width: 339px;
    }
    .sidebar {
      max-width: 339px;
    }
    .sidebar-burger {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      cursor: pointer;
      .sidebar-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
        display: none;
        text-align: center;
      }
      .sidebar-text.open {
        display: block;
      }
      .burger-button {
        background-image: url(../../images/drupal/icons/menu-burger-black.svg);
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        width: 20px;
        height: 20px;
        margin-left: 20px;
      }
      .burger-button.close {
        background-image: url(../../images/drupal/icons/menu-burger-close.svg);
      }
    }
  }
  .node--type-process-page {
    .field--name-field-intro-text {
      margin-bottom: 36px;
    }
    .date {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #0e2a49;
    }
    .node__content {
      margin-top: 36px;
      > h2 {
        font-family: 'Source Serif Pro', serif;
        font-size: 30px;
        line-height: 37px;
        font-weight: 600;
        margin: 18px 0 7px 0;
      }
    }
    .layout-sidebar {
      width: 339px;
    }
    .sidebar {
      max-width: 339px;
    }
    .sidebar-burger {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      cursor: pointer;
      .sidebar-text {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
        display: none;
        text-align: center;
      }
      .sidebar-text.open {
        display: block;
      }
      .burger-button {
        background-image: url(../../images/drupal/icons/menu-burger-black.svg);
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        width: 20px;
        height: 20px;
        margin-left: 20px;
      }
      .burger-button.close {
        background-image: url(../../images/drupal/icons/menu-burger-close.svg);
      }
    }
  }
  .layout-top-content {
    .process-top-navigation {
      ul {
        li {
          ul {
            display: none;
          }
        }
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
    }
    li {
      display: block;
      flex: 1;
      &:first-child {
        a {
          padding-left: 42px;
          border-left: 3px solid #e6e6e6;
        }
      }
      &:last-child {
        a {
          &:after {
            background-color: white;
          }
        }
      }
    }
    li.menu-item--active-trail {
      a {
        background: #0e2a49;
        color: white;
        border-color: #9a9a9a;
        &::before {
          background-color: #0e2a49;
        }
      }
    }
    a {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 22px;
      line-height: 27px;
      font-weight: 600;
      color: #0e2a49;
      padding: 0 2px 0 80px;
      text-decoration: none;
      background: white;
      height: 150px;
      border-width: 3px 0 3px 3px;
      border-style: solid;
      border-color: #e6e6e6;
      border-right: none;
      border-left: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &::after {
        background-image: url(../../images/drupal/icons/process-arrow-head.svg);
        position: absolute;
        top: -2px;
        right: -55px;
        display: block;
        content: '';
        width: 57px;
        height: 154px;
        transform: none;
        background-color: transparent !important;
        border: none;
        z-index: 1;
      }
    }
  }
  nav.process-sub-navigation {
    counter-reset: menu-first-level;
    counter-reset: menu-second-level;
    counter-reset: menu-third-level;
    counter-reset: menu-fourth-level;
    li.menu-item {
      list-style: none;
      position: relative;
      a {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        color: #0e2a49;
        text-decoration: none;
        display: inline-flex;
        padding-left: 47px;
        padding-right: 29px;
        position: relative;
        font-weight: 600;
        &:before {
          position: absolute;
          border-radius: 50%;
          text-align: center;
          left: 0;
        }
        &:after {
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          width: 14px;
          height: 8px;
          display: block;
          position: absolute;
          right: 9px;
          top: calc(50% - 4px);
        }
      }
      a.hover {
        color: #009fe3;
        &:before {
          border-color: #009fe3 !important;
        }
      }
    }
    li.menu-item.menu-item--collapsed {
      > a {
        &:after {
          background-image: url(../../images/drupal/icons/processes-arrow.svg);
          transform: rotate(270deg);
        }
      }
      > .square-wrapper {
        > a {
          &:after {
            background-image: url(../../images/drupal/icons/processes-arrow.svg);
            transform: rotate(270deg);
          }
        }
        > a.hover {
          &:after {
            background-image: url(../../images/drupal/icons/processes-arrow-light.svg);
          }
        }
      }
      > a.hover {
        &:after {
          background-image: url(../../images/drupal/icons/processes-arrow-light.svg);
        }
      }
    }
    li.menu-item.menu-item--expanded {
      > a {
        &:after {
          background-image: url(../../images/drupal/icons/processes-arrow.svg);
          transform: rotate(0deg);
        }
      }
      > .square-wrapper {
        > a {
          &:after {
            background-image: url(../../images/drupal/icons/processes-arrow.svg);
            transform: rotate(0deg);
          }
        }
        > a.hover {
          &:after {
            background-image: url(../../images/drupal/icons/processes-arrow-light.svg);
          }
        }
      }
      > a.hover {
        &:after {
          background-image: url(../../images/drupal/icons/processes-arrow-light.svg);
        }
      }
    }
    li.menu-item.menu-item--active-trail {
      > a {
        font-weight: 700 !important;
      }
      > .square-wrapper {
        > a {
          font-weight: 700 !important;
        }
      }
    }
    li.menu-item.menu-item--deepest-active-trail {
      > a {
        background-color: rgba(0, 159, 227, 0.08);
      }
    }
    ul.menu-level-0 {
      padding-left: 0;
      > li.menu-item {
        margin-bottom: 17px;
        > a {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          line-height: 23px;
          font-weight: 600;
          padding-top: 9px;
          padding-bottom: 9px;
          &:before {
            content: counter(menu-first-level);
            border: 3px solid #0e2a49;
            font-weight: 700;
            width: 34px;
            height: 34px;
            line-height: 34px;
            top: calc(50% - 19px);
          }
        }
      }
      > li.menu-item.menu-item--active-trail {
        > a {
          &:before {
            background-color: #0e2a49;
            color: white;
          }
        }
      }
      > li.menu-item.menu-item--active-trail.hover {
        > a {
          &:before {
            background-color: #009fe3;
            color: white !important;
          }
        }
      }
      > li.menu-item.circle {
        > a {
          &:before {
            display: none !important;
          }
        }
      }
      > li.menu-item.number {
        counter-increment: menu-first-level;
      }
      > li.menu-item.square {
        counter-increment: menu-first-level;
        > a {
          &:before {
            background-image: url(../../images/drupal/icons/square-border.svg);
            background-position: center center;
            background-size: contain;
            border: none !important;
            border-radius: 0 !important;
            width: 40px !important;
            height: 40px !important;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      > li.menu-item.square.menu-item--active-trail {
        > a {
          &:before {
            background-color: transparent;
            background-image: url(../../images/drupal/icons/square.svg);
          }
        }
      }
      > li.menu-item.square.menu-item--active-trail.hover {
        > a {
          &:before {
            background-image: url(../../images/drupal/icons/square-light.svg);
          }
        }
      }
      > li.menu-item.square.hover {
        > a {
          &:before {
            background-image: url(../../images/drupal/icons/square-border-light.svg);
          }
        }
      }
    }
    ul.menu-level-1 {
      padding-left: 0;
      margin-top: 21px;
      > li.menu-item {
        margin-bottom: 7px;
        > a {
          padding-left: 38px;
          padding-top: 6px;
          padding-bottom: 6px;
          &:before {
            content: counter(menu-second-level);
            border: 1px solid #0e2a49;
            font-weight: 600;
            font-size: 12px;
            width: 18px;
            height: 18px;
            line-height: 18px;
            top: calc(50% - 9px);
            left: 9px;
          }
          &:after {
            top: calc(50% - 4px);
          }
        }
      }
      > li.menu-item.menu-item--active-trail {
        > a {
          &:before {
            background-color: #0e2a49;
            color: white;
          }
        }
      }
      > li.menu-item.menu-item--active-trail.hover {
        > a {
          &:before {
            background-color: #009fe3;
            color: white !important;
          }
        }
      }
      > li.menu-item.square {
        > .square-wrapper {
          display: flex;
          align-items: center;
          > a {
            padding-left: 20px !important;
            width: calc(100% - 56px);
            &:before {
              display: none !important;
            }
          }
          > span.square {
            width: 18px;
            height: 18px;
            display: block;
            border: 1px solid #0e2a49;
            transform: rotate(45deg);
            position: relative;
            span.square-number {
              transform: rotate(-45deg);
              width: 18px;
              height: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              &:before {
                content: counter(menu-first-level);
                color: #0e2a49;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
              }
            }
          }
        }
        > .square-wrapper.hover {
          > span.square {
            border-color: #009fe3 !important;
            span.square-number {
              &:before {
                color: #009fe3 !important;
              }
            }
          }
          > a {
            color: #009fe3 !important;
            &:before {
              color: #009fe3 !important;
              border-color: #009fe3 !important;
            }
          }
        }
      }
      > li.menu-item.square.menu-item--active-trail {
        > .square-wrapper.hover {
          > span.square {
            background-color: #009fe3 !important;
            border-color: #009fe3 !important;
            > span.square-number {
              &:before {
                color: white !important;
              }
            }
          }
        }
        > .square-wrapper {
          > a {
            &:before {
              background-color: transparent;
            }
          }
          > span.square {
            background-color: #0e2a49;
            > span.square-number {
              &:before {
                color: white;
              }
            }
          }
        }
      }
      li.menu-item.square {
        > a {
          align-items: center;
          &:before {
            background-image: url(../../images/drupal/icons/square-small-border.svg);
            background-position: center center;
            background-size: contain;
            border: none !important;
            border-radius: 0 !important;
            width: 25px !important;
            height: 25px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            top: auto !important;
            left: 6px;
          }
        }
        counter-increment: menu-second-level;
      }
      li.menu-item.square.menu-item--active-trail {
        > a {
          &:before {
            background-color: transparent;
            background-image: url(../../images/drupal/icons/square-small.svg);
          }
        }
      }
      li.menu-item.square.menu-item--active-trail.hover {
        > a {
          &:before {
            background-image: url(../../images/drupal/icons/square-small-light.svg);
          }
        }
      }
      li.menu-item.square.hover {
        > a {
          &:before {
            background-image: url(../../images/drupal/icons/square-small-border-light.svg);
          }
        }
      }
      > li.menu-item.number {
        counter-increment: menu-second-level;
      }
      > li.menu-item.circle {
        > a {
          &:before {
            display: none !important;
          }
        }
      }
    }
    ul.menu-level-2 {
      margin-top: 8px;
      li.menu-item {
        a {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          line-height: 23px;
          font-weight: 400;
          padding-left: 38px;
          font-weight: 400;
          padding-bottom: 11px;
          padding-top: 11px;
          &:before {
            content: counter(menu-third-level);
          }
        }
      }
      ul.menu-level-3 {
        li.menu-item {
          a {
            padding-left: 38px;
            &:before {
              content: counter(menu-fourth-level);
            }
          }
        }
      }
      > li.menu-item.number {
        counter-increment: menu-third-level;
      }
      > li.menu-item.circle {
        > a {
          &:before {
            display: none !important;
          }
        }
      }
      li.menu-item.square {
        counter-increment: menu-third-level;
      }
    }
    ul {
      &:not(.menu-level-0) {
        > li.menu-item {
          > a {
            &:before {
              border: 1px solid #0e2a49;
              font-weight: 600;
              font-size: 12px;
              width: 18px;
              height: 18px;
              line-height: 18px;
              top: calc(50% - 9px);
            }
          }
        }
        > li.menu-item.menu-item--active-trail {
          > a {
            &:before {
              background-color: #0e2a49;
              color: white;
            }
          }
        }
        > li.menu-item.menu-item--active-trail.hover {
          > a {
            &:before {
              background-color: #009fe3;
              color: white !important;
            }
          }
        }
        &:not(.menu-level-1) {
          a {
            &:before {
              left: 7px;
            }
          }
        }
      }
    }
    ul.menu-level-3 {
      > li.menu-item.number {
        counter-increment: menu-fourth-level;
      }
      > li.menu-item.circle {
        > a {
          &:before {
            display: none !important;
          }
        }
      }
      li.menu-item.square {
        counter-increment: menu-fourth-level;
      }
    }
    li.menu-item.no-list {
      > a {
        &:before {
          display: none !important;
        }
      }
    }
  }
  .book-navigation {
    ul.book-navigation-list {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      margin-top: 60px;
      margin-bottom: 40px;
    }
    li {
      width: 100%;
      list-style: none;
    }
    li.book-navigation-next {
      a {
        background-image: url(../../images/drupal/icons/arrow-button-blue.svg);
        padding: 24px 66px 24px 0;
        width: calc(100% - 66px);
        background-position: 100% center;
        &:hover {
          background-image: url(../../images/drupal/icons/arrow-button-light-blue.svg);
        }
      }
      span {
        text-align: right;
      }
      b {
        text-align: right;
      }
    }
    li.book-navigation-prev {
      a {
        background-image: url(../../images/drupal/icons/arrow-button-blue-left.svg);
        padding: 24px 0 24px 66px;
        background-position: 0 center;
        &:hover {
          background-image: url(../../images/drupal/icons/arrow-button-light-blue-left.svg);
        }
      }
    }
    a {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      border-top: 1px solid #005b91;
      border-bottom: 1px solid #005b91;
      color: #0e2a49;
      text-decoration: none;
      background-repeat: no-repeat;
      background-size: 34px 24px;
      &:hover {
        border-color: #009fe3;
        span {
          color: #009fe3;
        }
        b {
          color: #009fe3;
        }
      }
    }
    a[rel='next'] {
      justify-content: flex-end;
    }
    b {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 20px;
      line-height: 27px;
      font-weight: 600;
      width: 100%;
      color: #0e2a49;
      margin-bottom: 4px;
    }
    span {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 20px;
      line-height: 27px;
      font-weight: 400;
    }
  }
  .node--type-landing-page {
    .field--name-body {
      margin-top: 60px;
    }
    .node__illustration-intro-image {
      display: flex;
      .field--name-field-image {
        margin-left: 40px;
        margin-top: 47px;
        min-width: 50%;
        img {
          width: 100%;
          height: auto;
        }
      }
      #block-dfo-page-title {
        h1 {
          margin-top: 0 !important;
        }
      }
    }
    .node__illustration-intro {
      margin-top: 47px;
    }
  }
  .page-node-type-landing-page.path-frontpage {
    #block-dfo-page-title {
      display: none;
    }
    .date.bottom {
      display: none;
    }
  }
  .search-page {
    .region-content {
      .main-container {
        display: grid;
        grid-template-columns: 35% 65%;
        grid-template-rows: auto auto;
        gap: 0 0;
        grid-template-areas: 'title title' 'search search' 'number number' 'facets results';
        #block-dfo-page-title {
          grid-area: title;
        }
        #block-search-box {
          grid-area: search;
          width: 566px;
        }
        .views-header {
          grid-area: number;
          margin-bottom: 35px;
          margin-top: 10px;
          color: #9a9a9a;
        }
        .facets-wrapper {
          grid-area: facets;
          .block-facets {
            margin-top: 0;
          }
        }
        #block-dfo-content {
          grid-area: results;
        }
        .block-views-exposed-filter-blocksite-search-search-results {
          position: relative;
          .form-item-search-api-fulltext {
            input {
              font-family: 'Source Sans Pro', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 27px;
              color: #0e2a49;
              border: 2px solid #005b91;
              padding: 18px 45px 18px 20px;
              width: 100%;
              box-sizing: border-box;
            }
          }
          .form-actions {
            position: absolute;
            right: 20px;
            top: 26px;
            input {
              width: 23px;
            }
          }
        }
      }
    }
    .search-content-type {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 10px;
      color: #9a9a9a;
    }
    .views-content {
      .views-row {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #e6e6e6;
        &:first-child {
          h2 {
            margin-top: 0;
          }
        }
        article {
          h2 {
            color: #0e2a49;
            text-decoration: none;
            display: block;
            font-family: 'Source Serif Pro', serif;
            font-size: 22px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 8px;
            a {
              color: #0e2a49;
              text-decoration: none;
              &:hover {
                color: #009fe3;
              }
            }
          }
          .field--name-field-intro-text {
            margin-bottom: 0;
            p {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 18px;
              font-weight: 400;
              line-height: 24px;
              color: #333;
            }
            strong {
              color: #009fe3;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
  .path-avtaler {
    .region-content {
      .main-container {
        display: grid;
        grid-template-columns: 35% 65%;
        grid-template-rows: auto auto;
        gap: 0 0;
        grid-template-areas: 'title title' 'search search' 'number number' 'facets results';
        #block-dfo-page-title {
          grid-area: title;
        }
        #block-search-box {
          grid-area: search;
          width: 566px;
        }
        .views-header {
          grid-area: number;
          margin-bottom: 35px;
          margin-top: 10px;
          color: #9a9a9a;
        }
        .facets-wrapper {
          grid-area: facets;
          .block-facets {
            margin-top: 0;
          }
        }
        #block-dfo-content {
          grid-area: results;
        }
        .block-views-exposed-filter-blocksite-search-search-results {
          position: relative;
          .form-item-search-api-fulltext {
            input {
              font-family: 'Source Sans Pro', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 27px;
              color: #0e2a49;
              border: 2px solid #005b91;
              padding: 18px 45px 18px 20px;
              width: 100%;
              box-sizing: border-box;
            }
          }
          .form-actions {
            position: absolute;
            right: 20px;
            top: 26px;
            input {
              width: 23px;
            }
          }
        }
      }
    }
    .search-content-type {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 10px;
      color: #9a9a9a;
    }
  }
  .block-webform-block {
    align-items: center;
  }
  .webform-submission-feedback-form {
    padding: 20px 0;
    #edit-flexbox {
      margin: 0;
    }
    #edit-flexbox-buttons {
      display: flex;
      justify-content: flex-end;
      margin: 0;
    }
    .webform-flex--container {
      margin: 0;
      h2 {
        margin: 0 40px 0 0;
        line-height: 24px;
      }
    }
    .webform-flex--1 {
      flex: inherit;
    }
    #edit-submit-yes-submit {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      cursor: pointer;
      color: white !important;
      text-decoration: none !important;
      padding: 10px 24px;
      border: none;
      outline: none;
      box-shadow: none;
      background: #005b91;
      -webkit-transition: background-color 0.25s, border-color 0.25s;
      transition: background-color 0.25s, border-color 0.25s;
      line-height: inherit;
      -webkit-appearance: none;
      border-radius: 0;
      margin: 0 15px 0 0;
      &:hover {
        background-color: #009fe3;
      }
      &:focus {
        outline: 1px solid #0e2a49;
      }
    }
    .form-item-answer-no {
      margin: 0;
      label {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        cursor: pointer;
        color: white !important;
        text-decoration: none !important;
        padding: 10px 24px;
        border: none;
        outline: none;
        box-shadow: none;
        background: #005b91;
        -webkit-transition: background-color 0.25s, border-color 0.25s;
        transition: background-color 0.25s, border-color 0.25s;
        line-height: inherit;
        &:hover {
          background-color: #009fe3;
        }
        &:focus {
          outline: 1px solid #0e2a49;
        }
      }
      input {
        display: none;
      }
    }
    .form-item-confirmation-no {
      clear: both;
      h3 {
        margin: 0 0 8px 0;
      }
    }
    #edit-actions-submit {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      cursor: pointer;
      color: white !important;
      text-decoration: none !important;
      padding: 10px 24px;
      border: none;
      outline: none;
      box-shadow: none;
      background: #005b91;
      -webkit-transition: background-color 0.25s, border-color 0.25s;
      transition: background-color 0.25s, border-color 0.25s;
      line-height: inherit;
      margin-bottom: 40px;
      &:hover {
        background-color: #009fe3;
      }
    }
  }
  .webform-submission-add-form {
    .form-item-behandlet {
      display: none !important;
    }
  }
  .js-pager__items {
    display: flex;
    justify-content: center;
    li {
      list-style: none;
      margin: 0 10px;
    }
    a {
      color: #0e2a49;
      font-weight: bold;
      &:hover {
        color: #009fe3;
      }
    }
  }
  .node--type-tool.node--view-mode-large {
    .field--name-field-intro-text {
      margin: 8px 0 0 0;
      p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #0e2a49;
      }
    }
    padding: 0;
    &:hover {
      border-color: #0e2a49;
    }
    a[rel='bookmark'] {
      padding: 24px;
      text-decoration: none;
      display: block;
      &:hover {
        color: #0e2a49 !important;
      }
    }
    .field--name-field-source {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      display: flex;
      color: #0e2a49;
      .field-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #0e2a49;
        margin-right: 3px;
      }
    }
    > a {
      > .field--name-field-source {
        margin-top: 8px;
      }
    }
    strong {
      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 22.63px;
        &:hover {
          color: #0e2a49 !important;
        }
      }
    }
  }
  .node--type-tool.node--view-mode-medium {
    padding: 0;
    &:hover {
      border-color: #0e2a49;
    }
    a[rel='bookmark'] {
      padding: 24px;
      text-decoration: none;
      display: block;
      &:hover {
        color: #0e2a49 !important;
      }
    }
    .field--name-field-source {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      display: flex;
      color: #0e2a49;
      .field-label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #0e2a49;
        margin-right: 3px;
      }
    }
    > a {
      > .field--name-field-source {
        margin-top: 8px;
      }
    }
    strong {
      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 22.63px;
        &:hover {
          color: #0e2a49 !important;
        }
      }
    }
  }
  .block-dfo-entity-usage-list {
    .list__title {
      margin-top: 0;
    }
  }
  #elma-registry-block {
    #search-holder {
      display: flex;
      align-items: flex-end;
      margin-bottom: 58px;
      input {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        border: 2px solid #005b91;
        padding: 14px 24px 15px 24px;
        color: #0e2a49;
        background-image: url(../../images/drupal/icons/search.svg);
        padding-right: 46px !important;
        background-repeat: no-repeat;
        background-size: 20px 21px;
        background-position: calc(100% - 20px) 16px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: #0e2a49;
        }
      }
      select {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        border: 2px solid #005b91;
        padding: 14px 24px 15px 24px;
        color: #0e2a49;
        background-image: url(../../images/drupal/icons/accordion-arrow-down-blue.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 15px) 15px;
        appearance: none;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: #0e2a49;
        }
      }
    }
    .search-box-wrapper {
      width: 343px;
      input {
        width: calc(100% - 70px);
        display: block;
      }
    }
    .search-arguments-wrapper {
      display: inline-flex;
      flex-direction: column;
      width: 344px;
      margin-left: 25px;
    }
    table.search-result {
      margin-top: 19px !important;
      width: 100%;
      color: #0e2a49;
      thead {
        display: none;
      }
      tbody {
        tr {
          display: flex;
          flex-direction: column;
          padding: 8px 0 10px 0;
          border-bottom: 1px solid #c4c4c4;
          &:first-child {
            border-top: 1px solid #c4c4c4;
          }
          td {
            padding: 0;
            &:first-child {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 16px;
              line-height: 24px;
              font-weight: 400;
              color: #333333;
            }
            &:last-child {
              font-family: 'Source Serif Pro', serif;
              font-size: 22px;
              line-height: 27.57px;
              font-weight: 600;
              color: #0e2a49;
              margin-top: 8px;
            }
          }
        }
      }
    }
    .results-count {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      display: inline-block;
    }
    .results-count-strong {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      display: inline-block;
    }
    .search-result-nav {
      margin: 0 auto;
      padding-top: 24px;
      a {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-decoration: none;
        display: inline-block;
        width: 45px;
        height: 30px;
        margin: 0 5px;
      }
      a.current {
        color: white;
        position: relative;
        z-index: 1;
        &:before {
          content: '';
          width: 45px;
          height: 45px;
          background-color: #005b91;
          border-radius: 50%;
          display: block;
          position: absolute;
          z-index: -1;
          top: -8px;
          left: 0;
        }
      }
      a.next {
        background-image: url(../../images/drupal/icons/accordion-arrow-down-blue.svg);
        background-repeat: no-repeat;
        background-position: 0;
        background-size: 30px;
        transform: rotate(270deg);
        color: transparent;
        width: 27px;
        height: 16px;
        &:hover {
          &:not(.empty) {
            background-image: url(../../images/drupal/icons/accordion-arrow-down-light-blue.svg);
          }
        }
      }
      a.next.empty {
        background-position: 5px;
        background-image: url(../../images/drupal/icons/accordion-arrow-down-grey.svg);
      }
      a.previous {
        background-image: url(../../images/drupal/icons/accordion-arrow-down-blue.svg);
        background-repeat: no-repeat;
        background-position: 9px;
        background-size: 30px;
        transform: rotate(90deg);
        color: transparent;
        width: 27px;
        height: 16px;
        &:hover {
          &:not(.empty) {
            background-image: url(../../images/drupal/icons/accordion-arrow-down-light-blue.svg);
          }
        }
      }
      a.previous.empty {
        background-position: 5px;
        background-image: url(../../images/drupal/icons/accordion-arrow-down-grey.svg);
      }
    }
  }
  form {
    &:not(.content-moderation-entity-moderation-form) {
      &:not(.webform-submission-feedback-form) {
        &:not(#views-exposed-form-site-search-search-results) {
          margin-bottom: 40px;
          max-width: 705px;
          textarea {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            width: 100% !important;
            padding: 18px;
            height: 250px;
            border: 2px solid #005b91;
            box-sizing: border-box;
            &:focus {
              outline: 1px solid #0e2a49;
            }
          }
          input {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            border: 2px solid #005b91;
            padding: 14px 24px 15px 18px;
            color: #0e2a49;
            width: 100%;
            box-sizing: border-box;
            &:focus {
              outline: none;
            }
            &::placeholder {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 18px;
              line-height: 24px;
              font-weight: 400;
              color: #0e2a49;
            }
          }
          select {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            border: 2px solid #005b91;
            padding: 14px 24px 15px 18px;
            color: #0e2a49;
            width: 100%;
            box-sizing: border-box;
            background-image: url(../../images/drupal/icons/accordion-arrow-down-blue.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 15px) 15px;
            appearance: none;
            padding-right: 55px;
            width: 100%;
            &:focus {
              outline: none;
            }
            &::placeholder {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 18px;
              line-height: 24px;
              font-weight: 400;
              color: #0e2a49;
            }
          }
          input[type='submit'] {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            cursor: pointer;
            color: white !important;
            text-decoration: none !important;
            padding: 10px 24px;
            border: none;
            outline: none;
            box-shadow: none;
            background: #005b91;
            -webkit-transition: background-color 0.25s, border-color 0.25s;
            transition: background-color 0.25s, border-color 0.25s;
            line-height: inherit;
            width: auto;
            margin-top: 40px;
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            cursor: pointer;
            color: white !important;
            text-decoration: none !important;
            padding: 10px 24px;
            border: none;
            outline: none;
            box-shadow: none;
            background: #005b91;
            -webkit-transition: background-color 0.25s, border-color 0.25s;
            transition: background-color 0.25s, border-color 0.25s;
            line-height: inherit;
            &:hover {
              background-color: #009fe3;
              background-color: #009fe3;
            }
            + {
              .js-form-item {
                margin-top: 40px;
              }
            }
          }
          label {
            &:not(.option) {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 18px;
              line-height: 24px;
              font-weight: 600;
              margin-bottom: 8px;
              color: #0e2a49;
            }
            &:not(.option).form-required {
              &:after {
                color: #f00000;
                content: '*';
                padding-left: 3px;
              }
            }
          }
          legend {
            span {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 18px;
              line-height: 24px;
              font-weight: 600;
              margin-bottom: 8px;
              color: #0e2a49;
            }
            span.form-required {
              &:after {
                color: #f00000;
                content: '*';
                padding-left: 3px;
              }
            }
            padding: 0;
          }
          fieldset {
            margin: 0;
            border: none;
            padding: 0;
            span.fieldset-legend {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 18px;
              line-height: 24px;
              font-weight: 600;
              display: block;
            }
            label {
              color: #333333;
              margin-bottom: 0;
            }
            .js-form-item {
              color: #333333;
              margin-top: 16px;
              &:first-child {
                margin-top: 8px;
              }
            }
          }
          #ajax-wrapper {
            > .js-form-item {
              margin-top: 24px;
              label {
                display: block;
              }
            }
          }
          > .js-form-item {
            label {
              display: block;
            }
            + {
              .js-form-item {
                margin-top: 24px;
              }
            }
          }
          a {
            color: #0e2a49;
            &:hover {
              color: #009fe3;
            }
          }
          .js-form-type-checkbox {
            margin-right: 20px;
            margin-top: 8px !important;
            display: flex;
            &:first-child {
              margin-top: 0 !important;
            }
            label {
              color: #0e2a49;
            }
          }
          .js-form-type-checkbox.form-item-call {
            margin-bottom: 5px;
            input {
              margin-top: 1px;
            }
          }
          .js-form-type-checkbox.form-item-priority {
            input {
              margin-top: 1px;
            }
          }
          .form-file-wrapper {
            border: 1px dashed #828282;
            display: flex;
            align-items: center;
            height: 240px;
            input {
              width: auto;
              margin: 0 auto;
              border: none;
            }
          }
          .description {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 18px;
            line-height: 27px;
            font-weight: 400;
            font-style: italic;
            margin-top: 9px;
            a {
              color: #0e2a49;
              &:hover {
                color: #009fe3;
              }
            }
          }
          input.facets-checkbox {
            box-sizing: border-box;
            flex-shrink: 0;
            border: 2px solid #005b91;
            box-shadow: none;
            -webkit-appearance: none;
            outline: none;
            background-color: #fff;
            padding: 7px;
            height: 1em;
            width: 1em;
            margin: 5px 10px 0 0;
            &:checked {
              background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.408 19.422c.123.052.254.078.387.078h.051l.018-.001a1.002 1.002 0 0 0 .738-.406l.001-.002 13.093-17.57L20.094.295 7.689 16.941 1.716 10.89.298 12.327l6.783 6.874c.093.095.204.17.327.221z' fill='%23fff'/%3E%3C/svg%3E")
                no-repeat 50%;
              background-size: calc(17px - 8px);
              background-color: #005b91;
            }
            &:hover {
              border-width: 4px;
              padding: 5px;
            }
          }
          input.form-checkbox {
            box-sizing: border-box;
            flex-shrink: 0;
            border: 2px solid #005b91;
            box-shadow: none;
            -webkit-appearance: none;
            outline: none;
            background-color: #fff;
            padding: 7px;
            height: 1em;
            width: 1em;
            margin: 5px 10px 0 0;
            &:checked {
              background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.408 19.422c.123.052.254.078.387.078h.051l.018-.001a1.002 1.002 0 0 0 .738-.406l.001-.002 13.093-17.57L20.094.295 7.689 16.941 1.716 10.89.298 12.327l6.783 6.874c.093.095.204.17.327.221z' fill='%23fff'/%3E%3C/svg%3E")
                no-repeat 50%;
              background-size: calc(17px - 8px);
              background-color: #005b91;
            }
            &:hover {
              border-width: 4px;
              padding: 5px;
            }
          }
          input[type='submit'].cancel {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            cursor: pointer;
            color: white !important;
            text-decoration: none !important;
            padding: 10px 24px;
            border: none;
            outline: none;
            box-shadow: none;
            background: #e83f53;
            -webkit-transition: background-color 0.25s, border-color 0.25s;
            transition: background-color 0.25s, border-color 0.25s;
            line-height: inherit;
            &:hover {
              background-color: #ec5f70;
            }
          }
          input[type='submit'].ok {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            cursor: pointer;
            color: white !important;
            text-decoration: none !important;
            padding: 10px 24px;
            border: none;
            outline: none;
            box-shadow: none;
            background: #00a982;
            -webkit-transition: background-color 0.25s, border-color 0.25s;
            transition: background-color 0.25s, border-color 0.25s;
            line-height: inherit;
            &:hover {
              background-color: #00cd9d;
            }
          }
        }
      }
    }
  }
  form.my-page-apply-contact-person-form {
    > p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  #block-dfo-content {
    .consents-form {
      border-bottom: 1px solid #d1d1d1;
      padding-bottom: 40px;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
  .path-erfaringsrommet {
    .views-row {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #d1d1d1;
      &:first-child {
        border-top: 1px solid #d1d1d1;
        padding-top: 16px;
      }
      .views-field-role {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        display: inline-block;
        margin-left: 8px;
      }
    }
    .views-field-org-name {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #828282;
      margin-bottom: 8px;
    }
    .views-field-name {
      display: inline-block;
      font-family: 'Source Serif Pro', serif;
      font-size: 24px;
      line-height: 24px;
      font-weight: 600;
      a {
        color: #005b91;
        text-decoration: none;
        &:hover {
          color: #009fe3;
        }
      }
    }
    .views-field-topic-target-id {
      margin-top: 8px;
      ul {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin: 0;
      }
      li {
        list-style: none;
        &:not(:last-child) {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          line-height: 27px;
          font-weight: 400;
          margin-bottom: 8px;
          margin-right: 8px;
          &:after {
            content: '';
            border-right: 1px solid #d1d1d1;
            padding-left: 8px;
          }
        }
      }
    }
  }
  #views-exposed-form-arbeidsgiver-contact-persons-page-1 {
    position: relative;
  }
  .contact-profile {
    .profile-layout {
      display: flex;
      justify-content: space-between;
    }
    .profile-left {
      width: calc(45% - 25px);
      display: flex;
    }
    .profile-right {
      width: calc(55% - 25px);
      .profile-element {
        margin-bottom: 40px;
      }
    }
    .profile-element {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      line-height: 27px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 4px;
      a {
        color: #005b91;
        &:hover {
          color: #009fe3;
        }
      }
      label {
        display: block;
        font-weight: bold;
        margin-bottom: 8px;
      }
    }
    .profile-element.name {
      text-transform: capitalize;
      h2 {
        margin: 0 0 5px 0;
      }
    }
    .areas-wrapper {
      display: flex;
      flex-wrap: wrap;
      .area {
        &:not(:last-child) {
          margin-bottom: 8px;
          margin-right: 8px;
          &:after {
            content: '';
            border-right: 1px solid #d1d1d1;
            padding-left: 8px;
          }
        }
      }
    }
    .contact-person-information {
      margin-top: 40px;
      padding-top: 40px;
      border-top: 1px solid #d1d1d1;
    }
  }
  .contact-person-message {
    padding: 32px;
    border: 1px solid #039fe3;
    margin-bottom: 60px;
    p {
      margin: 0;
    }
  }
  .messages {
    padding: 15px 20px 15px 35px;
    word-wrap: break-word;
    border: 1px solid;
    border-width: 1px 1px 1px 0;
    border-radius: 2px;
    background: no-repeat 10px 17px;
    overflow-wrap: break-word;
    margin-top: 20px;
    + {
      .messages {
        margin-top: 1.538em;
      }
    }
  }
  [dir='rtl'] {
    .messages {
      padding-right: 35px;
      padding-left: 20px;
      text-align: right;
      border-width: 1px 0 1px 1px;
      background-position: right 10px top 17px;
    }
    .messages--status {
      margin-left: 0;
      border-color: #c9e1bd transparent #c9e1bd #c9e1bd;
      box-shadow: 8px 0 0 #77b259;
    }
    .messages--warning {
      border-color: #f4daa6 transparent #f4daa6 #f4daa6;
      box-shadow: 8px 0 0 #e09600;
    }
    .messages--error {
      border-color: #f9c9bf transparent #f9c9bf #f9c9bf;
      box-shadow: 8px 0 0 #e62600;
    }
  }
  .messages__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .messages__item {
    + {
      .messages__item {
        margin-top: 0.769em;
      }
    }
  }
  // .messages--status {
  //   color: #325e1c;
  //   border-color: #c9e1bd #c9e1bd #c9e1bd transparent;
  //   background-color: #f3faef;
  //   background-image: url(/themes/dfo-platform/misc/icons/73b355/check.svg);
  //   box-shadow: -8px 0 0 #77b259;
  // }
  // .messages--warning {
  //   color: #734c00;
  //   border-color: #f4daa6 #f4daa6 #f4daa6 transparent;
  //   background-color: #fdf8ed;
  //   background-image: url(/themes/dfo-platform/misc/icons/e29700/warning.svg);
  //   box-shadow: -8px 0 0 #e09600;
  // }
  // .messages--error {
  //   color: #a51b00;
  //   border-color: #f9c9bf #f9c9bf #f9c9bf transparent;
  //   background-color: #fcf4f2;
  //   background-image: url(/themes/dfo-platform/misc/icons/e32700/error.svg);
  //   box-shadow: -8px 0 0 #e62600;
  //   p.error {
  //     color: #a51b00;
  //   }
  // }
  @media all and (max-width: 639px) {
    .h2 {
      font-size: 20px;
      font-size: 20px;
    }
    .list__title {
      font-size: 20px;
      font-size: 20px;
    }
    .mobile-only {
      display: block;
      .searchwrapper {
        display: block;
      }
    }
    .wp-block-table {
      table {
        width: 100% !important;
      }
    }
    .wp-block-rm-advanced-table {
      table {
        width: 100% !important;
      }
    }
    .cke_editable {
      table {
        width: 100% !important;
      }
    }
    .field--name-field-advanced-table {
      overflow-x: auto;
    }
    #block-dfo-page-title {
      h1 {
        h1 {
          font-size: 30px;
          line-height: 38px;
        }
        font-size: 30px;
        line-height: 38px;
      }
    }
    main {
      h2 {
        font-size: 20px;
      }
    }
    .block-editor-block-list__layout {
      h2 {
        font-size: 20px;
      }
    }
    details.collapsable {
      .wp-block-rm-advanced-table {
        max-width: none;
        overflow-x: auto;
      }
    }
    div.collapsable {
      .wp-block-rm-advanced-table {
        max-width: none;
        overflow-x: auto;
      }
    }
    .wp-block-column {
      flex-basis: 100% !important;
      + {
        .wp-block-column {
          .card {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
    .wp-block-rm-factbox {
      .factbox__label {
        font-size: 20px;
      }
    }
    .card {
      + {
        .wp-block-columns {
          .wp-block-column {
            &:first-child {
              .card {
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
    .card.card--large {
      display: block;
      .card__illustration {
        width: auto;
      }
    }
    .card-front {
      .card__copy {
        padding: 40px 30px 70px 30px !important;
      }
    }
    .card--front {
      .card__copy {
        padding: 40px 30px 70px 30px !important;
      }
    }
    .card-front.card--medium {
      .card__copy {
        padding: 21px 24px 77px 24px;
      }
      .card__title {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
      .card__link-title {
        right: 30px;
        left: auto;
      }
      .card__body {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
      }
    }
    .card--front.card--medium {
      .card__copy {
        padding: 21px 24px 77px 24px;
      }
      .card__title {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
      .card__link-title {
        right: 30px;
        left: auto;
      }
      .card__body {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
      }
    }
    figure.media-type-block.align-left {
      width: 100%;
      max-width: 100%;
      float: none;
      margin: 0;
    }
    figure.media-type-block.align-right {
      width: 100%;
      max-width: 100%;
      float: none;
      margin: 0;
    }
    figure.wp-block-media-entity.align-left {
      width: 100%;
      max-width: 100%;
      float: none;
      margin: 0;
    }
    figure.wp-block-media-entity.align-right {
      width: 100%;
      max-width: 100%;
      float: none;
      margin: 0;
    }
    .field--name-field-image.align-left {
      width: 100%;
      max-width: 100%;
      float: none;
      margin: 0;
    }
    .field--name-field-image.align-right {
      width: 100%;
      max-width: 100%;
      float: none;
      margin: 0;
    }
    .field--name-field-media-image.align-left {
      width: 100%;
      max-width: 100%;
      float: none;
      margin: 0;
    }
    .field--name-field-media-image.align-right {
      width: 100%;
      max-width: 100%;
      float: none;
      margin: 0;
    }
    .wp-block-cover {
      .wp-block-columns {
        .wp-block-column {
          + {
            .wp-block-column {
              margin-top: 30px;
            }
          }
        }
      }
    }
    .wrapper-logo-header.header-with-mypage {
      .header-navigation {
        width: 100%;
      }
    }
    #block-dfo-site-branding {
      .logo {
        display: none;
      }
      .mobile-logo {
        display: block;
      }
    }
    .header-navigation {
      width: auto;
    }
    .searchwrapper {
      form {
        width: calc(100% - 40px);
        background: #0e2a49;
        border: none;
        padding: 40px 20px;
        margin-right: -20px;
        input.form-text {
          width: calc(100% - 20px) !important;
          padding: 5px 20px 5px 0;
          background: inherit;
          color: white;
          width: 100%;
          font-size: 18px;
          border-bottom: 3px solid white;
          &:focus {
            outline: none;
          }
          &::placeholder {
            color: white;
          }
        }
        div.form-actions {
          position: absolute;
          right: 17px;
        }
        input.form-submit {
          padding-top: 6px;
        }
        .js-form-type-textfield {
          width: 100%;
        }
      }
    }
    .searchtoggle {
      display: none;
    }
    .main-menu-outside-burger {
      display: none;
    }
    .block-content-sub-menu {
      width: 100%;
    }
    footer[role='contentinfo'] {
      .main-container {
        flex-direction: column;
      }
      ul.menu {
        max-width: 100%;
      }
      .footer__contact-wrapper {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        margin-left: 0;
        margin-top: 40px;
      }
    }
    .node.has-sidebar {
      .node__content {
        width: 100%;
      }
    }
    .page-node-type-process-page {
      h1.book-title {
        font-size: 30px;
        line-height: 38px;
      }
    }
    .node--type-landing-page {
      .node__illustration-intro-image {
        display: block;
        .field--name-field-image {
          margin-left: 0;
          min-width: auto;
        }
      }
    }
    .search-page {
      .region-content {
        .main-container {
          display: block;
          #block-search-box {
            width: 100%;
          }
        }
      }
    }
    .path-avtaler {
      .region-content {
        .main-container {
          display: block;
          #block-search-box {
            width: 100%;
          }
        }
      }
    }
    .webform-submission-feedback-form {
      #edit-flexbox {
        display: flex;
        justify-content: space-between;
        > .webform-flex {
          width: calc(50% - 10px);
        }
      }
      #edit-submit-yes-submit {
        float: left;
      }
      .form-item-answer-no {
        display: flex;
      }
    }
    #elma-registry-block {
      #search-holder {
        flex-direction: column;
      }
      .search-box-wrapper {
        order: 2;
        width: 100%;
        input {
          width: calc(100% - 73px) !important;
        }
      }
      .search-arguments-wrapper {
        margin-left: 0;
        width: 100%;
        order: 1;
        margin-bottom: 20px;
      }
    }
    #block-dfo-primary-local-tasks {
      padding-top: 15px;
      right: 0;
      li {
        margin-top: 5px;
      }
      a {
        display: block;
      }
    }
  }
  @media all and (min-width: 1025px) {
    .desktop-only {
      display: block;
    }
    .wp-block-table.is-large {
      margin-right: -120px;
      overflow-x: hidden;
    }
    .wp-block-rm-advanced-table.is-large {
      margin-right: -120px;
      overflow-x: hidden;
    }
    .cke_editable.is-large {
      margin-right: -120px;
      overflow-x: hidden;
    }
    .wp-block-column {
      flex-basis: 0;
      flex-grow: 1;
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
    .wp-block-column[style*='flex-basis'] {
      flex-grow: 0;
    }
    .card.card--large {
      .card__illustration {
        max-width: 50%;
      }
    }
    .wp-block-cover.wide {
      margin-left: calc(-50vw + (1146px / 2));
      margin-right: calc(-50vw + (1146px / 2));
      padding-left: calc((100vw - 1146px) / 2);
      padding-right: calc((100vw - 1146px) / 2);
    }
    .wrapper-logo-header {
      .region-header {
        width: 375px;
      }
    }
    .main-menu {
      ul {
        li {
          ul {
            column-count: 2;
            -moz-column-count: 2;
            -webkit-column-count: 2;
            column-gap: 2em;
            -moz-column-gap: 2em;
            -webkit-column-gap: 2em;
          }
        }
      }
    }
    .block-content-sub-menu {
      width: 705px;
    }
    .page-node-type-landing-page {
      .block-content-sub-menu {
        width: 100%;
        ul.menu {
          justify-content: space-between;
          display: flex;
          flex-wrap: wrap;
          li {
            margin-bottom: 15px;
            width: calc(50% - 10px);
          }
        }
      }
    }
    .node.has-sidebar {
      .node__content {
        width: 705px;
      }
    }
    .node--type-process {
      .node__content-wrapper {
        display: flex;
        justify-content: space-between;
      }
      .node__content {
        margin-top: 0;
        max-width: 759px;
      }
      .sidebar-burger {
        display: none;
      }
    }
    .node--type-process-page {
      .node__content-wrapper {
        display: flex;
        justify-content: space-between;
      }
      .node__content {
        margin-top: 0;
        max-width: 759px;
      }
      .sidebar-burger {
        display: none;
      }
    }
    .layout-top-content {
      height: 174px;
      margin-bottom: 25px;
      ul {
        padding: 0 52px 0 0;
        display: flex;
        flex-direction: row;
        flex: 1 1 0px;
      }
      li {
        &:first-child {
          a {
            &:hover {
              padding-left: 40px;
            }
          }
        }
        &:first-child.menu-item--active-trail {
          margin-left: -5px;
          a {
            padding-left: 40px;
            &:before {
              display: none;
            }
          }
        }
        &:not(.menu-item--active-trail) {
          &:not(.previous-to-active-parent) {
            a {
              &:hover {
                border-color: #0e2a49;
                border-width: 5px;
                height: 150px;
                margin-top: -2px;
                &::after {
                  background-image: url(../../images/drupal/icons/process-arrow-head-hover.svg);
                  position: absolute;
                  top: -5px;
                  height: 160px;
                }
              }
            }
          }
        }
      }
      li.menu-item--active-trail {
        a {
          border-color: #0e2a49;
          height: 160px;
          margin-top: -5px;
          &::after {
            background-image: url(../../images/drupal/icons/process-arrow-head-active.svg);
            position: absolute;
            top: -3px;
            right: -62px;
            height: 166px;
            width: 62px;
          }
          &::before {
            background-image: url(../../images/drupal/icons/process-arrow-tail-active.svg);
            content: '';
            height: 166px;
            width: 60px;
            left: -7px;
            top: -3px;
            position: absolute;
            display: block;
            z-index: 1;
            background-color: transparent;
          }
        }
      }
      li.previous-to-active-parent {
        a {
          &:hover {
            border-color: #0e2a49;
            border-width: 5px;
            height: 150px;
            margin-top: -2px;
          }
        }
      }
      a.blueArrow {
        &::after {
          background-image: url(../../images/drupal/icons/process-arrow-tail-hover.svg);
          position: absolute;
          top: -5px;
          display: block;
          content: '';
          width: 60px;
          height: 160px;
          transform: none;
          background-color: transparent !important;
          border: none;
        }
      }
    }
    .block-dfo-entity-usage-list {
      max-width: 705px;
    }
    article {
      .node__contact-wrapper {
        margin-left: calc(-50vw + (1146px / 2));
        margin-right: calc(-50vw + (1146px / 2));
        padding-left: calc((100vw - 1146px) / 2);
        padding-right: calc((100vw - 1146px) / 2);
      }
    }
    .path-erfaringsrommet {
      .views-element-container {
        max-width: 705px;
      }
    }
    .contact-profile {
      .profile-left {
        .image {
          margin-right: 12px;
        }
      }
    }
  }
  @media all and (min-width: 640px) and (max-width: 1024px) {
    #block-dfo-page-title {
      h1 {
        h1 {
          font-size: 38px;
          line-height: 47px;
        }
        font-size: 38px;
        line-height: 47px;
      }
    }
    .wp-block-column {
      flex-basis: calc(50%) !important;
      flex-grow: 0;
      &:nth-child(2n) {
        margin-left: 16px;
      }
    }
    .wrapper-logo-header {
      .region-header {
        width: auto;
      }
    }
    .page-node-type-process-page {
      h1.book-title {
        font-size: 38px;
        line-height: 47px;
      }
    }
    .node--type-process {
      .sidebar {
        margin-top: 26px;
      }
    }
    .node--type-process-page {
      .sidebar {
        margin-top: 26px;
      }
    }
    .node--type-landing-page {
      .node__illustration-intro-image {
        .field--name-field-image {
          width: 50%;
          margin-left: 50px;
        }
      }
      .node__illustration-intro {
        width: auto;
      }
    }
  }
  @media all and (max-width: 1024px) {
    details.collapsable {
      max-width: none;
      width: 100%;
    }
    div.collapsable {
      max-width: none;
      width: 100%;
    }
    .card.card--small {
      .card__copy {
        padding-bottom: 87px;
      }
    }
    .card.card--process {
      ul.process-list {
        flex-direction: column;
        width: 100%;
        padding-bottom: 17px;
        margin-bottom: 0;
        li {
          width: 100%;
          &:first-child {
            span {
              padding-left: 10px;
            }
          }
          > span {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            height: auto;
            min-height: 36px;
            justify-content: center;
            padding: 20px;
            border-color: #9a9a9a;
            border-width: 1px;
            text-align: center;
            &::after {
              left: calc(50% - 14px);
              bottom: -15px;
              top: auto;
              transform: rotate(135deg) skew(8deg, 8deg);
              height: 28px;
              width: 28px;
              z-index: 1;
              border-color: #9a9a9a;
              border-width: 1px;
            }
            &::before {
              content: '';
              width: 100px;
              height: 23px;
              background-color: white;
              position: absolute;
              bottom: 0;
              z-index: 2;
            }
          }
        }
      }
    }
    .card-front.card--medium {
      display: block;
      .card__copy {
        width: auto;
      }
      .card__illustration {
        width: auto;
        margin-right: 0;
        img {
          padding: 40px 30px 0 30px;
          width: calc(100% - 60px);
        }
      }
    }
    .card--front.card--medium {
      display: block;
      .card__copy {
        width: auto;
      }
      .card__illustration {
        width: auto;
        margin-right: 0;
        img {
          padding: 40px 30px 0 30px;
          width: calc(100% - 60px);
        }
      }
    }
    .wp-block-cover.wide {
      margin-left: -20px;
      width: 100%;
    }
    #block-dfo-site-branding {
      margin: 0;
      a {
        width: 100%;
        display: block;
        height: 100%;
      }
    }
    .header-navigation {
      position: relative;
      z-index: 10;
      margin: 35px 0;
    }
    .menutoggle {
      padding-right: 30px;
      background-size: 25px;
    }
    .main-menu {
      width: calc(100% + 40px);
      margin-bottom: 0;
      margin-left: -20px;
      margin-right: -20px;
      ul {
        li {
          a {
            &::after {
              right: 20px;
            }
          }
        }
      }
    }
    .main-container {
      width: auto;
    }
    .node.has-sidebar {
      .sidebar {
        display: none;
      }
    }
    .node.node--type-process {
      .sidebar {
        max-width: 100%;
      }
      #stickyMenu {
        display: none;
      }
    }
    .node.node--type-process-page {
      .sidebar {
        max-width: 100%;
      }
      #stickyMenu {
        display: none;
      }
    }
    .node--type-process {
      .node__content-wrapper {
        flex-direction: column;
      }
      .node__content {
        order: 3;
        + {
          .sidebar {
            display: none !important;
          }
        }
      }
      .layout-sidebar {
        display: none;
      }
      .sidebar {
        display: block !important;
        margin-right: 0;
        padding-bottom: 10px;
        width: auto !important;
        border-bottom: 1px dashed #d1d1d1;
        order: 2;
      }
    }
    .node--type-process-page {
      .node__content-wrapper {
        flex-direction: column;
      }
      .node__content {
        order: 3;
        + {
          .sidebar {
            display: none !important;
          }
        }
      }
      .layout-sidebar {
        display: none;
      }
      .sidebar {
        display: block !important;
        margin-right: 0;
        padding-bottom: 10px;
        width: auto !important;
        border-bottom: 1px dashed #d1d1d1;
        order: 2;
      }
    }
    .layout-top-content {
      li {
        &:first-child {
          a {
            padding-left: 20px;
            border-left-width: 1px;
            border-left-color: #9a9a9a;
          }
        }
        + {
          li {
            a {
              border-top: 0;
            }
          }
        }
      }
      li.menu-item--active-trail {
        a {
          &::after {
            background-color: #0e2a49 !important;
          }
        }
      }
      a {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        height: auto;
        min-height: 36px;
        justify-content: center;
        padding: 20px;
        border-color: #9a9a9a;
        border-width: 1px;
        border-left: 1px solid #9a9a9a;
        border-right: 1px solid #9a9a9a;
        text-align: center;
        &::after {
          left: calc(50% - 14px);
          bottom: -15px;
          top: auto;
          transform: rotate(135deg) skew(8deg, 8deg);
          height: 28px;
          width: 28px;
          z-index: 1;
          border: 1px solid #9a9a9a;
          content: '';
          display: block;
          position: absolute;
          background-color: white !important;
          background-image: none;
          right: -41px;
        }
        &::before {
          content: '';
          width: 100px;
          height: 23px;
          background-color: white;
          position: absolute;
          bottom: 0;
          z-index: 2;
        }
      }
    }
    nav.process-sub-navigation {
      display: none;
      ul.menu-level-0 {
        margin-bottom: 30px;
      }
    }
    .book-navigation {
      ul.book-navigation-list {
        flex-direction: column;
      }
      li {
        width: 100%;
      }
      li.book-navigation-prev {
        a {
          border-bottom: none;
          width: calc(100% - 66px);
        }
      }
    }
    article {
      .node__contact-wrapper {
        margin-left: -20px;
        width: 100%;
      }
    }
    .contact-profile {
      .profile-layout {
        display: block;
      }
      .profile-left {
        width: 100%;
        display: block;
      }
      .profile-right {
        width: 100%;
        margin-top: 8px;
      }
    }
  }
  @media all and (min-width: 640px) {
    .wp-block-columns {
      flex-wrap: nowrap;
      &:first-child {
        .card {
          margin-top: 0;
        }
      }
    }
    .wp-block-column {
      &:not(:first-child) {
        margin-left: 16px !important;
      }
    }
    .wp-block-rm-factbox.alignleft {
      width: 40%;
      float: left;
      margin-right: 20px;
    }
    .wp-block-rm-factbox.alignright {
      width: 40%;
      float: right;
      margin-left: 20px;
    }
    .content-list-wrapper.align-left {
      width: 40%;
      margin-right: 20px;
      float: left;
    }
    .content-list-wrapper.alignleft {
      width: 40%;
      margin-right: 20px;
      float: left;
    }
    .manual-content-list-wrapper.align-left {
      width: 40%;
      margin-right: 20px;
      float: left;
    }
    .manual-content-list-wrapper.alignleft {
      width: 40%;
      margin-right: 20px;
      float: left;
    }
    .content-list-wrapper.align-right {
      width: 40%;
      margin-left: 20px;
      float: right;
    }
    .content-list-wrapper.alignright {
      width: 40%;
      margin-left: 20px;
      float: right;
    }
    .manual-content-list-wrapper.align-right {
      width: 40%;
      margin-left: 20px;
      float: right;
    }
    .manual-content-list-wrapper.alignright {
      width: 40%;
      margin-left: 20px;
      float: right;
    }
    .searchwrapper {
      width: 250px;
      float: right;
    }
  }
}

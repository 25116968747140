@use './stylesheets/global';

.Process {
  position: relative;
  ul {
    list-style: none;
    padding: 0;
  }

  .ProcessStep {
    display: block;
    flex: 1;

    &:first-child .StepLink {
      padding-left: 30px;
    }

    &.Active .StepLink {
      background: var(--primary-dark-color);
      color: var(--white);
    }

    &:nth-of-type(1) {
      z-index: 4;
    }

    &:nth-of-type(2) {
      z-index: 3;
    }

    &:nth-of-type(3) {
      z-index: 2;
    }

    &:nth-of-type(4) {
      z-index: 1;
    }

    &.PreHover .StepLink::after {
      border-color: var(--primary-dark-color);
    }
  }

  .Iterate {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 8;
    display: flex;
    flex-direction: column;
    align-content: stretch;

    div {
      width: 100%;
      height: 100%;
      background-image: url('../../images/iterate-arrow-primary.svg');
      background-repeat: no-repeat;
      background-position: calc(50% - 1.25rem);

      &.LightArrow {
        background-image: url('../../images/iterate-arrow-light.svg');
      }
    }

    .Forward {
      background-position: calc(50% + 1.25rem);
      transform: rotate(180deg);
    }
  }

  .StepLink {
    font-family: 'Source Sans Pro', serif;
    font-size: 22px;
    line-height: 27px;
    font-weight: var(--font-weight-semibold);
    color: var(--primary-dark-color);
    padding: 0 0 0 60px;
    text-decoration: none;
    background: white;
    height: 149px;
    border-width: 3px 0 3px 3px;
    border-style: solid;
    border-color: var(--border-color-secondary);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover,
    &:focus {
      color: var(--primary-dark-color);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: inherit;
      height: 188px;
      width: 189px;
      transform: rotate(45deg) skew(12deg, 12deg);
      border: 3px var(--border-color-secondary) solid;
      top: -23px;
      right: -40px;
      z-index: -1;
    }

    .IconContainer {
      position: absolute;
      bottom: 10px;
    }
  }

  .Tooltip {
    font-size: var(--font-size-base);
    font-family: var(--primary-font);
    width: 32rem;
    border: var(--border-width-thin) solid var(--secondary-color);
    box-sizing: border-box;
    height: auto;
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 20px;
    letter-spacing: 0.01em;
    color: var(--text-color);
    text-align: left;
    padding: 22px 21px 22px 22px;
    opacity: 1 !important;
  }
}

@media all and (min-width: global.breakpoint('xl-end')) {
  .Process {
    ul {
      padding: 0 79px 0 0;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex: 1 1 0px;
    }

    li.Active {
      .StepLink {
        border-color: var(--primary-dark-color);

        &::after {
          border-color: var(--primary-dark-color);
          border-width: 0;
        }
      }
    }

    .StepLink:hover,
    .StepLink:focus {
      border-color: var(--primary-dark-color);

      &::after {
        border-color: var(--primary-dark-color);
      }
    }
  }
}

@media all and (max-width: global.breakpoint('lg')) {
  .Process {
    li {
      &:first-child .StepLink {
        padding-left: 20px;
      }

      &.Active .StepLink::before {
        background-color: var(--primary-dark-color);
      }

      & + li .StepLink {
        border-top: 0;
      }
    }

    .StepLink {
      font-family: 'Source Sans Pro', serif;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      height: auto;
      min-height: 36px;
      justify-content: center;
      padding: 20px;
      border-color: #9a9a9a;
      border-width: 1px;
      text-align: center;

      &::after {
        left: calc(50% - 14px);
        bottom: -15px;
        top: auto;
        transform: rotate(135deg) skew(8deg, 8deg);
        height: 28px;
        width: 28px;
        z-index: 1;
        border-color: #9a9a9a;
        border-width: 1px;
      }

      &::before {
        content: '';
        width: 100px;
        height: 23px;
        background-color: white;
        position: absolute;
        bottom: 0;
        z-index: 2;
      }
      .IconContainer {
        visibility: hidden;
      }
    }
  }
}

.StaticProcess {
  .StepTitle {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: var(--font-weight-bold);
    hyphens: auto;
  }

  use {
    fill: var(--white);
    stroke: #d1d1d1;
  }
}

.DescriptionAndTags {
  display: block;

  .Decription {
    margin-bottom: var(--grid-row-gap);
  }

  h2 {
    margin-bottom: calc(var(--grid-row-gap) / 2);
    font-size: var(--font-size-h3);
    font-family: var(--primary-font);
  }
}

img {
  max-width: 100%;
}

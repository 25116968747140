@use 'sass:map';
@use '/src/stylesheets/global' as global;

.Forside {
  display: flex;
  flex-direction: column;
  gap: var(--grid-row-gap);

  section {
    label.searchField {
      max-width: 41.25rem;
      display: grid;
      grid-template-areas:
        'labelarea labelarea'
        'inputarea buttonarea';
      grid-template-columns: 1fr auto;
      margin-bottom: 0;

      input {
        grid-area: inputarea;
        border-right: none;
      }

      button {
        grid-area: buttonarea;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: var(--border-width) solid var(--border-color);
        border-left: none;
        padding: 0;
        background-color: var(--white);
        margin-top: 0.625rem;
        width: 3.125rem;
        color: var(--secondary-color);
      }
    }

    &.handlingBokser,
    &.veiledningBokser,
    &.avtaleBokser,
    &.col_3 {
      display: grid;
      column-gap: var(--grid-column-gap);
      row-gap: var(--grid-column-gap);
      grid-template-columns: repeat(3, 1fr);
    }

    &.handlingBokser,
    &.veiledningBokser,
    &.avtaleBokser {
      > * {
        grid-column: span 1;

        &[data-layout='horizontal'] {
          grid-column: span 2;
        }
      }
    }
  }
}

@media only screen and (max-width: global.breakpoint('lg')) {
  .Forside {
    section {
      &:not(.col_3) {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        row-gap: var(--grid-row-gap);
        column-gap: initial;
      }
      &.handlingBokser,
      &.veiledningBokser,
      &.avtaleBokser {
        > * {
          grid-column: initial;

          &[data-layout='horizontal'] {
            grid-column: initial;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .Forside {
    section {
      &.handlingBokser,
      &.veiledningBokser,
      &.avtaleBokser,
      &.col_3 {
        display: flex;
        flex-direction: column;
        gap: var(--grid-column-gap);
      }
    }
  }
}

@use 'sass:map';
@use '/src/stylesheets/global' as global;

.LeverandoerProfilSide {
  h1 {
    font-family: var(--primary-font);
    font-size: 3.125rem;
    font-weight: var(--font-weight-semibold);
    padding-bottom: 2rem;
  }

  p {
    font-family: var(--primary-font);
    font-size: 1.25rem;
    font-weight: var(--font-weight-normal);
  }

  .container {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    width: 100%;

    .profilSide_description {
      border: 2px solid var(--tab-button-bg-hover);
      background: var(--white);
      padding: 3rem;
    }

    .kategori_container {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      .profilSide_kategori {
        display: flex;
        padding: 0.375rem 1rem;
        border-radius: 6.25rem;
        font-size: var(--font-size-normal);
        background: var(--secondary-color-25);
        color: var(--primary-color);
      }
    }

    .profilSide_link {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 2rem;
    }

    a {
      font-family: var(--primary-font);
      font-size: 1.5rem;
      text-decoration-line: underline;
    }
  }

  .profilSide_contactWrapper {
    display: flex;
    gap: 3rem;
    padding: 2rem 0;
  }

  .profilSide_contactBox {
    width: 100%;
    border: 2px solid var(--tab-button-bg-hover);
    background: var(--white);
    padding: 2rem;

    h4 {
      padding-bottom: 2rem;
      font-family: var(--primary-font);
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-semibold);
    }
  }

  .profilSide_contactInfoWrap {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  .profilSide_otherInfo {
    border: 2px solid var(--tab-button-bg-hover);
    background: var(--white);
    padding: 3rem;

    h3 {
      padding-bottom: 2rem;
    }
  }
}

@use '/src/stylesheets/global' as global;

.container {
  input {
    margin-bottom: 1rem;
  }
  .title {
    font-family: var(--secondary-font);
    font-size: 3.125rem;
    font-weight: var(--font-weight-semibold);
    margin-bottom: 4rem;
  }
  .kategori_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    .kategori {
      display: flex;
      width: fit-content;
      padding: 0.375rem 1rem;
      border-radius: 6.25rem;
      font-size: var(--font-size-normal);
      background: var(--secondary-color-25);
      color: var(--primary-color);
    }
  }
  .leverandoer_oversikt {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
  }
}

.LeverandoerListe {
  background: transparent;

  section {
    h1 {
      font-size: var(--font-size-large);
    }

    p {
      white-space: pre-wrap;
      font-size: var(--font-size-normal);
    }

    a,
    a:link,
    a:visited {
      color: var(--primary-color);
      text-decoration: none;
    }

    + section {
      margin-top: 2.5rem;
    }
  }
}
.EmptyList {
  p {
    display: flex;
    justify-content: center;
  }
  [class*='icon-'] {
    display: flex;
    justify-content: center;
    font-size: var(--font-size-h1);
  }
}

.Question {
  margin-top: 3rem;
  display: grid;
  grid-template-areas:
    'introduction'
    'guidance';
  grid-row-gap: 3rem;

  .Introduction {
    grid-area: introduction;
    background: var(--white);
    border: var(--border-width-thin) solid var(--primary-dark-color);
    box-sizing: border-box;
    padding: 3rem 4rem 0 4rem;
    margin-bottom: 1.5rem;

    .subject {
      font-size: 1.125rem;
    }

    .main {
      h1 {
        margin: 2rem 0 4rem 0;
        color: var(--primary-dark-color);
        font-size: 2.1875rem;
        font-family: var(--primary-font);
        font-weight: var(--font-weight-normal);
      }

      p {
        margin: 2rem 0 4rem 0;
        color: var(--primary-dark-color);
        font-size: var(--font-size-large);
        font-weight: var(--font-weight-normal);
      }

      a {
        text-decoration: none;
        color: var(--secondary-color);

        span {
          &:first-child {
            margin-right: 0.5rem;
          }
        }

        [class^='Icon'] {
          font-size: 1rem;
          border: var(--border-width-thin) solid var(--secondary-color);
          border-radius: 50%;
          padding: var(--border-width-thin);
        }
      }
    }

    .options {
      display: flex;
      justify-content: center;
      padding: 1.5rem;

      > div {
        margin: 1.5rem;
        text-align: center;
      }

      input[type='radio'] {
        border: 0.1875rem solid #d1d1d1;
        margin: 0;
        width: 4rem;
        height: 4rem;

        &[checked] {
          border: 0.1875rem solid var(--primary-dark-color);

          &:after {
            content: '\e900';
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid var(--white);
            border-radius: 50%;
            background-color: var(--primary-dark-color);
            color: var(--white);
            font-family: dfo-symbols, sans-serif;
            font-size: 2rem;
          }
        }
      }

      label {
        margin: 0;
        text-transform: uppercase;
        font-size: 1.625rem;
        font-weight: var(--font-weight-semibold);
      }
    }

    .Actions {
      display: grid;
      grid-template-columns: 3fr 1fr 3fr;
      grid-column-gap: 1rem;
      margin-bottom: calc((var(--button-vertical-padding) * 2) * -1);

      button {
        [class^='Icon'] {
          font-size: 1rem;
          display: inline-block;
          transform: rotate(-90deg);
        }

        &.back {
          grid-column: 1;
          justify-self: right;
          border-radius: 50%;
          padding: 0;
          width: calc(var(--button-vertical-padding) * 4);
          height: calc(var(--button-vertical-padding) * 4);
          margin-top: 0.2rem;
        }

        &.next {
          grid-column: 2;

          span {
            font-size: var(--font-size-h4);
          }
        }
      }
    }
  }
}

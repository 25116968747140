@use '/src/stylesheets/global' as global;

.RedigerTjenesteSide {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  .Wrapper {
    grid-column: 1 / 9;
    display: grid;
    gap: 2rem;

    > header {
      p {
        font-size: var(--font-size-h4);
        font-weight: var(--font-weight-bold);
        color: var(--primary-color);
      }

      h1 {
        margin: calc(var(--font-size-base) / 2) 0;
      }
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .RedigerTjenesteSide {
    grid-template-columns: 1fr;

    .Wrapper {
      grid-column: 1;
    }
  }
}

.Icon {
  font-size: 1.5rem;

  &:before {
    font-size: inherit;
  }

  &.success {
    color: var(--tertiary-color);
  }

  &.error {
    color: var(--error-color);
  }

  &.warning {
    color: var(--warning-color);
  }

  &.neutral {
    color: var(--text-light-color);
  }
}

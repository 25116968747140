@use '/src/stylesheets/global' as global;

.Grid {
  display: grid;
  gap: var(--grid-column-gap);

  &[data-grid-columns='2'] {
    grid-template-columns: repeat(2, 1fr);
  }
  &[data-grid-columns='3'] {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: global.breakpoint('lg')) {
  .Grid {
    &[data-grid-columns='2'] {
      grid-template-columns: repeat(1, 1fr);
    }

    &[data-grid-columns='3'] {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media only screen and (max-width: global.breakpoint('md')) {
  .Grid {
    &[data-grid-columns='2'],
    &[data-grid-columns='3'] {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.Report {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;

  .status {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-normal);
    font-family: var(--primary-font);
    padding: 4rem;

    [class*='icon'] {
      font-size: var(--font-size-h1);
      margin-right: 1rem;
    }

    &.ok {
      background: #00a982;
    }

    &.fail {
      background: #e83f53;
    }
  }

  button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: fit-content;

    [class*='icon'] {
      margin-right: 0.625rem;
      margin-left: 0.625rem;
    }
  }
}

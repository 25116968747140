@use '/src/stylesheets/global' as global;

:root {
  --modal-grid-gap: 2.5rem;
}

.MP-Modal {
  &__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    background-color: var(--background-color-overlay);
  }

  &__Modal {
    box-sizing: border-box;
    position: relative;
    min-height: 40.9375rem;
    width: 65.625rem;
    max-width: 90vw;
    font-size: var(--font-size-normal);
    padding: 5.875rem;
    background-color: var(--white);
    display: grid;
    gap: var(--modal-grid-gap);

    &__Confirmation {
      min-height: 0;
      width: auto;
      min-width: 50vw;
    }

    form {
      display: grid;
      gap: var(--modal-grid-gap);
    }

    header,
    section,
    form,
    footer {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    header {
      h1 {
        font-family: var(--primary-font);
        font-size: var(--font-size-large);
        font-weight: var(--font-weight-normal);

        + p {
          font-size: var(--font-size-normal);
          margin-top: 0.8125rem;
        }
      }
    }
  }

  &__Close {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    padding: 1.25rem;
    background-color: transparent;
    color: var(--primary-color);
  }
}

@media only screen and (max-width: global.breakpoint('lg')) {
  .MP-Modal {
    &__Overlay {
      z-index: 20;
      background-color: var(--white);
    }

    &__Modal {
      min-height: initial;
      max-height: 100vh;
      width: auto;
      max-width: 100vw;
      padding: 2.5rem;
      overflow-y: scroll;
    }

    &__Close {
      position: fixed;
      right: 0.625rem;
      top: 0.625rem;
    }
  }
}

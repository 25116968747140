.LinkList {
  display: flex;
  flex-direction: column;

  li {
    border-style: solid;
    border-width: var(--border-width-thin) 0 0 0;
    border-color: var(--secondary-color);

    a {
      padding: 0.9375rem 1.875rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;

      &:hover,
      &:focus {
        color: var(--link-hover-color);
      }
    }

    &:last-child {
      border-style: solid;
      border-bottom-width: var(--border-width-thin);
      border-color: var(--secondary-color);
    }
  }
}

@use '/src/stylesheets/global' as global;

.NotFound {
  display: flex;
  justify-content: space-evenly;

  div {
    flex: 1;

    &:first-child {
      display: flex;
      justify-content: center;
    }

    img {
      max-height: 30vh;
    }

    p {
      font-size: var(--font-size-large);

      &:first-of-type {
        margin-bottom: var(--font-size-large);
      }
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .NotFound {
    display: block;
    div {
      &:first-child {
        display: none;
      }
    }
  }
}

.RegistrerTjenesteSide {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  .Wrapper {
    grid-column: 1 / 9;
    display: grid;
    gap: 2rem;

    > header {
      p {
        font-size: var(--font-size-h4);
        font-weight: var(--font-weight-bold);
        color: var(--primary-color);
      }

      h1 {
        margin: calc(var(--font-size-base) / 2) 0;
      }
    }
  }

  label {
    font-weight: var(--font-weight-semibold);
    margin-bottom: 0.625rem;
  }

  p {
    margin-top: 0.625rem;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-normal);
    line-height: normal;
  }

  .RadioButtonsText {
    font-weight: var(--font-weight-normal);
  }
}

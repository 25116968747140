@use '/src/stylesheets/global' as global;

.TjenesteRegistrationForm {
  form {
    display: grid;
    gap: 2.5rem;

    label {
      font-weight: var(--font-weight-semibold);

      p {
        font-weight: var(--font-weight-normal);
      }
    }

    p {
      margin-top: 0.625rem;
      color: var(--text-color);
      font-size: var(--font-size-normal);
      line-height: 150%;
    }
  }

  .dropDown {
    label {
      margin-bottom: 0.625rem;
    }
  }

  .fileInput {
    display: grid;
    gap: 1rem;

    label {
      margin-bottom: 0;
    }

    .dropArea {
      display: grid;
      position: relative;
      min-height: 12.5rem;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        border: 0.1875rem dashed var(--line-color);
        top: calc(var(--border-width) * -1);
        bottom: calc(var(--border-width) * -1);
        left: calc(var(--border-width) * -1);
        right: calc(var(--border-width) * -1);
      }
    }

    .spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    .preview {
      display: grid;
      gap: 0.5rem;
      grid-template: auto / repeat(6, 1fr);

      div {
        width: 6.25rem;
        height: 6.25rem;
        background-color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: var(--border-width-thin) solid var(--line-color);
      }
    }

    span {
      white-space: pre-line;
      text-align: center;
      align-self: center;
      font-size: var(--font-size-small);

      button {
        margin-top: 0.5rem;
      }
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

[class*='FormGroup'][class*='ShowValidation'][class*='IsInvalid'] {
  .fileInput {
    > label,
    > label * {
      color: var(--error-color);
      border-color: var(--error-color);
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .RedigerTjenesteSide {
    grid-template-columns: 1fr;

    .Wrapper {
      grid-column: 1;
    }
  }
}

@use '/src/stylesheets/global' as global;

.TabSelector {
  display: flex;
  border-bottom: var(--border-width) solid var(--border-color-light);
  width: 100%;

  button {
    box-sizing: border-box;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    max-width: calc(100% / 3);
    font-size: 1.1875rem;
    line-height: 3.4375rem;
    text-decoration: none;
    color: var(--primary-dark-color);
    background-color: var(--tab-button-bg-inactive);
    cursor: pointer;

    + button {
      margin: 0;
    }

    &:focus,
    &:hover {
      background-color: var(--tab-button-bg-hover);
    }

    &.active {
      background-color: var(--tab-button-bg-active);
      color: var(--white);
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .TabSelector {
    button {
      font-size: var(--font-size-base);
    }
  }
}

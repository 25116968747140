.LinkContainer {
  h2 {
    font-size: var(--font-size-h4);
  }
  .Link {
    text-decoration: none;
    display: flex;
    align-items: center;

    .LinkText {
      text-decoration: underline;
      margin-right: 0.5rem;
    }
  }
}

@use '/src/stylesheets/global' as global;

.IkkeAutorisert {
  font-size: var(--font-size-normal);

  h1 {
    margin-top: 0;
    margin-block-end: var(--border-width-thin);
  }

  h2 {
    margin-top: 3.125rem;
    margin-bottom: 0.625rem;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
  }

  p {
    font-size: inherit;
  }

  .ingress {
    margin: 5rem 0 2.5rem;
    font-size: var(--font-size-large);
    white-space: pre-line;
    color: var(--primary-color);
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: black;

    li:last-child {
      font-weight: var(--font-weight-bold);
    }

    + ul {
      margin-top: 1.5625rem;
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .IkkeAutorisert {
    h1 {
      margin: 0 0 1.25rem;
      font-size: var(--font-size-h4);
    }
  }
}

.TjenesteListe {
  --list--gap--base: calc(var(--grid-column-gap) / 2);
  --list--item--padding: calc(var(--grid-column-gap) * 1.5);

  display: flex;
  flex-direction: column;
  gap: var(--list--gap--base);
  padding-block: 1px;

  a {
    display: block;
    background-color: white;
    padding-inline: var(--list--item--padding);
    padding-block: var(--list--item--padding);
    text-decoration: none;
    border: var(--border-width) solid var(--border-color-secondary);

    div {
      div:nth-child(1) {
        text-transform: uppercase;
      }
    }
  }
}

@use '/src/stylesheets/global' as global;

.Firmaprofil {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  .Wrapper {
    grid-column: 1 / 13;
    display: grid;
    column-gap: var(--grid-column-gap);
    row-gap: var(--grid-row-gap);
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  header {
    div {
      font-family: var(--secondary-font);
      font-size: var(--font-size-large);
    }
  }

  &__actions {
    --gap--base: 1rem;

    display: flex;
    justify-content: flex-start;
    gap: calc(var(--gap--base) * 0.5);
    cursor: pointer;

    [class*='icon-'] {
      font-size: var(--font-size-normal);
    }

    &:hover {
      color: var(--link-hover-color);
    }

    [aria-disabled='true'] {
      color: var(--disabled-color);
      cursor: none;

      &:hover {
        color: var(--disabled-color);
      }
    }
  }

  section {
    display: grid;
    gap: var(--grid-row-gap);

    h2 {
      font-size: var(--font-size-large);
      font-family: var(--secondary-font);
      text-transform: uppercase;
    }
  }

  li {
    display: inline-block;
    margin: 1.25rem 1.25rem 0 0;
    background: rgb(0, 91, 144);
    color: white;
    font-size: var(--font-size-normal);

    a,
    a.link,
    a.active,
    a:hover {
      display: inline-block;
      padding: 0.625rem 1.25rem;
      color: inherit;
      text-decoration: none;
      font-size: inherit;

      &.disabled {
        cursor: default;
        pointer-events: none;
        background-color: var(--disabled-color);
      }
    }
  }

  label {
    display: block;
    margin-bottom: 0.625rem;
    font-weight: var(--font-weight-semibold);
  }

  .Information {
    background: var(--white);
    border: var(--border-width-thin) solid var(--primary-dark-color);
    box-sizing: border-box;
    padding: 3rem 4rem;
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .Firmaprofil {
    grid-template-columns: 1fr;

    .Wrapper {
      grid-column: 1;
    }

    li {
      margin-right: 0.625rem;
      font-size: var(--font-size-smaller);

      a {
        padding: 0.625rem;
      }
    }
  }
}

@use '/src/stylesheets/global' as global;

.MinProfil {
  margin: 0 9.375rem;

  h1 {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .MinProfil {
    h1 {
      margin: 0 0 1.25rem;
      font-size: var(--font-size-h4);
    }
  }
}

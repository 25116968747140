label.searchField {
  max-width: 41.25rem;
  display: grid;
  grid-template-areas:
    'labelarea labelarea'
    'inputarea buttonarea';
  grid-template-columns: 1fr auto;
  margin-bottom: 0;

  input {
    grid-area: inputarea;
    border-right: none;
  }

  button {
    grid-area: buttonarea;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: var(--border-width) solid var(--border-color);
    border-left: none;
    padding: 0;
    background-color: var(--white);
    margin-top: 0.625rem;
    width: 3.125rem;
    color: var(--secondary-color);
  }
}

.LeverandoerSide {
  background: transparent;
  color: var(--text-color);
  font-size: var(--font-size-normal);

  header {
    margin-bottom: 3.125rem;

    h1 {
      margin-top: 1.5625rem;
      color: var(--primary-color);
    }

    h2 {
      font-family: var(--primary-font);
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-normal);
    }
  }

  section {
    line-height: 150%;

    h1 {
      margin-bottom: 0.625rem;
      color: var(--primary-color);
      font-size: var(--font-size-large);
      line-height: 1.75rem;
    }

    span {
      display: inline-block;

      + span {
        margin-left: 2.5rem;
      }

      &.dato {
        font-size: var(--font-size-small);
      }
    }

    + section {
      margin-top: 3.75rem;
    }

    .beskrivelse {
      white-space: pre-wrap;
    }

    + section {
      display: grid;
      row-gap: 0.5rem;

      [class^='Icon'] {
        margin-left: 0.5rem;
      }
    }
  }
}

@use '/src/stylesheets/global' as global;

.SokeSide {
  .header {
    margin-bottom: 5rem;

    h1 {
      margin-bottom: 0.5rem;
    }
  }

  .sok {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--grid-column-gap);

    > *:nth-child(1) {
      grid-column: 1 / 5;
    }

    > *:nth-child(2) {
      grid-column: 5 / 13;
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .SokeSide {
    .sok {
      grid-template-columns: 1fr;
      > *:nth-child(1) {
        grid-column: 1 / 13;
      }

      > *:nth-child(2) {
        grid-column: 1 / 13;
      }
    }
  }
}

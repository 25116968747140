.FunksjonskategoriFilter {
  display: flex;
  flex-direction: column;
  row-gap: var(--grid-column-gap);
  margin-top: 3.125rem;

  h1 {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-semibold);
    font-family: var(--secondary-font);
  }

  .selectedKategorier {
    display: flex;
    align-items: center;
    column-gap: var(--grid-column-gap);

    > span {
      text-decoration: underline;

      &[data-selected='true'] {
        font-weight: var(--font-weight-bold);
        text-decoration: none;
        margin-left: var(--grid-row-gap);

        &[data-first-children='true'] {
          margin-left: 0;

          &[data-have-children='false'] {
            margin-left: var(--grid-row-gap);
          }
        }
      }
    }

    [class*='icon-'] {
      font-size: var(--font-size-large);
      text-decoration: none;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: var(--grid-column-gap);

    li {
      ul {
        li {
          span {
            text-decoration: underline;
            &:hover {
              color: var(--link-hover-color);
            }

            &[data-selected='false'] {
              &[data-hoved-kategori='false'] {
                &:nth-child(1) {
                  margin-left: calc(var(--grid-row-gap) * 1.5);
                }
              }
            }
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }

  .selected {
    display: flex;
    flex-direction: column;
    gap: var(--grid-column-gap);
  }
}

.FormGroup {
  &.ShowValidation {
    position: relative;

    &.IsInvalid {
      > label,
      > label * {
        color: var(--error-color);
        border-color: var(--error-color);
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1rem;
        width: 2px;
        background-color: var(--error-color);
      }
    }
  }

  .Error {
    color: var(--error-color);
    font-size: var(--font-size-small);
    font-style: italic;
  }

  > * {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.Panel {
  --panel--padding--base: 1.25rem;
  --panel--padding--top: calc(var(--panel--padding--base) * 2);
  --panel--padding--right: calc(var(--panel--padding--base) * 2);
  --panel--padding--bottom: calc(var(--panel--padding--base) * 2);
  --panel--padding--left: calc(var(--panel--padding--base) * 4);

  --panel--padding: var(--panel--padding--top) var(--panel--padding--right)
    var(--panel--padding--bottom) var(--panel--padding--left);

  background: var(--white);
  border: 1px solid var(--border-color-secondary);
  display: grid;
  grid-template-areas:
    'header'
    'content';

  .Header {
    --panel-header--column-size: var(--panel--padding--left);

    display: grid;
    grid-template-areas: 'status title toggle';
    grid-template-columns: var(--panel-header--column-size) 1fr 0.5fr;
    grid-template-rows: minmax(var(--panel-header--column-size), auto);
    margin-bottom: 0;

    .Status,
    h1,
    .Toggle {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      .Toggle {
        color: var(--link-hover-color);
      }
    }

    .Status {
      grid-area: status;

      &[data-state-color] {
        &[data-state-color='primary'] [class*='icon-'] {
          color: var(--primary-color);
        }
        &[data-state-color='secondary'] [class*='icon-'] {
          color: var(--secondary-color);
        }
        &[data-state-color='tertiary'] [class*='icon-'] {
          color: var(--tertiary-color);
        }
        &[data-state-color='error'] [class*='icon-'] {
          color: var(--error-color);
        }
        &[data-state-color='warning'] [class*='icon-'] {
          color: var(--warning-color);
        }
      }
    }

    h1 {
      grid-area: title;
      margin: 0;
      justify-content: flex-start;
      font-size: var(--font-size-h4);
      font-family: var(--primary-font);
    }

    .Toggle {
      grid-area: toggle;
    }
  }

  .Content {
    grid-area: content;
    padding: var(--panel--padding);
  }

  &.Clickable {
    &:hover {
      cursor: pointer;

      .Toggle {
        [class*='arrow-right'] {
          color: var(--link-hover-color);
        }
      }
    }
  }

  &.Collapsed {
    .Content {
      display: none;
    }
  }
}

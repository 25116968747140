@use '/src/stylesheets/global' as global;

.RegistrerTypeVirksomhet {
  h1 {
    font-family: var(--secondary-font);
    font-size: 36px;
    font-weight: var(--font-weight-semibold);
    margin-bottom: 2.5rem;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  label {
    font-family: var(--primary-font);
    font-weight: var(--font-weight-semibold);
  }
}

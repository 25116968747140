.List {
  --list--gap--base: 1rem;
  --list--gap: var(--list--gap--base);
  --list--gap--horizontal: var(--list--gap);
  --list--gap--vertical: calc(var(--list--gap) / 2);

  display: flex;
  gap: var(--list--gap--vertical) var(--list--gap--horizontal);
  flex-wrap: wrap;

  &:empty {
    display: none;
  }

  > * {
    margin: 0;
  }

  span {
    display: flex;
    align-items: center;
  }

  &[data-list-gap='none'] {
    --list--gap: 0;
  }

  &[data-list-gap='sm'] {
    --list--gap: calc(var(--list--gap--base) * 0.5);
  }

  &[data-list-gap='md'] {
    --list--gap: calc(var(--list--gap--base) * 1);
  }

  &[data-list-gap='lg'] {
    --list--gap: calc(var(--list--gap--base) * 1.5);
  }

  &[data-list-orientation='vertical'] {
    flex-direction: column;
    justify-content: flex-start;
  }

  &[data-list-orientation='horizontal'] {
    flex-direction: row;
    justify-content: flex-start;
  }

  &[data-list-alignment='start'] {
    justify-content: flex-start;
  }

  &[data-list-alignment='end'] {
    justify-content: flex-end;
  }

  &[data-list-alignment='center'] {
    justify-content: center;
  }

  &[data-list-spacing='around'] {
    justify-content: space-around;
  }

  &[data-list-spacing='between'] {
    justify-content: space-between;
  }

  &[data-list-spacing='stretch'] {
    justify-content: stretch;

    > * {
      flex-grow: 1;
    }
  }
}

@use 'sass:map';
@use '/src/stylesheets/global' as global;

a:link {
  text-decoration: none;
}

.Card {
  --card-title-font-size: 1.625rem;
  --card-icon-font-size: 2.5rem;
  --card-internal-grid-gap: 1.25rem;
  --card-internal-grid-gap-small: 0.625rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--card-internal-grid-gap);
  box-sizing: border-box;
  padding: map.get(global.$card-spacings, 'narrow');
  background-color: var(--white);
  font-size: var(--font-size-normal);
  border: var(--border-width) solid var(--border-color-secondary);

  a,
  a:visited {
    color: var(--link-color);

    &:hover,
    &:active {
      color: var(--link-color);
    }
  }

  &[data-href] {
    cursor: pointer;

    &:hover,
    &:focus-within {
      border: var(--border-width) solid var(--primary-color);
    }
  }

  &:not([data-href]) {
    a,
    a:visited {
      text-decoration: underline;
      color: var(--link-color);

      &:hover,
      &:focus {
        color: var(--link-hover-color);
      }
    }
  }

  &__meta {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
  }

  &__tittel {
    h1 {
      font-family: var(--primary-font);
      font-size: var(--font-size-h);
      font-weight: var(--font-weight-normal);
      hyphens: initial;
    }
  }

  &__innhold {
    display: flex;
    flex-direction: column;
    gap: var(--card-internal-grid-gap-small);
  }

  &__bunntekst {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__handling {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch;

    a {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      font-size: var(--font-size-base);
      color: var(--primary-color);
      height: 2.5rem;

      [class*='icon-'] {
        margin-left: 0.875rem;
        font-size: var(--card-icon-font-size);
      }
    }

    button {
      &:only-child {
        margin-right: auto;
      }
    }
  }

  &__illustrasjon {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 10rem;
      aspect-ratio: 5 / 4;
    }
  }

  &[data-layout] {
    &[data-layout='default'] {
      .Card {
        &__handling {
          margin-top: auto;
        }
      }
    }

    &[data-layout='centered'] {
      .Card {
        &__meta,
        &__tittel,
        &__innhold,
        &__bunntekst,
        &__handling {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }
    }

    &[data-layout='horizontal'] {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto 1fr auto;
      grid-template-areas:
        'meta illustrasjon'
        'tittel illustrasjon'
        'tittel handling';

      .Card {
        &__meta {
          grid-area: meta;
          font-size: var(--font-size-h3);
        }

        &__tittel {
          grid-area: tittel;
        }

        &__illustrasjon {
          grid-area: illustrasjon;
        }

        &__handling {
          grid-area: handling;
        }
      }
    }
  }

  &[data-theme] {
    &[data-theme='primary'],
    &[data-theme='primarydark'],
    &[data-theme='tertiary'] {
      color: var(--white);

      h1 {
        color: var(--white);
      }

      a,
      a:visited {
        color: var(--white);

        &:hover,
        &:active {
          color: var(--white);
        }
      }

      button {
        background-color: var(--warning-color);
        color: var(--primary-color);
      }

      &[data-href] {
        &:hover,
        &:focus-within {
          border-color: var(--white);
        }
      }

      &:not([data-href]) {
        a,
        a:visited {
          color: var(--white);

          &:hover,
          &:focus {
            color: var(--white);
          }
        }
      }
    }

    &[data-theme='primary'] {
      background-color: var(--primary-dark-color);
      border-color: var(--primary-dark-color);
    }

    &[data-theme='tertiary'] {
      background-color: var(--tertiary-color);
      border-color: var(--tertiary-color);

      &[data-href] {
        &:hover,
        &:focus-within {
          border-color: var(--primary-color);
        }
      }

      .Card {
        &__meta {
          font-size: var(--font-size-h3);
        }
      }
    }

    &[data-theme='secondary'] {
      background-color: var(--background-dark-color);
    }

    &[data-theme='blank'] {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

.Header {
  grid-template-areas: 'innhold illustrasjon';
  grid-template-rows: auto;
  padding: 0;

  .Card {
    &__illustrasjon {
      img {
        max-height: 15rem;
      }
    }
  }
}

.Tjenestekategori {
  padding: map.get(global.$card-spacings, 'even');

  .Card {
    &__illustrasjon {
      order: 1;
      [class*='icon'] {
        font-size: var(--card-icon-font-size);
      }
    }

    &__tittel {
      order: 2;
      h1 {
        font-family: var(--secondary-font);
        font-size: var(--font-size-large);
        font-weight: var(--font-weight-bold);
      }
    }

    &__innhold {
      order: 3;
      p {
        font-size: var(--font-size-smaller);
      }
    }
  }
}

.Avtale {
  display: grid;
  grid-template-areas:
    'meta         meta'
    'tittel       tittel'
    'bunntekst    handling';
  justify-content: initial;
  grid-template-rows: auto 1fr auto;
  color: var(--white);
  background-color: var(--primary-light-color);
  border-color: var(--primary-light-color);

  &[data-href] {
    &:hover,
    &:focus-within {
      border: var(--border-width) solid var(--white);
    }
  }

  .Card {
    &__meta {
      grid-area: meta;
      font-weight: var(--font-weight-normal);
    }

    &__tittel {
      grid-area: tittel;

      h1 {
        font-family: var(--secondary-font);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-h3);
        color: var(--white);
      }
    }

    &__bunntekst {
      grid-area: bunntekst;
      font-size: var(--font-size-smaller);
    }

    &__handling {
      grid-area: handling;
      a {
        color: var(--white);

        span[class*='icon-'] {
          color: var(--white);
        }
      }
    }
  }
}

.Feedback {
  display: flex;
  flex-direction: column;
  row-gap: var(--grid-row-gap);

  .Card {
    &__illustrasjon {
      order: 1;
      [class*='icon'] {
        font-size: var(--font-size-h1);
        color: var(--primary-light-color);
      }
    }

    &__tittel {
      order: 2;
      h1 {
        color: var(--primary-light-color);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-h3);
      }
    }

    &__innhold {
      order: 3;
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .Card {
    padding: map.get(global.$card-spacings, 'tight');

    &[data-layout] {
      &[data-layout='horizontal'] {
        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        .Card {
          &__illustrasjon {
            order: 1;
          }

          &__meta {
            order: 2;
          }

          &__tittel {
            order: 3;
          }

          &__innhold {
            order: 4;
          }

          &__handling {
            order: 5;
          }
        }
      }
    }
  }
}

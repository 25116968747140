@use '/src/stylesheets/global' as global;

.CmsNodes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap);
  font-size: var(--font-size-large);

  > header {
    grid-column: 1 / 4;
  }

  > main {
    grid-column: 1 / 3;
    display: flex;
    gap: var(--grid-row-gap);
    flex-direction: column;
  }

  > aside {
    grid-column: 3 / 4;

    figure {
      display: flex;
      justify-content: center;
    }
  }

  .Nyheter {
    display: grid;
    row-gap: var(--grid-row-gap);
  }

  [class*='Card'] {
    &[class*='Generic'] {
      p,
      a,
      ul,
      ol,
      summary {
        font-size: var(--font-size-base);
      }
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .CmsNodes {
    grid-template-columns: 1fr;

    > header,
    > main,
    > aside {
      grid-column: 1;
    }
  }
}

[class*='CmsNode_main'] {
  --list-link-background-size: 1.5rem;
  --list-link-background-posision: bottom 50% right 1rem;

  table {
    min-width: 100%;
  }

  .wp-block-columns {
    display: flex;
    gap: 20px;
  }

  .wp-block-column {
    flex-basis: 0;
    flex-grow: 1;
  }

  .wp-block-rm-manual-content {
    &:first-child {
      .list__title {
        margin-top: 0;
      }
    }

    .list__title {
      @extend h2;
      margin-top: 2.5rem;
    }

    .list-items {
      margin-top: var(--form-vertical-spacing);
      display: grid;
    }
  }

  .node--type-page,
  .type-document,
  .wp-block-rm-link-block.external-link {
    display: grid;
    border-style: solid;
    border-width: var(--border-width-thin) 0 0 0;
    border-color: var(--secondary-color);
    padding: 0;
    margin: 0;

    a {
      display: grid;
      text-decoration: none;
      color: var(--text-color);
      background-repeat: no-repeat;
      background-position: var(--list-link-background-posision);
      background-size: var(--list-link-background-size);
      background-image: url(../../images/drupal/icons/arrow-right.svg);

      &:hover,
      &:active {
        color: var(--link-hover-color);
        background-image: url(../../images/drupal/icons/arrow-right-primary.svg);
      }

      &[data-type='media'] {
        background-image: url(../../images/drupal/icons/download.svg);

        &:focus,
        &:hover {
          background-image: url(../../images/drupal/icons/download-primary.svg);
        }
      }

      &[data-type='url'] {
        background-image: url(../../images/drupal/icons/external-link-blue.svg);

        &:focus,
        &:hover {
          background-image: url(../../images/drupal/icons/external-link-primary.svg);
        }
      }
    }

    strong {
      font-weight: var(--font-weight-normal);
    }

    span {
      display: block;
    }

    .media.media--type-document table,
    .field--name-field-intro-text {
      display: none;
    }
  }

  .wp-block-rm-factbox,
  .factbox {
    border-style: solid;
    border-width: var(--border-width-thin);
    border-color: var(--secondary-color);
    padding: 2.5rem;
    margin: 2.5rem 0;

    &__label {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-large);
      line-height: 1.6875rem;
    }

    &__content {
      font-family: var(--primary-font);
      margin-top: -0.625rem;
    }
  }

  @media only screen and (max-width: global.breakpoint('lg')) {
    details,
    table {
      min-width: 100vw;
      margin-left: calc(var(--app-horizontal-padding) * -1);
      margin-right: calc(var(--app-horizontal-padding) * -1);
    }

    details {
      figure {
        &.wp-block-table {
          display: block;
          width: 100vw;
          overflow-x: auto;
          margin-left: calc(var(--app-horizontal-padding) * -1);
          margin-right: calc(var(--app-horizontal-padding) * -1);

          table {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
    .wp-block-columns {
      flex-direction: column;
    }
  }

  .card {
    border: 2px solid var(--border-color-secondary);
    background-color: var(--white);
    color: var(--primary-dark-color);
    border-radius: 0;
    box-shadow: none;
    position: relative;
  }
  .card:hover {
    border-color: var(--primary-dark-color);
  }
  .card .card__title-link {
    display: block;
    text-decoration: none;
    color: var(--primary-dark-color);
    height: 100%;
  }
  .card .card__copy {
    padding: 32px 32px;
  }
  .card .card__title {
    font-family: 'Source Sans Pro', serif;
    font-size: 22px;
    line-height: 1.688rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
  }
  .card.is-external .card__title span {
    margin-right: 28px;
    display: block;
  }
  .card p.card__body {
    margin-top: 8px;
  }
  .card p:last-child {
    margin-bottom: 0;
  }
  .card + .card {
    margin-top: 16px;
  }
  .card:last-child {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .card + figure {
    margin-top: 40px;
  }
  .card img {
    width: 100%;
    border-radius: 0;
  }
  @media all and (max-width: 639px) {
    .card + .wp-block-columns .wp-block-column:first-child .card {
      margin-top: 16px;
    }
  }
  .card + .wp-block-columns .wp-block-column .list__title {
    margin-top: 0;
  }
  .card + .wp-block-group {
    margin-top: 16px;
  }
  .card.card--medium .card__copy {
    padding: 32px;
  }
  @media all and (max-width: 1024px) {
    .card.card--small .card__copy {
      padding-bottom: 87px;
    }
  }
  .card.card--button .card__copy {
    padding: 24px;
  }
  .card.card--button .card__title {
    font-size: var(--font-size-normal);
  }
  .card.card--large {
    display: flex;
    flex-direction: row;
  }
  @media all and (max-width: 639px) {
    .card.card--large {
      display: block;
    }
  }
  .card.card--large .card__copy {
    padding: 32px;
  }
  .card.card--large.card__title-link {
    text-decoration: none;
  }
  .card.card--large .card__illustration {
    min-width: 50%;
  }
  @media all and (min-width: 1025px) {
    .card.card--large .card__illustration {
      max-width: 50%;
    }
  }
  @media all and (max-width: 639px) {
    .card.card--large .card__illustration {
      width: auto;
    }
  }
  .card.card--large .card__illustration img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .card.card--large .card__copy {
    min-width: calc(50% - 80px);
  }
  .card.card__with-icon--balance {
    background-image: url(../../images/drupal/icons/balance.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
  }
  .card.card__with-icon--balance .card__title {
    padding-top: 54px;
    text-align: center;
  }
  .card.card__with-icon--bubble-question {
    background-image: url(../../images/drupal/icons/bubble-question.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
  }
  .card.card__with-icon--bubble-question .card__title {
    padding-top: 54px;
    text-align: center;
  }
  .card.card__with-icon--man-and-woman {
    background-image: url(../../images/drupal/icons/man-woman.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
  }
  .card.card__with-icon--man-and-woman .card__title {
    padding-top: 54px;
    text-align: center;
  }
  .card.card__with-icon--calendar {
    background-image: url(../../images/drupal/icons/calendar.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
  }
  .card.card__with-icon--calendar .card__title {
    padding-top: 54px;
    text-align: center;
  }
  .card.card__with-icon--file-search {
    background-image: url(../../images/drupal/icons/file-search.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
  }
  .card.card__with-icon--file-search .card__title {
    padding-top: 54px;
    text-align: center;
  }
  .card.card__with-icon--document {
    background-image: url(../../images/drupal/icons/document.svg);
    background-repeat: no-repeat;
    background-position: center 34px;
  }
  .card.card__with-icon--document .card__title {
    padding-top: 54px;
    text-align: center;
  }
  .card.card--process ul.process-list {
    margin: 50px 0 0px;
    padding: 0 36px 0 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex: 1 1 0px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }
  @media all and (max-width: 1024px) {
    .card.card--process ul.process-list {
      flex-direction: column;
    }
  }
  .card.card--process ul.process-list li {
    flex-grow: 1;
  }
  @media all and (max-width: 1024px) {
    .card.card--process ul.process-list li {
      width: 100%;
    }
  }
  .card.card--process ul.process-list li:nth-of-type(1) {
    z-index: 6;
  }
  .card.card--process ul.process-list li:nth-of-type(2) {
    z-index: 5;
  }
  .card.card--process ul.process-list li:nth-of-type(3) {
    z-index: 4;
  }
  .card.card--process ul.process-list li:nth-of-type(4) {
    z-index: 3;
  }
  .card.card--process ul.process-list li:nth-of-type(5) {
    z-index: 2;
  }
  .card.card--process ul.process-list li:nth-of-type(6) {
    z-index: 1;
  }
  .card.card--process ul.process-list li:first-child span {
    padding-left: 20px;
  }
  @media all and (max-width: 1024px) {
    .card.card--process ul.process-list li:first-child span {
      padding-left: 10px;
    }
  }
  .card.card--process ul.process-list li > span {
    font-family: 'Source Sans Pro', serif;
    font-size: var(--font-size-normal);
    line-height: 21px;
    font-weight: 600;
    color: var(--primary-color);
    padding: 0 0 0 40px;
    text-decoration: none;
    background: var(--white);
    height: 99px;
    border-width: 3px 0 3px 3px;
    border-style: solid;
    border-color: var(--border-color-secondary);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media all and (max-width: 1024px) {
    .card.card--process ul.process-list li > span {
      font-family: 'Source Sans Pro', serif;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      height: auto;
      min-height: 36px;
      justify-content: center;
      padding: 20px;
      border-color: #9a9a9a;
      border-width: 1px;
      text-align: center;
    }
  }
  .card.card--process ul.process-list li > span::after {
    content: '';
    display: block;
    position: absolute;
    background: inherit;
    height: 148px;
    width: 149px;
    transform: rotate(45deg) skew(12deg, 12deg);
    border: 3px var(--border-color-secondary) solid;
    top: -28px;
    right: -27px;
    z-index: -1;
  }
  @media all and (max-width: 1024px) {
    .card.card--process ul.process-list li > span::after {
      left: calc(50% - 14px);
      bottom: -15px;
      top: auto;
      transform: rotate(135deg) skew(8deg, 8deg);
      height: 28px;
      width: 28px;
      z-index: 1;
      border-color: #9a9a9a;
      border-width: 1px;
    }
  }
  @media all and (max-width: 1024px) {
    .card.card--process ul.process-list li > span::before {
      content: '';
      width: 100px;
      height: 23px;
      background-color: var(--white);
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
  }
  .card.card--process ul.process-list li > span.blueArrow::after {
    border-color: var(--primary-dark-color);
  }
  .card.card--process .card__link-title {
    display: none;
  }
  .card.card--process ul.process-list {
    margin: 50px 0 30px;
  }
  @media all and (max-width: 1024px) {
    .card.card--process ul.process-list {
      width: 100%;
      padding-bottom: 17px;
      margin-bottom: 0;
    }
  }
  .card .card__link-title {
    background-image: url(../../images/drupal/icons/arrow-right.svg);
    font-family: 'Source Sans Pro', serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: var(--font-weight-normal);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 40px auto;
    position: absolute;
    right: 30px;
    bottom: 30px;
    padding: 0 54px 0 0px;
    height: 27px;
  }
  .card.is-external .card__link-title {
    background-image: url(../../images/drupal/icons/external-link-blue.svg);
  }
  .card.has-primary-light-blue-background-color,
  .card.has-primary-dark-blue-background-color {
    color: var(--white) !important;
  }
  .card.has-primary-light-blue-background-color .card__link-title,
  .card.has-primary-dark-blue-background-color .card__link-title {
    background-image: url(../../images/drupal/icons/arrow-right.svg);
  }
  .card.has-primary-light-blue-background-color .card__title-link,
  .card.has-primary-dark-blue-background-color .card__title-link {
    color: var(--white) !important;
  }
  .card.external-link,
  .card.external {
    border: none;
    border-top: solid 1px var(--secondary-color);
    border-bottom: solid 1px var(--secondary-color);
    background: inherit;
    margin: -1px 0 0 0;
  }
  .card.external-link + *:not(.card.external-link):not(.card.external),
  .card.external + *:not(.card.external-link):not(.card.external) {
    margin-top: 40px;
  }
  .card.external-link:last-child,
  .card.external:last-child {
    margin-bottom: 40px;
  }
  .card.external-link:first-child,
  .card.external-link:only-of-type,
  .card.external:first-child,
  .card.external:only-of-type {
    margin-top: 40px;
  }
  .card.external-link a:hover .card__copy,
  .card.external a:hover .card__copy {
    color: var(--secondary-color);
  }
  .card.external-link .card__copy,
  .card.external .card__copy {
    color: var(--primary-dark-color);
    text-decoration: none;
    display: block;
    padding: 10px 50px 10px 20px;
  }
  .card.external-link .card__copy .card__title,
  .card.external .card__copy .card__title {
    font-family: 'Source Sans Pro', serif;
    font-style: normal;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-normal);
    line-height: 1.688rem;
  }
  @media all and (min-width: 640px) {
    .wp-block-columns:first-child .card {
      margin-top: 0;
    }
  }
  .wp-block-columns + .card {
    margin-top: 0;
  }
  .wp-block-columns + .wp-block-columns .card:first-child {
    margin-top: 0;
  }
  .wp-block-column .card {
    height: 100%;
  }
  .wp-block-column .card.card--large {
    display: block;
  }
  .wp-block-column .card.card--large .card__title-link {
    display: block;
  }
  .wp-block-column .card.card--large .card__illustration,
  .wp-block-column .card.card--large .card__image,
  .wp-block-column .card.card--large .card__copy {
    width: auto;
    max-width: 100%;
  }
  @media all and (max-width: 639px) {
    .wp-block-column + .wp-block-column .card:first-child {
      margin-top: 0;
    }
  }
  .wp-block-group + .wp-block-columns > .wp-block-column > .card {
    margin-top: 0;
  }

  .wp-block-drupal-section figure.media-type-block.type-document {
    border-top: var(--border-width-thin) solid var(--secondary-color);
    border-bottom: var(--border-width-thin) solid var(--secondary-color);
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: var(--list-link-background-posision);
      background-size: var(--list-link-background-size);
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;
      &:hover {
        color: var(--secondary-color);
      }
    }
    .document_title {
      font-style: normal;
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-normal);
      line-height: 1.688rem;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-size: var(--font-size-smaller);
        line-height: 1.125rem;
      }
    }
    table {
      margin-left: 0;
      display: block;
      margin-top: 0;
      border-collapse: collapse;
      text-transform: uppercase;
      td {
        font-size: var(--font-size-smaller);
        line-height: 1.125rem;
        padding: 0 24px 0 0;
        background-color: var(--background-color-overlay);
        border: none;
      }
    }
  }
  .wp-block-drupal-section
    figure.media-type-block.wp-block-rm-media-type-document {
    border-top: var(--border-width-thin) solid var(--secondary-color);
    border-bottom: var(--border-width-thin) solid var(--secondary-color);
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: var(--list-link-background-posision);
      background-size: var(--list-link-background-size);
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;
      &:hover {
        color: var(--secondary-color);
      }
    }
    .document_title {
      font-style: normal;
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-normal);
      line-height: 1.688rem;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-size: var(--font-size-smaller);
        line-height: 1.125rem;
      }
    }
    table {
      margin-left: 0;
      display: block;
      margin-top: 0;
      border-collapse: collapse;
      text-transform: uppercase;
      td {
        font-size: var(--font-size-smaller);
        line-height: 1.125rem;
        padding: 0 24px 0 0;
        background-color: var(--background-color-overlay);
        border: none;
      }
    }
  }
  .wp-block-drupal-section figure.wp-block-media-entity.type-document {
    border-top: var(--border-width-thin) solid var(--secondary-color);
    border-bottom: var(--border-width-thin) solid var(--secondary-color);
    margin-top: -1px;
    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }
    a {
      display: block;
      overflow: hidden;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: var(--list-link-background-posision);
      background-size: var(--list-link-background-size);
      padding: 10px 50px 10px 20px;
      text-decoration: none;
      &:hover {
        color: var(--secondary-color);
      }
    }
    .document_title {
      font-style: normal;
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-normal);
      line-height: 1.688rem;
    }
    .field--name-field-description {
      p {
        margin: 0;
        font-size: var(--font-size-smaller);
        line-height: 1.125rem;
      }
    }
    table {
      margin-left: 0;
      display: block;
      margin-top: 0;
      border-collapse: collapse;
      text-transform: uppercase;
      width: auto;
      max-width: 100%;
      td {
        font-size: var(--font-size-smaller);
        line-height: 1.125rem;
        padding: 0 24px 0 0;
        background-color: var(--background-color-overlay);
        border: none;
      }
    }
  }
  .wp-block-drupal-section
    figure.wp-block-media-entity.wp-block-rm-media-type-document {
    border-top: var(--border-width-thin) solid var(--secondary-color);
    border-bottom: var(--border-width-thin) solid var(--secondary-color);
    margin-top: -1px;

    + {
      * {
        &:not(.wp-block-media-entity) {
          margin-top: 40px;
        }
      }
    }

    a {
      display: block;
      background-image: url(../../images/drupal/icons/download.svg);
      background-repeat: no-repeat;
      background-position: var(--list-link-background-posision);
      background-size: var(--list-link-background-size);
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 10px 50px 10px 20px;
      margin: 0;
      text-decoration: none;

      &:hover {
        color: var(--secondary-color);
      }
    }

    .document_title {
      font-style: normal;
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-normal);
      line-height: 1.688rem;
    }

    .field--name-field-description {
      p {
        margin: 0;
        font-size: var(--font-size-smaller);
        line-height: 1.125rem;
      }
    }

    table {
      margin-left: 0;
      display: block;
      margin-top: 0;
      border-collapse: collapse;
      text-transform: uppercase;

      td {
        font-size: var(--font-size-smaller);
        line-height: 1.125rem;
        padding: 0 24px 0 0;
        background-color: var(--background-color-overlay);
        border: none;
      }
    }
  }
}

@use '/src/stylesheets/global' as global;

.CmsNode {
  color: var(--text-color);

  &[data-er-skyreise='true'] {
    &[data-skyreise-har-navigasjon='true'] {
      > article {
        grid-template-columns: 3fr 1fr 8fr;
        grid-template-areas:
          'header header header'
          'nav nav nav'
          'processmenu . ingress'
          'processmenu . main'
          'processmenu . timestamp';

        .ProcessMenu {
          grid-area: processmenu;
        }
      }
    }
  }

  &[data-er-skyreise='false'] {
    > article {
      &.avtale {
        grid-template-areas:
          'header header'
          'timestamp sidebar'
          'ingress sidebar'
          'main sidebar';
      }
    }
  }

  &[data-er-landingsside='true'] {
    > article {
      grid-template-columns: 12fr;
      grid-template-areas:
        'header'
        'timestamp'
        'ingress'
        'main';
    }
  }

  > article {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 8fr 4fr;
    grid-template-areas:
      'header header'
      'nav nav'
      'ingress sidebar'
      'main sidebar'
      'timestamp sidebar';
    column-gap: var(--grid-column-gap);
    row-gap: var(--grid-row-gap);

    &.avtale {
      grid-template-areas:
        'header header'
        'nav nav'
        'timestamp sidebar'
        'ingress sidebar'
        'main sidebar';

      .timestamp {
        grid-area: timestamp;

        time {
          font-size: var(--font-size-smaller);
          letter-spacing: 0.00625rem;
        }

        time + time,
        span + span {
          margin-left: 0.1875rem;
        }
      }

      .main {
        margin-top: 2rem;
      }
    }

    .header {
      grid-area: header;
      display: flex;
      flex-direction: column;

      h1 {
        &:not(:only-child) {
          order: 2;
        }
      }

      p {
        order: 1;
        margin: 0 0 0.75rem 0;
        font-family: var(--secondary-font);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-h3);

        &:empty {
          display: none;
        }
      }
    }

    .toc {
      grid-area: sidebar;
      div {
        position: sticky;
        top: var(--app-header-height);
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1.875rem auto;
        grid-template-areas:
          'toc-arrow toc-header'
          'toc-arrow toc-items';

        header {
          grid-area: toc-header;

          h2 {
            font-size: var(--font-size-base);
            font-family: var(--primary-font);
            font-weight: var(--font-weight-normal);
          }
        }

        ul {
          grid-area: toc-arrow;
          grid-row: 2;
          grid-column: span 2;
        }

        li {
          display: grid;
          grid-template-columns: 1.875rem auto;
          padding-bottom: 0.4rem;
          border-bottom: var(--border-width-thin) dashed var(--border-color);

          &:nth-child(n + 2) {
            padding-top: 0.4rem;
          }

          > span {
            grid-column: 1;
          }

          > a {
            grid-column: 2;
            font-size: var(--font-size-base);
            text-decoration: none;
          }
        }
      }
    }

    .skyreise {
      grid-area: nav;
    }

    .header,
    .ingress {
      color: var(--primary-color);
    }

    .ingress {
      grid-area: ingress;
      font-size: var(--font-size-large);
    }

    .main {
      grid-area: main;

      main {
        > *:first-child {
          margin-top: 0;
        }
      }
    }

    p,
    ul,
    ol,
    h4,
    figure,
    details,
    table {
      margin-top: var(--font-size-normal);

      &:first-child {
        margin-top: 0;
      }
    }

    h2,
    h3 {
      margin-top: 2.5rem;
    }

    details + details {
      margin-top: 0;
    }

    a {
      text-decoration: underline;
    }

    img {
      width: initial;
      height: initial;
      max-height: 100%;
      max-width: 100%;
    }

    table {
      border-collapse: collapse;

      tr {
        th,
        td {
          border-width: var(--border-width-thin) 0 0 0;
          border-style: solid;
          border-color: var(--border-color-secondary);
        }

        &:last-child {
          th,
          td {
            border-bottom-width: var(--border-width-thin);
          }
        }
      }

      th,
      td {
        padding: 0.9375rem 1.5625rem;
        text-align: left;
      }

      th {
        background-color: var(--table-header-bg);
        font-weight: var(--font-weight-semibold);
      }

      td {
        background-color: var(--white);
      }
    }

    main {
      ul,
      ol {
        padding-left: 2.5rem;

        ul,
        ol {
          margin: 1.25rem 0;
        }
      }

      ul {
        list-style-type: disc;

        ul {
          list-style-type: circle;
        }
      }
    }

    .sistEndert {
      grid-area: timestamp;
    }
  }
}

@media only screen and (max-width: global.breakpoint('lg')) {
  .CmsNode {
    > article {
      display: flex;
      flex-direction: column;

      .toc {
        display: none;
      }

      table {
        width: 100%;
      }
    }
  }
}

@use '/src/stylesheets/global' as global;

.App {
  display: flex;
  padding-top: calc(var(--app-header-height) + var(--app-vertical-padding));
  min-height: calc(
    100vh - calc(var(--app-header-height) + var(--app-vertical-padding))
  );
  flex-direction: column;
  justify-content: space-between;

  .Main {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 var(--app-horizontal-padding);
  }

  .Wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--app-vertical-padding);
    width: 100%;
    max-width: var(--app-max-width);
  }
}

@use '/src/stylesheets/global' as global;

.Breadcrumbs {
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    list-style: none;
    flex-wrap: wrap;
  }

  li {
    &:not(:only-child) {
      &:not(:last-child) {
        margin-right: 0.625rem;

        &::after {
          display: inline-block;
          content: '/';
          margin-left: 0.625rem;
          color: var(--primary-color);
        }
      }
    }
  }

  a {
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-smaller);

    &.selected {
      color: var(--text-color);
      font-weight: var(--font-weight-normal);
      text-decoration: none;
      pointer-events: none;
    }
  }
}

@media only screen and (max-width: global.breakpoint('md')) {
  .Breadcrumbs {
    margin-bottom: 1.25rem;
  }
}

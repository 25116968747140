.Toolbar {
  font-size: 1.25rem;
  font-weight: var(--font-weight-normal);
  line-height: 1.25rem;

  ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
  }

  li,
  a,
  a:visited,
  a:active,
  a:hover,
  a:link {
    display: flex;
    align-items: center;
    color: var(--primary-color) !important;
    cursor: pointer;
    text-decoration: none;
    font-weight: var(--font-weight-normal);

    &:not(:first-child) {
      margin-left: 2.5rem;
    }

    span {
      display: inline-block;
      height: 1.875rem;
      width: 1.875rem;
      line-height: 1.875rem;
      font-size: 1.75rem;
      margin-right: 0.8125rem;
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;
    }
  }
}

.Tag {
  --tag--vertical-padding--base: calc(var(--button-vertical-padding) / 2);
  --tag--horizontal-padding--base: calc(var(--button-horizontal-padding) / 2);

  --tag--color--bg: var(--text-light-color);
  --tag--color--text: var(--white);

  display: inline-block;
  padding: var(--tag--vertical-padding--base)
    var(--tag--horizontal-padding--base);
  background-color: var(--tag--color--bg);
  color: var(--tag--color--text);
  line-height: 1;
  border-radius: 1rem;

  > * {
    margin: 0;
  }

  &[data-tag-variant='success'] {
    --tag--color--bg: var(--supportive-text-color);
    --tag--color--text: var(--white);
  }

  &[data-tag-variant='neutral'] {
    --tag--color--bg: var(--text-light-color);
    --tag--color--text: var(--white);
  }

  &[data-tag-variant='warning'] {
    --tag--color--bg: var(--warning-yellow-color);
    --tag--color--text: var(--white);
  }

  &[data-tag-variant='error'] {
    --tag--color--bg: var(--error-red-color);
    --tag--color--text: var(--white);
  }

  &[data-tag-variant='primary'] {
    --tag--color--bg: var(--primary-color);
    --tag--color--text: var(--white);
  }

  &[data-tag-variant='primaryLight'] {
    --tag--color--bg: var(--primary-light-color-25);
    --tag--color--text: var(--primary-color);
  }

  &[data-tag-variant='transparent'] {
    --tag--color--bg: var(--white);
    --tag--color--text: var(--text-color);
    border: solid 1px var(--primary-color);
  }
}

.Progress {
  margin-top: 1.2rem;
  position: relative;
  display: flex;

  .Step {
    position: absolute;
    border-top: var(--border-width) solid #bab1c3;
    display: flex;
    justify-content: flex-end;

    &:first-child {
      border-top: var(--border-width) solid transparent;
      justify-content: flex-start;
    }

    &.previous {
      border-top: var(--border-width) solid var(--primary-dark-color);

      .dot {
        background: var(--primary-dark-color);
      }
    }

    &.current {
      border-top: var(--border-width) solid var(--primary-dark-color);

      &:first-child {
        border-top: var(--border-width) solid transparent;
      }

      .dot {
        width: 2rem;
        height: 2rem;
        margin-top: -1rem;
        margin-left: -1rem;
        background: var(--primary-dark-color);
        z-index: 8;
      }
    }

    .dot {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -0.3rem;
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 50%;
      color: var(--white);
      background: #bab1c3;
      font-size: var(--font-size-base);
    }
  }
}

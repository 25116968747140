.OversiktTjenesteSide {
  display: flex;
  flex-direction: column;
  gap: var(--grid-row-gap);

  section,
  header {
    display: flex;
    flex-direction: column;
    gap: var(--grid-row-gap);
  }

  section:last-child {
    gap: var(--grid-column-gap);
  }

  header {
    gap: calc(var(--grid-row-gap) / 2);

    h1 + p {
      font-size: var(--font-size-h3);
      font-family: var(--secondary-font);
      font-weight: var(--font-weight-normal);
    }
  }

  h2 {
    font-size: var(--font-weight-normal);
    font-family: var(--primary-font);
  }
}

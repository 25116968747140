.Header {
  h1 {
    text-align: center;
  }

  nav {
    display: flex;
    h1 {
      flex-grow: 0.7;
      text-align: center;
      color: var(--primary-dark-color);
      font-size: 1.5rem;
      font-family: var(--primary-font);
      font-weight: var(--font-weight-semibold);
    }

    button {
      display: flex;
      justify-content: space-between;
      color: var(--primary-light-color);
      background-color: transparent;
      line-height: 1.5rem;
      text-transform: uppercase;

      [class^='Icon'] {
        margin-right: 0.625rem;
        margin-left: 0.625rem;
      }
    }
  }
}

.AlertBox {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: var(--grid-column-gap);
  position: fixed;
  left: 10vw;
  right: 10vw;
  bottom: 10vw;
  z-index: 10;
  padding: var(--font-size-base);
  background-color: var(--error-color);
  color: white;
  opacity: 0;
  pointer-events: none;
  transition: all 180ms ease-out;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {
    justify-content: flex-start;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0;
    border: none;
    pointer-events: auto;
  }

  a,
  a:link,
  a:active,
  a:hover {
    color: white;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  &.presented {
    opacity: 1;
    transition: transform 250ms ease-out;
  }
}

.ImporterTjenesterSide {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--grid-row-gap);

  ol {
    list-style: auto;
    padding-left: var(--grid-row-gap);
  }

  em {
    font-weight: var(--font-weight-semibold);
    font-style: normal;
  }

  .VeiledningLink {
    text-align: center;
  }

  header,
  [class*='Toolbar'] {
    grid-column: 1 / 13;
  }

  main {
    grid-column: 1 / 9;
    display: flex;
    flex-direction: column;
    gap: var(--modal-grid-gap);
  }

  section {
    display: flex;
    flex-direction: column;
    gap: var(--modal-grid-gap);
  }

  label {
    margin-bottom: 0;
  }

  .UploadError {
    padding: var(--grid-column-gap) var(--grid-row-gap);
    border: 1px solid var(--error-red-color);
    color: var(--error-red-color);
    background-color: var(--white);
    gap: calc(var(--modal-grid-gap) / 2);

    h3 {
      color: var(--error-red-color);
    }
  }

  .DownloadTemplateWrapper {
    display: grid;
    place-items: center;
  }

  .DownloadTemplate {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-decoration: none;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-top: 1px solid var(--link-hover-color);
    border-bottom: 1px solid var(--link-hover-color);
  }

  .DropZone {
    display: grid;
    gap: calc(var(--modal-grid-gap) / 2);
    place-items: center;
    padding: calc(var(--grid-column-gap) * 3) calc(var(--grid-row-gap) * 1.5);
    background-color: var(--background-dark-color);
    border: 1px dashed var(--border-color-light);
    margin: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      border-color: var(--border-color);
    }

    .DropZoneInner,
    .InProgress {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: calc(var(--grid-column-gap) / 2) calc(var(--grid-row-gap) / 2);
    }

    .DropZoneInner {
      input[type='file'] {
        display: none;
      }
    }

    .InProgress {
      p {
        font-weight: var(--font-weight-normal);
      }
    }

    .InputFile {
      padding: var(--button-vertical-padding) var(--button-horizontal-padding);
      border: 1px solid var(--link-color);
      color: var(--link-color);
      cursor: pointer;

      &:focus,
      &:hover {
        border-color: var(--link-hover-color);
        color: var(--link-hover-color);
      }
    }

    .FileName {
      font-weight: var(--font-weight-semibold);
    }

    + .Hint {
      font-size: var(--font-size-small);
    }
  }

  .StatusPanel {
    --status-panel--padding--base: 2rem;
    --status-panel--padding: calc(var(--status-panel--padding--base) / 1.25)
      var(--status-panel--padding--base);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: var(--status-panel--padding--base);
    padding: var(--status-panel--padding);
    background-color: var(--white);

    [class*='icon-'] {
      &[class*='-check'] {
        color: var(--tertiary-color);
      }
    }
  }

  .ReturnButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: calc(var(--button-vertical-padding) * 2)
      var(--button-horizontal-padding);
  }
}

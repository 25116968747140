@use '/src/stylesheets/global' as global;

.Veileder {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: global.breakpoint('xl')) {
  .Veileder {
    margin: 0 8rem 0 8rem;
  }
}

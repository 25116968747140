.ProcessMenu {
  --process-number-size: calc(var(--font-size-large) * 1.75);
  --process-number-size--small: var(--font-size-normal);
  --process-number-padding--small: calc(
    (var(--process-number-size) - var(--process-number-size--small)) / 2
  );

  font-weight: var(--font-weight-semibold);
  font-family: var(--primary-font);
  font-size: var(--font-size-normal);
  color: var(--primary-dark-color);

  .RootMenu,
  .SubMenu {
    margin-top: 0;
  }

  ul {
    counter-reset: processmenu;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    a {
      text-decoration: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      padding-right: 0.5rem;
      font-size: var(--font-size-normal);
      min-height: calc(var(--font-size-normal) * 2);

      &:before {
        counter-increment: processmenu;
        content: counter(processmenu);
        width: var(--process-number-size);
        height: var(--process-number-size);
        line-height: 1;
        border-radius: 50%;
        background-color: transparent;
        color: var(--primary-color);
        display: grid;
        place-items: center;
        border: 3px solid var(--primary-color);
        aspect-ratio: 1/1;
      }

      &:hover,
      &:focus {
        &:before {
          border-color: var(--secondary-color);
          color: var(--secondary-color);
        }
      }

      [class*='icon-chevron'] {
        font-size: var(--font-size-small);
        transform: rotate(90deg);
      }
    }

    ul {
      a {
        font-size: var(--font-size-normal);

        &:before {
          width: var(--process-number-size--small);
          height: var(--process-number-size--small);
          font-size: var(--font-size-smaller);
          font-weight: var(--font-weight-semibold);
          border-width: 1px;
          margin-inline: var(--process-number-padding--small);
        }
      }

      ul {
        a {
          padding-block: 0.75rem;
          padding-inline: var(--process-number-size);
          margin-left: var(--process-number-size);
          font-size: var(--font-size-small);

          &:before {
            display: none;
          }
        }
      }
    }

    li.activePath {
      > a > [class*='icon-chevron'] {
        transform: rotate(180deg);
      }

      > a {
        font-weight: var(--font-weight-bold);
      }

      &.activeNode {
        > a {
          font-weight: var(--font-weight-bold);
          background: var(--secondary-color-overlay);

          &:before {
            background-color: var(--primary-color);
            color: var(--white);
          }

          &:hover,
          &:focus {
            &:before {
              background-color: var(--secondary-color);
              border-color: transparent;
              color: var(--white);
            }
          }
        }

        > ul {
          li {
            [class*='icon-chevron'] {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
}

.TjenesteViewer {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  .Wrapper {
    grid-column: 1 / 13;
    display: grid;
    gap: 2.5rem;
  }

  .Main {
    display: flex;
    flex-direction: row;
    gap: var(--grid-column-gap);
  }

  header {
    display: grid;
    gap: var(--grid-column-gap);
    font-family: var(--secondary-font);

    div:first-child {
      align-items: center;
      span {
        text-transform: uppercase;
        font-family: var(--primary-font);
      }
    }

    a {
      font-size: var(--font-size-large);
      font-family: var(--secondary-font);
      font-weight: var(--font-weight-semibold);
      text-decoration: none;
      display: flex;
      column-gap: var(--grid-column-gap);

      p {
        text-decoration: underline;
      }

      [class*='icon-'] {
        font-size: var(--font-size-large);
      }
    }
  }

  .Produsent {
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: var(--link-hover-color);
    }
  }

  .TjenesteList {
    display: flex;
    flex-direction: column;
    row-gap: var(--grid-row-gap);

    h1 {
      font-size: var(--font-size-h4);
    }
  }

  .Tjenestekategori {
    font-weight: var(--font-weight-bold);
  }

  .TabSelector {
    margin-bottom: 0;
  }

  .TabContent {
    background-color: var(--white);
    padding: 3.7rem;
    display: grid;
    gap: 2.5rem;

    h2 {
      font-family: var(--secondary-font);
      margin-bottom: 1.5rem;
    }
  }
}

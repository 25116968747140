.Pagination {
  h2 {
    display: none;
  }
  display: flex;
  justify-content: center;

  button {
    background: transparent;
    color: var(--primary-light-color);
    transition: background-color 200ms ease-out;
    user-select: none;

    &:hover {
      background: var(--border-color-secondary);
    }

    &.selected {
      background: var(--primary-light-color);
      color: white;
      cursor: default;
      pointer-events: none;
    }

    &.disabled {
      cursor: default;
      opacity: 0.25;
      background-color: transparent;
      color: black;
      pointer-events: none;
    }

    + button {
      margin-left: var(--border-width);
    }
  }
}

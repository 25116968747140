.CloudSaveIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-color);
  font-family: var(--primary-font);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-normal);

  span {
    display: flex;
    padding: 0 0.3125rem var(--border-width-thin);
    vertical-align: bottom;
    font-size: 1.25rem;
  }
}
